import React, { useState, useEffect, useCallback } from 'react';
import { Card } from '../ui/card';
import { ArrowLeft, Search, Filter, X, Info } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);


const ActivityLogsDashboard = () => {
  const navigate = useNavigate();
  
  // ใช้ state สำหรับโหลดข้อมูลหน้าจอและตาราง
  const [pageLoading, setPageLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  
  const [logs, setLogs] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 50,
    total: 0,
    totalPages: 0
  });
  const [filters, setFilters] = useState({
    member_id: '',
    event_type: '',
    device_type: '',
    start_date: '',
    end_date: '',
    user_type: 'all',
    username: '',
    page_url: '' // เพิ่มนี้
  });
  const [activeTab, setActiveTab] = useState('all');
  
  
  // เพิ่ม eslint-disable ก่อนบรรทัดที่ประกาศ fetchLogsData
  
  // eslint-disable-next-line no-unused-vars
  const fetchLogsData = useCallback(async (customFilters = null, showLoading = true) => {
    try {
      if (showLoading) {
        setTableLoading(true);
      }
      
      // สร้างสำเนาของ filters และใช้ customFilters ถ้ามี
      const currentFilters = customFilters || { ...filters };
      const currentPagination = { ...pagination };
      
      const token = localStorage.getItem('admin_token');
      const params = {
        page: currentPagination.page,
        limit: currentPagination.limit
      };
      
      // ตั้งค่าพารามิเตอร์ตาม filters
      if (currentFilters.member_id && currentFilters.member_id.trim() !== '') {
        params.member_id = currentFilters.member_id.trim();
      }
      if (currentFilters.event_type && currentFilters.event_type !== '') {
        params.event_type = currentFilters.event_type;
      }
      if (currentFilters.device_type && currentFilters.device_type !== '') {
        params.device_type = currentFilters.device_type;
      }
      if (currentFilters.start_date && currentFilters.start_date !== '') {
        params.start_date = currentFilters.start_date;
      }
      if (currentFilters.end_date && currentFilters.end_date !== '') {
        params.end_date = currentFilters.end_date;
      }
      if (currentFilters.username && currentFilters.username !== '') {
        params.username = currentFilters.username.trim();
      }
      if (currentFilters.user_type && currentFilters.user_type !== 'all') {
        params.user_type = currentFilters.user_type;
      }
      if (currentFilters.access_level) {
        params.access_level = currentFilters.access_level;
      }
      if (currentFilters.non_admin) {
        params.non_admin = currentFilters.non_admin;
      }
      if (currentFilters.page_url && currentFilters.page_url.trim() !== '') {
        params.page_url = currentFilters.page_url.trim();
      }
      
      console.log("API Request params:", params);
      
      // แก้ไขส่วนการเรียก API ด้วย Axios
      axios.get('/activity/admin/logs', {
        headers: { Authorization: `Bearer ${token}` },
        params
      })
      .then(response => {
        console.log("API Response received:", response.data);
        // เพิ่มการตรวจสอบว่าข้อมูลมีรูปแบบที่ถูกต้องหรือไม่
        if (response.data && Array.isArray(response.data.data)) {
          setLogs(response.data.data);
          setPagination({
            page: parseInt(response.data.page || 1),
            limit: parseInt(response.data.limit || 50),
            total: parseInt(response.data.total || 0),
            totalPages: parseInt(response.data.total_pages || 1)
          });
        } else {
          console.error('API response format is incorrect:', response.data);
          setLogs([]);
          setPagination({
            page: 1,
            limit: 50,
            total: 0,
            totalPages: 0
          });
          MySwal.fire({
            title: 'เกิดข้อผิดพลาด',
            text: 'รูปแบบข้อมูลไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง',
            icon: 'error',
            confirmButtonText: 'ตกลง'
          });
        }
      })
      .catch(error => {
        console.error(`เกิดข้อผิดพลาดในการเรียก API:`, error);
        // เพิ่มการตรวจสอบสถานะการเชื่อมต่อ
        if (!navigator.onLine) {
          MySwal.fire({
            title: 'ไม่มีการเชื่อมต่ออินเทอร์เน็ต',
            text: 'กรุณาตรวจสอบการเชื่อมต่อของท่าน',
            icon: 'warning',
            confirmButtonText: 'ตกลง'
          });
        } else {
          MySwal.fire({
            title: 'เกิดข้อผิดพลาด',
            text: `ไม่สามารถดึงข้อมูล logs ได้: ${error.response?.status === 500 ? 'เซิร์ฟเวอร์มีปัญหา กรุณาลองใหม่ภายหลัง' : error.response?.data?.message || error.message}`,
            icon: 'error',
            confirmButtonText: 'ตกลง'
          });
        }
        // แสดงข้อมูลว่างเมื่อเกิดข้อผิดพลาด
        setLogs([]);
      })
      .finally(() => {
        setTableLoading(false);
      });
    } catch (error) {
      console.error('Error fetching activity logs data:', error);
      MySwal.fire({
        title: 'เกิดข้อผิดพลาด',
        text: `ไม่สามารถดึงข้อมูล logs ได้: ${error.response?.data?.message || error.message}`,
        icon: 'error',
        confirmButtonText: 'ตกลง'
      });
    } finally {
      if (showLoading) {
        setTableLoading(false);
      }
    }
  }, [filters, pagination]); // เพิ่ม dependencies ให้ครบถ้วน
  
  // แก้ไขส่วน useEffect ที่ดึงข้อมูลเริ่มต้น
  useEffect(() => {
    const token = localStorage.getItem('admin_token');
    if (!token) {
      MySwal.fire({
        title: 'ไม่มีสิทธิ์เข้าถึง',
        text: 'กรุณาเข้าสู่ระบบด้วยบัญชีผู้ดูแลระบบ',
        icon: 'warning',
        confirmButtonText: 'ตกลง'
      }).then(() => {
        navigate('/admin/login');
      });
      return;
    }
    
    const fetchInitialData = async () => {
      try {
        setPageLoading(true);
        
        // เปลี่ยนจาก all เป็น users
        setActiveTab('users');
        setFilters({
          member_id: '',
          event_type: '',
          device_type: '',
          start_date: '',
          end_date: '',
          user_type: 'all',
          username: '',
          page_url: '',
          non_admin: 'true' // เพิ่มตัวกรองผู้ใช้ทั่วไป
        });
        
        // เรียกข้อมูลด้วยการส่งคำขอโดยตรงแทนที่จะใช้ fetchLogsData
        const params = {
          page: 1,
          limit: 50,
          non_admin: 'true'  // เพิ่มตัวกรองผู้ใช้ทั่วไป
        };
        
        console.log("Initial API Request params:", params);
        
        axios.get('/activity/admin/logs', {
          headers: { Authorization: `Bearer ${token}` },
          params
        })
        .then(response => {
          console.log("API Response received:", response.data);
          // เพิ่มการตรวจสอบว่าข้อมูลมีรูปแบบที่ถูกต้องหรือไม่
          if (response.data && Array.isArray(response.data.data)) {
            setLogs(response.data.data);
            setPagination({
              page: parseInt(response.data.page || 1),
              limit: parseInt(response.data.limit || 50),
              total: parseInt(response.data.total || 0),
              totalPages: parseInt(response.data.total_pages || 1)
            });
          } else {
            console.error('API response format is incorrect:', response.data);
            setLogs([]);
            setPagination({
              page: 1,
              limit: 50,
              total: 0,
              totalPages: 0
            });
            MySwal.fire({
              title: 'เกิดข้อผิดพลาด',
              text: 'รูปแบบข้อมูลไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง',
              icon: 'error',
              confirmButtonText: 'ตกลง'
            });
          }
        })
        .catch(error => {
          console.error(`เกิดข้อผิดพลาดในการเรียก API:`, error);
          // เพิ่มการตรวจสอบสถานะการเชื่อมต่อ
          if (!navigator.onLine) {
            MySwal.fire({
              title: 'ไม่มีการเชื่อมต่ออินเทอร์เน็ต',
              text: 'กรุณาตรวจสอบการเชื่อมต่อของท่าน',
              icon: 'warning',
              confirmButtonText: 'ตกลง'
            });
          } else {
            MySwal.fire({
              title: 'เกิดข้อผิดพลาด',
              text: `ไม่สามารถดึงข้อมูล logs ได้: ${error.response?.status === 500 ? 'เซิร์ฟเวอร์มีปัญหา กรุณาลองใหม่ภายหลัง' : error.response?.data?.message || error.message}`,
              icon: 'error',
              confirmButtonText: 'ตกลง'
            });
          }
          // แสดงข้อมูลว่างเมื่อเกิดข้อผิดพลาด
          setLogs([]);
        })
        .finally(() => {
          setTableLoading(false);
        });
        
        setPageLoading(false);
      } catch (error) {
        console.error('Error fetching initial data:', error);
        setPageLoading(false);
      }
    };
    
    fetchInitialData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);
  
  // แก้ไขฟังก์ชัน handleFilterChange
const handleFilterChange = (e) => {
  const { name, value } = e.target;
  console.log(`Changing filter ${name} to: "${value}"`);
  
  // สร้างตัวแปรเพื่อเก็บค่า filters ใหม่
  const newFilters = { ...filters, [name]: value };
  
  // อัปเดต filters ทันที
  setFilters(newFilters);
  
  // ทำการรีเซ็ต pagination หน้าแรก
  const updatedPagination = { ...pagination, page: 1 };
  setPagination(updatedPagination);
  
  // เตรียมพารามิเตอร์สำหรับ API
  setTableLoading(true);
  
  const token = localStorage.getItem('admin_token');
  const params = {
    page: 1, // เริ่มที่หน้า 1 เสมอเมื่อเปลี่ยนตัวกรอง
    limit: pagination.limit
  };
  
  // เพิ่มพารามิเตอร์จาก filters ใหม่
  Object.keys(newFilters).forEach(key => {
    if (newFilters[key] && newFilters[key] !== '') {
      params[key] = newFilters[key];
    }
  });
  
  console.log("Sending API request with params:", params);
  
  // เรียก API
  axios.get('/activity/admin/logs', {
    headers: { Authorization: `Bearer ${token}` },
    params
  })
  .then(response => {
    console.log("API Response received:", response.data);
    if (response.data && Array.isArray(response.data.data)) {
      setLogs(response.data.data);
      setPagination({
        page: parseInt(response.data.page || 1),
        limit: parseInt(response.data.limit || 50),
        total: parseInt(response.data.total || 0),
        totalPages: parseInt(response.data.total_pages || 1)
      });
    } else {
      console.error('API response format is incorrect:', response.data);
      setLogs([]);
      MySwal.fire({
        title: 'เกิดข้อผิดพลาด',
        text: 'รูปแบบข้อมูลไม่ถูกต้อง',
        icon: 'error',
        confirmButtonText: 'ตกลง'
      });
    }
  })
  .catch(error => {
    console.error(`เกิดข้อผิดพลาดในการเรียก API:`, error);
    handleApiError(error);
  })
  .finally(() => {
    setTableLoading(false);
  });
};

  // เพิ่มฟังก์ชัน handleApiError เพื่อจัดการข้อผิดพลาดจาก API ให้เป็นรูปแบบเดียวกัน
  const handleApiError = (error) => {
    if (!navigator.onLine) {
      MySwal.fire({
        title: 'ไม่มีการเชื่อมต่ออินเทอร์เน็ต',
        text: 'กรุณาตรวจสอบการเชื่อมต่อของท่าน',
        icon: 'warning',
        confirmButtonText: 'ตกลง'
      });
    } else {
      MySwal.fire({
        title: 'เกิดข้อผิดพลาด',
        text: `ไม่สามารถดึงข้อมูล logs ได้: ${error.response?.status === 500 ? 'เซิร์ฟเวอร์มีปัญหา กรุณาลองใหม่ภายหลัง' : error.response?.data?.message || error.message}`,
        icon: 'error',
        confirmButtonText: 'ตกลง'
      });
    }
    setLogs([]);
  };
  
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= pagination.totalPages) {
      // อัปเดต state ก่อน
      setPagination(prev => ({ ...prev, page: newPage }));
      
      // เรียก API โดยตรงแทนที่จะใช้ fetchLogsData เพื่อให้แน่ใจว่าใช้ค่า newPage
      setTableLoading(true);
      
      const token = localStorage.getItem('admin_token');
      const params = {
        page: newPage, // ใช้ newPage โดยตรงแทน pagination.page
        limit: pagination.limit
      };
      
      // เพิ่มพารามิเตอร์ตาม filters ปัจจุบัน
      if (filters.member_id && filters.member_id.trim() !== '') {
        params.member_id = filters.member_id.trim();
      }
      if (filters.event_type && filters.event_type !== '') {
        params.event_type = filters.event_type;
      }
      if (filters.device_type && filters.device_type !== '') {
        params.device_type = filters.device_type;
      }
      if (filters.start_date && filters.start_date !== '') {
        params.start_date = filters.start_date;
      }
      if (filters.end_date && filters.end_date !== '') {
        params.end_date = filters.end_date;
      }
      if (filters.username && filters.username !== '') {
        params.username = filters.username.trim();
      }
      if (filters.user_type && filters.user_type !== 'all') {
        params.user_type = filters.user_type;
      }
      if (filters.access_level) {
        params.access_level = filters.access_level;
      }
      if (filters.non_admin) {
        params.non_admin = filters.non_admin;
      }
      if (filters.page_url && filters.page_url.trim() !== '') {
        params.page_url = filters.page_url.trim();
      }
      
      console.log(`Changing to page ${newPage}, sending params:`, params);
      
      axios.get('/activity/admin/logs', {
        headers: { Authorization: `Bearer ${token}` },
        params
      })
      .then(response => {
        console.log("API Response received:", response.data);
        // เพิ่มการตรวจสอบว่าข้อมูลมีรูปแบบที่ถูกต้องหรือไม่
        if (response.data && Array.isArray(response.data.data)) {
          setLogs(response.data.data);
          setPagination({
            page: parseInt(response.data.page || 1),
            limit: parseInt(response.data.limit || 50),
            total: parseInt(response.data.total || 0),
            totalPages: parseInt(response.data.total_pages || 1)
          });
        } else {
          console.error('API response format is incorrect:', response.data);
          setLogs([]);
          setPagination({
            page: 1,
            limit: 50,
            total: 0,
            totalPages: 0
          });
          MySwal.fire({
            title: 'เกิดข้อผิดพลาด',
            text: 'รูปแบบข้อมูลไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง',
            icon: 'error',
            confirmButtonText: 'ตกลง'
          });
        }
      })
      .catch(error => {
        console.error(`เกิดข้อผิดพลาดในการเรียก API:`, error);
        // เพิ่มการตรวจสอบสถานะการเชื่อมต่อ
        if (!navigator.onLine) {
          MySwal.fire({
            title: 'ไม่มีการเชื่อมต่ออินเทอร์เน็ต',
            text: 'กรุณาตรวจสอบการเชื่อมต่อของท่าน',
            icon: 'warning',
            confirmButtonText: 'ตกลง'
          });
        } else {
          MySwal.fire({
            title: 'เกิดข้อผิดพลาด',
            text: `ไม่สามารถดึงข้อมูล logs ได้: ${error.response?.status === 500 ? 'เซิร์ฟเวอร์มีปัญหา กรุณาลองใหม่ภายหลัง' : error.response?.data?.message || error.message}`,
            icon: 'error',
            confirmButtonText: 'ตกลง'
          });
        }
        // แสดงข้อมูลว่างเมื่อเกิดข้อผิดพลาด
        setLogs([]);
      })
      .finally(() => {
        setTableLoading(false);
      });
    }
  };
  
  // แก้ไข handleTabChange ให้สมบูรณ์และชัดเจนยิ่งขึ้น
  const handleTabChange = (tab) => {
    if (tab === activeTab) return;
    
    console.log(`เปลี่ยนแท็บเป็น: ${tab}`);
    
    // สร้างตัวแปรสำหรับเก็บค่า filters ใหม่
    const newFilters = {
      member_id: '',
      event_type: '',
      device_type: '',
      start_date: '',
      end_date: '',
      user_type: 'all',
      username: '',
      page_url: filters.page_url || '' // คงค่า page_url ไว้ไม่รีเซ็ต
    };
    
    // กำหนดค่าตามแท็บที่เลือก
    if (tab === 'admin') {
      console.log('กำหนดค่า filter สำหรับผู้ดูแลระบบ');
      newFilters.access_level = 'admin';
    } else if (tab === 'users') {
      console.log('กำหนดค่า filter สำหรับผู้ใช้ทั่วไป');
      newFilters.non_admin = 'true';
    }
    
    // อัปเดต state โดยไม่ต้องใช้ callback ของ useState
    setActiveTab(tab);
    setFilters(newFilters);
    setPagination(prev => ({ ...prev, page: 1 }));
    
    // เรียกฟังก์ชัน fetch ข้อมูลในรอบถัดไปของ event loop
    // เพื่อให้แน่ใจว่า React ได้อัปเดต state แล้ว
    setTimeout(() => {
      // ทำการเรียกข้อมูลแบบ manual โดยใช้ค่า filters ที่สร้างใหม่ทั้งหมด
      const token = localStorage.getItem('admin_token');
      const params = {
        page: 1, // หน้าแรกเสมอเมื่อเปลี่ยนแท็บ
        limit: 50
      };
      
      if (tab === 'admin') {
        params.access_level = 'admin';
      } else if (tab === 'users') {
        params.non_admin = 'true';
      }
      
      console.log("กำลังเรียก API ด้วย params:", params);
      setTableLoading(true);
      
      axios.get('/activity/admin/logs', {
        headers: { Authorization: `Bearer ${token}` },
        params
      })
      .then(response => {
        console.log("API Response received:", response.data);
        // เพิ่มการตรวจสอบว่าข้อมูลมีรูปแบบที่ถูกต้องหรือไม่
        if (response.data && Array.isArray(response.data.data)) {
          setLogs(response.data.data);
          setPagination({
            page: parseInt(response.data.page || 1),
            limit: parseInt(response.data.limit || 50),
            total: parseInt(response.data.total || 0),
            totalPages: parseInt(response.data.total_pages || 1)
          });
        } else {
          console.error('API response format is incorrect:', response.data);
          setLogs([]);
          setPagination({
            page: 1,
            limit: 50,
            total: 0,
            totalPages: 0
          });
          MySwal.fire({
            title: 'เกิดข้อผิดพลาด',
            text: 'รูปแบบข้อมูลไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง',
            icon: 'error',
            confirmButtonText: 'ตกลง'
          });
        }
      })
      .catch(error => {
        console.error(`เกิดข้อผิดพลาดในการเรียก API:`, error);
        // เพิ่มการตรวจสอบสถานะการเชื่อมต่อ
        if (!navigator.onLine) {
          MySwal.fire({
            title: 'ไม่มีการเชื่อมต่ออินเทอร์เน็ต',
            text: 'กรุณาตรวจสอบการเชื่อมต่อของท่าน',
            icon: 'warning',
            confirmButtonText: 'ตกลง'
          });
        } else {
          MySwal.fire({
            title: 'เกิดข้อผิดพลาด',
            text: `ไม่สามารถดึงข้อมูล logs ได้: ${error.response?.status === 500 ? 'เซิร์ฟเวอร์มีปัญหา กรุณาลองใหม่ภายหลัง' : error.response?.data?.message || error.message}`,
            icon: 'error',
            confirmButtonText: 'ตกลง'
          });
        }
        // แสดงข้อมูลว่างเมื่อเกิดข้อผิดพลาด
        setLogs([]);
      })
      .finally(() => {
        setTableLoading(false);
      });
    }, 0);
  };
  
  // ฟังก์ชันแปลงวันที่
  const formatDate = (dateString) => {
    if (!dateString) return '-';
    try {
      if (dateString.includes(' ')) {
        const [datePart, timePart] = dateString.split(' ');
        const [year, month, day] = datePart.split('-').map(num => parseInt(num));
        const [hours, minutes, seconds] = timePart.split(':').map(num => parseInt(num));
        const thaiMonths = ['', 'ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'];
        return `${day} ${thaiMonths[month]} ${year} ${hours.toString().padStart(2,'0')}:${minutes.toString().padStart(2,'0')}:${seconds.toString().padStart(2,'0')}`;
      } else {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        const thaiMonths = ['', 'ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'];
        return `${day} ${thaiMonths[month]} ${year} ${hours.toString().padStart(2,'0')}:${minutes.toString().padStart(2,'0')}:${seconds.toString().padStart(2,'0')}`;
      }
    } catch (error) {
      console.error("Error formatting date:", error, dateString);
      return dateString;
    }
  };
  
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const [showEventTypeInfo, setShowEventTypeInfo] = useState(false);

  // เพิ่มฟังก์ชันสำหรับกลุ่ม event_type
  const getEventTypeGroups = useCallback(() => {
    return [
      { 
        group: 'navigation', 
        label: 'การนำทาง',
        types: ['page_view', 'page_exit'] 
      },
      { 
        group: 'session', 
        label: 'การเข้า/ออกระบบ',
        types: ['login', 'logout'] 
      },
      { 
        group: 'app', 
        label: 'การใช้งานแอพ',
        types: ['app_exit', 'app_inactive', 'app_active'] 
      },
      { 
        group: 'upload', 
        label: 'การอัพโหลด',
        types: ['file_upload'] 
      },
      { 
        group: 'error', 
        label: 'ข้อผิดพลาด',
        types: ['error'] 
      }
    ];
  }, []);

  if (pageLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen p-4">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500 mx-auto"></div>
          <p className="mt-4 text-gray-600">กำลังโหลดข้อมูล...</p>
        </div>
      </div>
    );
  }
  
  return (
    <div className="p-4 sm:p-6 lg:p-8 bg-gray-50 min-h-screen">
      {/* ปรับปุ่มย้อนกลับให้อยู่ตำแหน่งเดียวกับไฟล์อื่น */}
      <div className="flex justify-end items-center">
        <button
          onClick={() => navigate("/admin/dashboard")}
          className="flex gap-2 bg-none text-gray-600 px-4 py-2 mb-5 rounded-md hover:text-gray-800 transition-colors"
        >
          <ArrowLeft className="h-5 w-5" />
          ย้อนกลับ
        </button>
      </div>
      
      {/* ปรับหัวข้อให้อยู่ตรงกลาง */}
      <div className="mb-8 flex justify-center items-center">
        <div className="text-center">
          <h1 className="text-3xl font-bold text-gray-800">บันทึกกิจกรรมผู้ใช้</h1>
          <p className="text-gray-600 mt-2">ดูประวัติการใช้งานและกิจกรรมของผู้ใช้ในระบบ</p>
        </div>
      </div>
      
      {/* แท็บสำหรับกรองข้อมูล พร้อมปุ่ม "สรุปบันทึกกิจกรรม" ที่นำไปไว้ด้านข้างของแท็บ "ผู้ใช้ทั่วไป" */}
      <div className="flex flex-wrap items-center gap-2 md:gap-4 mb-4 overflow-x-auto justify-center">
        <button 
          onClick={() => handleTabChange('all')}
          className={`px-3 py-1.5 md:px-4 md:py-2 rounded text-sm md:text-base ${activeTab==='all' ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-700'}`}
        >
          ทั้งหมด
        </button>
        <button 
          onClick={() => handleTabChange('admin')}
          className={`px-3 py-1.5 md:px-4 md:py-2 rounded text-sm md:text-base ${activeTab==='admin' ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-700'}`}
        >
          ผู้ดูแลระบบ
        </button>
        <button 
          onClick={() => handleTabChange('users')}
          className={`px-3 py-1.5 md:px-4 md:py-2 rounded text-sm md:text-base ${activeTab==='users' ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-700'}`}
        >
          ผู้ใช้ทั่วไป
        </button>
        <button
          onClick={() => navigate("/admin/dashboard/activity-logs/summary")}
          className="px-3 py-1.5 md:px-4 md:py-2 bg-purple-600 text-white rounded hover:bg-purple-700 flex items-center text-sm md:text-base"
        >
          <span className="hidden md:inline">สรุปบันทึกกิจกรรม</span>
          <span className="md:hidden">สรุป</span>
        </button>
      </div>
      
      {/* ฟิลเตอร์ */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4 mb-6">
        {/* ปุ่มเปิด/ปิดการแสดงตัวกรองบนมือถือ */}
        <div className="sm:hidden w-full mb-2">
          <button 
            onClick={() => setShowMobileFilters(prev => !prev)} 
            className="w-full bg-gray-100 py-2 rounded flex items-center justify-center gap-2"
          >
            <Filter className="h-4 w-4" />
            {showMobileFilters ? "ซ่อนตัวกรอง" : "แสดงตัวกรอง"}
          </button>
        </div>
        
        {/* กล่องฟิลเตอร์ที่จะซ่อน/แสดงบนมือถือ */}
        <div className={`${showMobileFilters ? 'block' : 'hidden'} sm:block sm:contents`}>
          {/* เนื้อหาฟิลเตอร์เดิม */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">รหัสสมาชิก</label>
            <div className="relative">
              <input
                type="text"
                name="member_id"
                value={filters.member_id}
                onChange={handleFilterChange}
                className="w-full p-2 pl-8 border rounded text-base"
                placeholder="พิมพ์เพื่อค้นหา..."
              />
              <Search className="h-4 w-4 text-gray-400 absolute left-2 top-3" />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
              ประเภทกิจกรรม 
              <div className="group relative ml-1 cursor-help">
                <Info size={16} className="text-gray-500" />
                <div className="hidden group-hover:block absolute left-1/2 transform -translate-x-1/2 top-full mt-1 bg-gray-900 text-white text-xs rounded px-2 py-1 w-56 z-10">
                  <p className="mb-1 font-bold">คำอธิบาย:</p>
                  <p className="mb-1">page_view - การเข้าชมหน้า</p>
                  <p className="mb-1">page_exit - ออกจากหน้า</p>
                  <p className="mb-1">login - เข้าสู่ระบบ</p>
                  <p className="mb-1">logout - ออกจากระบบ</p>
                  <p className="mb-1">app_inactive - ไม่มีการใช้งาน</p>
                  <p className="mb-1">app_active - กลับมาใช้งาน</p>
                  <p className="mb-1">file_upload - อัพโหลดไฟล์</p>
                  <p>error - ข้อผิดพลาด</p>
                </div>
              </div>
            </label>
            <select
              name="event_type"
              value={filters.event_type}
              onChange={handleFilterChange}
              className="w-full p-2 border rounded text-base"
            >
              <option value="">ทั้งหมด</option>
              
              {/* แสดงประเภทกิจกรรมทั้งหมด */}
              <option disabled>--- แยกตามประเภท ---</option>
              <option value="page_view">เข้าชมหน้า (page_view)</option>
              <option value="page_exit">ออกจากหน้า (page_exit)</option>
              <option value="login">เข้าสู่ระบบ (login)</option>
              <option value="logout">ออกจากระบบ (logout)</option>
              <option value="app_exit">ปิดแอพ (app_exit)</option>
              <option value="app_inactive">ไม่มีการใช้งาน (app_inactive)</option>
              <option value="app_active">กลับมาใช้งาน (app_active)</option>
              <option value="file_upload">อัพโหลดไฟล์ (file_upload)</option>
              <option value="error">ข้อผิดพลาด (error)</option>
              
              {/* แสดงตัวกรองตามกลุ่ม */}
              <option disabled>--- แยกตามกลุ่ม ---</option>
              {getEventTypeGroups().map(group => (
                <option key={group.group} value={group.group}>
                  {group.label} ({group.types.join(', ')})
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">อุปกรณ์</label>
            <select
              name="device_type"
              value={filters.device_type}
              onChange={handleFilterChange}
              className="w-full p-2 border rounded text-base"
            >
              <option value="">ทั้งหมด</option>
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">วันที่เริ่ม</label>
            <input
              type="date"
              name="start_date"
              value={filters.start_date}
              onChange={handleFilterChange}
              className="w-full p-2 border rounded text-base"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">วันที่สิ้นสุด</label>
            <input
              type="date"
              name="end_date"
              value={filters.end_date}
              onChange={handleFilterChange}
              className="w-full p-2 border rounded text-base"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">หน้าที่เข้าชม</label>
            <div className="relative">
              <input
                type="text"
                name="page_url"
                value={filters.page_url || ''}
                onChange={handleFilterChange}
                className="w-full p-2 pl-8 border rounded text-base"
                placeholder="เช่น /home, /admin/login"
              />
              <Search className="h-4 w-4 text-gray-400 absolute left-2 top-3" />
            </div>
          </div>
        </div>
      </div>
      
      {/* เพิ่มส่วนที่แสดงคำอธิบาย event_type */}
      <div className="flex items-center mb-4">
        <h2 className="text-lg font-semibold">ประเภทกิจกรรม</h2>
        <button
          onClick={() => setShowEventTypeInfo(true)}
          className="ml-2 inline-flex items-center justify-center w-5 h-5 rounded-full bg-gray-200 text-gray-600 hover:bg-gray-300"
          title="รายละเอียดประเภทกิจกรรม"
        >
          ?
        </button>
      </div>

      {/* เพิ่ม modal หรือ dialog สำหรับแสดงรายละเอียด */}
      {showEventTypeInfo && (
        <div className="fixed inset-0 bg-black bg-opacity-30 z-40 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl">
            <div className="flex justify-between items-center p-5 border-b">
              <h3 className="text-lg font-semibold">รายละเอียดประเภทกิจกรรม</h3>
              <button onClick={() => setShowEventTypeInfo(false)} className="text-gray-500 hover:text-gray-700">
                <X className="h-5 w-5" />
              </button>
            </div>
            <div className="p-5 max-h-[70vh] overflow-y-auto">
              <table className="min-w-full divide-y divide-gray-200 text-sm">
                <thead>
                  <tr>
                    <th className="px-3 py-2 text-center">ประเภท</th>
                    <th className="px-3 py-2 text-center">คำอธิบาย</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  <tr>
                    <td className="px-3 py-2 font-medium">page_view</td>
                    <td className="px-3 py-2">บันทึกเมื่อผู้ใช้เข้าชมหน้าใดๆ ในระบบ</td>
                  </tr>
                  <tr>
                    <td className="px-3 py-2 font-medium">page_exit</td>
                    <td className="px-3 py-2">บันทึกเมื่อผู้ใช้ออกจากหน้าหนึ่งไปยังอีกหน้าหนึ่ง</td>
                  </tr>
                  <tr>
                    <td className="px-3 py-2 font-medium">login</td>
                    <td className="px-3 py-2">บันทึกเมื่อผู้ใช้เข้าสู่ระบบ</td>
                  </tr>
                  <tr>
                    <td className="px-3 py-2 font-medium">logout</td>
                    <td className="px-3 py-2">บันทึกเมื่อผู้ใช้ออกจากระบบ</td>
                  </tr>
                  <tr>
                    <td className="px-3 py-2 font-medium">app_exit</td>
                    <td className="px-3 py-2">บันทึกเมื่อผู้ใช้ปิดแอพพลิเคชัน</td>
                  </tr>
                  <tr>
                    <td className="px-3 py-2 font-medium">app_inactive</td>
                    <td className="px-3 py-2">บันทึกเมื่อไม่มีการใช้งานเกิน 15 นาที</td>
                  </tr>
                  <tr>
                    <td className="px-3 py-2 font-medium">app_active</td>
                    <td className="px-3 py-2">บันทึกเมื่อผู้ใช้กลับมาใช้งานอีกครั้งหลังจากไม่มีการใช้งาน</td>
                  </tr>
                  <tr>
                    <td className="px-3 py-2 font-medium">file_upload</td>
                    <td className="px-3 py-2">บันทึกเมื่อมีการอัพโหลดไฟล์</td>
                  </tr>
                  <tr>
                    <td className="px-3 py-2 font-medium">error</td>
                    <td className="px-3 py-2">บันทึกเมื่อเกิดข้อผิดพลาด</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="p-4 border-t text-right">
              <button
                onClick={() => setShowEventTypeInfo(false)}
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
              >
                ปิด
              </button>
            </div>
          </div>
        </div>
      )}

      {/* ตารางแสดงรายการกิจกรรม */}
      <Card>
        {tableLoading ? (
          <div className="flex items-center mb-4 text-sm text-gray-600">
            <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-blue-500 mr-2"></div>
            {activeTab === 'admin'
              ? 'กำลังดึงข้อมูลกิจกรรมของผู้ดูแลระบบ...'
              : activeTab === 'users'
              ? 'กำลังดึงข้อมูลกิจกรรมของผู้ใช้ทั่วไป...'
              : 'กำลังดึงข้อมูลกิจกรรมทั้งหมด...'}
          </div>
        ) : (
          <div className="mb-4 text-sm sm:hidden mt-5 text-gray-600">
            พบข้อมูล {logs.length} รายการ จากทั้งหมด {pagination.total} รายการ
          </div>
        )}
        
        {/* ตารางสำหรับหน้าจอปกติจะซ่อนบนมือถือ */}
        <div className="hidden sm:block overflow-x-auto">
          <div className="min-w-full grid grid-cols-12 bg-gray-50 border-b border-gray-200">
            <div className="px-3 py-3 text-sm font-medium text-gray-600 uppercase tracking-wider text-center col-span-1">ID</div>
            <div className="px-3 py-3 text-sm font-medium text-gray-600 uppercase tracking-wider text-left col-span-2">ผู้ใช้</div>
            <div className="px-3 py-3 text-sm font-medium text-gray-600 uppercase tracking-wider text-center col-span-1">กิจกรรม</div>
            <div className="px-3 py-3 text-sm font-medium text-gray-600 uppercase tracking-wider col-span-4">รายละเอียด</div>
            <div className="px-3 py-3 text-sm font-medium text-gray-600 uppercase tracking-wider col-span-2">เวลา</div>
            <div className="px-3 py-3 text-sm font-medium text-gray-600 uppercase tracking-wider hidden md:block col-span-1">อุปกรณ์</div>
            <div className="px-3 py-3 text-sm font-medium text-gray-600 uppercase tracking-wider hidden lg:block col-span-1">สถานะ</div>
          </div>
          
          <div className="min-w-full text-center">
            {logs.length > 0 ? logs.map((log) => {
              const additionalInfo = log.additional_info 
                ? (typeof log.additional_info === 'string' ? JSON.parse(log.additional_info) : log.additional_info) 
                : {};
              // eslint-disable-next-line no-unused-vars
              const actionDetails = log.action_details 
                ? (typeof log.action_details === 'string' ? JSON.parse(log.action_details) : log.action_details) 
                : {};
              // eslint-disable-next-line no-unused-vars
              const userTypeText = additionalInfo.user_type === 'admin' ? 'ผู้ดูแลระบบ' : 'ผู้ใช้ทั่วไป';
              
              let activityDetails = '';
              let durationText = '';

              if (log.event_type === 'page_view') {
                activityDetails = `เข้าชมหน้า "${log.page_url}"`;
                if (actionDetails.title) {
                  activityDetails += ` (${actionDetails.title})`;
                }
              } else if (log.event_type === 'page_exit') {
                activityDetails = `ออกจากหน้า "${log.page_url}"`;
                // แสดงระยะเวลาที่อยู่ในหน้า
                if (additionalInfo.duration) {
                  const duration = parseInt(additionalInfo.duration);
                  const minutes = Math.floor(duration / 60);
                  const seconds = duration % 60;
                  durationText = `${minutes} นาที ${seconds} วินาที`;
                }
                // แสดงความลึกของการเลื่อน
                if (additionalInfo.scroll_depth) {
                  activityDetails += ` (เลื่อนลงไป ${additionalInfo.scroll_depth}%)`;
                }
              } else if (log.event_type === 'app_exit') {
                activityDetails = `ปิดแอพพลิเคชัน`;
                // แสดงระยะเวลาทั้งการใช้งานเซสชันและหน้าสุดท้าย
                if (additionalInfo.session_duration) {
                  const sessionDuration = parseInt(additionalInfo.session_duration);
                  const sessionMinutes = Math.floor(sessionDuration / 60);
                  const sessionSeconds = sessionDuration % 60;
                  durationText = `เซสชัน: ${sessionMinutes} นาที ${sessionSeconds} วินาที`;
                }
              } else if (log.event_type === 'login') {
                // คงเดิม...
              }

              return (
                <div key={log.id} className="min-w-full grid grid-cols-12 hover:bg-gray-50 border-b border-gray-200">
                  <div className="px-3 py-4 text-gray-500 col-span-1 text-center">
                    <span 
                      className="cursor-pointer hover:text-indigo-600 hover:underline"
                      onClick={() => navigate(`/admin/dashboard/activity-logs/user/${log.member_id}`)}
                    >
                      {log.id}
                    </span>
                  </div>
                  <div className="px-3 py-4 col-span-2">
                    <div className="flex items-start">
                      <div className={`w-2 h-2 rounded-full mr-2 mt-1.5 ${additionalInfo.user_type === 'admin' ? 'bg-red-400' : 'bg-green-400'}`}></div>
                      <div className="min-w-0">
                        <div className="font-medium text-gray-900 truncate flex items-center gap-1">
                          {/* เพิ่มลิงก์ไปยังหน้ารายละเอียดกิจกรรมผู้ใช้ */}
                          <span 
                            className="cursor-pointer hover:text-indigo-600 hover:underline"
                            onClick={() => navigate(`/admin/dashboard/activity-logs/user/${log.member_id}`)}
                          >
                            {log.username || '-'}
                          </span>
                        </div>
                        <div className="text-xs text-gray-500 truncate text-left">
                          {/* เพิ่มลิงก์ไปยังหน้ารายละเอียดกิจกรรมผู้ใช้ที่รหัสสมาชิกด้วย */}
                          <span 
                            className="cursor-pointer hover:text-indigo-600 hover:underline"
                            onClick={() => navigate(`/admin/dashboard/activity-logs/user/${log.member_id}`)}
                          >
                            รหัสสมาชิก : {log.member_id}
                          </span>
                          {additionalInfo.user_type && (
                            <span className="ml-1 text-xs">
                              ({additionalInfo.user_type === 'admin' ? 'ผู้ดูแล' : 'ผู้ใช้'})
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-3 py-4 col-span-1 flex justify-center items-center">
                    <span className={`px-2 py-1 text-xs leading-5 font-semibold rounded-full w-full text-center
                      ${log.event_type === 'login' ? 'bg-green-100 text-green-800' : 
                        log.event_type === 'logout' ? 'bg-yellow-100 text-yellow-800' : 
                        log.event_type === 'page_view' ? 'bg-blue-100 text-blue-800' : 
                        log.event_type === 'file_upload' ? 'bg-purple-100 text-purple-800' : 
                        log.event_type === 'error' ? 'bg-red-100 text-red-800' : 
                        'bg-gray-100 text-gray-800'}`}>
                      {log.event_type}
                    </span>
                  </div>
                  <div className="px-3 py-4 text-gray-500 col-span-4 min-w-0">
                    <div className="text-sm break-words">{activityDetails}</div>
                    {log.page_url && log.event_type !== 'page_view' && (
                      <div className="text-xs text-gray-400 mt-1 truncate">
                        หน้า: {log.page_url}
                      </div>
                    )}
                    {(log.action_details || log.additional_info) && (
                      <details className="mt-1 text-xs">
                        <summary className="cursor-pointer text-indigo-600 hover:text-indigo-800 font-medium">
                          รายละเอียดเพิ่มเติม
                        </summary>
                        <div className="mt-2 p-2 bg-gray-50 rounded text-xs">
                          {log.action_details && (
                            <div className="mb-1">
                              <span className="font-semibold">Action:</span> {typeof log.action_details === 'string' ? log.action_details : JSON.stringify(log.action_details)}
                            </div>
                          )}
                          {log.additional_info && (
                            <div>
                              <span className="font-semibold">Info:</span> {typeof log.additional_info === 'string' ? log.additional_info : JSON.stringify(log.additional_info)}
                            </div>
                          )}
                        </div>
                      </details>
                    )}
                    {durationText && (
                      <div className="text-xs text-gray-500 mt-1">
                        ระยะเวลา: {durationText}
                      </div>
                    )}
                  </div>
                  <div className="px-3 py-4 text-gray-500 col-span-2 min-w-0">
                    <div className="text-sm whitespace-normal">{formatDate(log.event_time)}</div>
                  </div>
                  <div className="px-3 py-4 text-gray-500 hidden md:block col-span-1 min-w-0">
                    <div className="flex items-start">
                      {log.device_type === 'mobile' ? (
                        <span className="inline-block w-4 h-4 mr-2">📱</span>
                      ) : log.device_type === 'tablet' ? (
                        <span className="inline-block w-4 h-4 mr-2">📟</span>
                      ) : (
                        <span className="inline-block w-4 h-4 mr-2">💻</span>
                      )}
                      <div className="min-w-0">
                        <div className="text-xs truncate">{log.device_type || 'Desktop'}</div>
                        <div className="text-xs truncate">{log.browser} / {log.os}</div>
                        <div className="text-xs truncate">{log.ip_address || '-'}</div>
                      </div>
                    </div>
                  </div>
                  <div className="px-3 py-4 hidden lg:flex justify-center items-center col-span-1">
                    <span className={`px-2 py-1 text-xs leading-5 font-semibold rounded-full w-full text-center
                      ${log.status === 'success' ? 'bg-green-100 text-green-800' : 
                        log.status === 'error' ? 'bg-red-100 text-red-800' : 
                        'bg-gray-100 text-gray-800'}`}>
                      {log.status || '-'}
                    </span>
                  </div>
                </div>
              );
            }) : (
              <div className="px-3 py-6 text-center text-gray-500 text-lg col-span-12">
                ไม่พบข้อมูลกิจกรรม
              </div>
            )}
          </div>
        </div>

        {/* เพิ่มการแสดงผลบนมือถือด้วย Card UI */}
        <div className="sm:hidden space-y-4">
          {logs.length > 0 ? logs.map((log) => {
            const additionalInfo = log.additional_info 
              ? (typeof log.additional_info === 'string' ? JSON.parse(log.additional_info) : log.additional_info) 
              : {};
            const actionDetails = log.action_details 
              ? (typeof log.action_details === 'string' ? JSON.parse(log.action_details) : log.action_details) 
              : {};
            
            let activityDetails = '';
            let durationText = ''; // เพิ่มการประกาศตัวแปรนี้
            const userTypeText = additionalInfo.user_type === 'admin' ? 'ผู้ดูแลระบบ' : 'ผู้ใช้ทั่วไป';
            
            if (log.event_type === 'page_view') {
              activityDetails = `เข้าชมหน้า "${log.page_url}"`;
              if (actionDetails.title) {
                activityDetails += ` (${actionDetails.title})`;
              }
            } else if (log.event_type === 'page_exit') {
              activityDetails = `ออกจากหน้า "${log.page_url}"`;
              // แสดงระยะเวลาที่อยู่ในหน้า
              if (additionalInfo.duration) {
                const duration = parseInt(additionalInfo.duration);
                const minutes = Math.floor(duration / 60);
                const seconds = duration % 60;
                durationText = `${minutes} นาที ${seconds} วินาที`;
              }
              // แสดงความลึกของการเลื่อน
              if (additionalInfo.scroll_depth) {
                activityDetails += ` (เลื่อนลงไป ${additionalInfo.scroll_depth}%)`;
              }
            } else if (log.event_type === 'app_exit') {
              activityDetails = `ปิดแอพพลิเคชัน`;
              // แสดงระยะเวลาทั้งการใช้งานเซสชันและหน้าสุดท้าย
              if (additionalInfo.session_duration) {
                const sessionDuration = parseInt(additionalInfo.session_duration);
                const sessionMinutes = Math.floor(sessionDuration / 60);
                const sessionSeconds = sessionDuration % 60;
                durationText = `เซสชัน: ${sessionMinutes} นาที ${sessionSeconds} วินาที`;
              }
            } else if (log.event_type === 'login') {
              activityDetails = `เข้าสู่ระบบ (${userTypeText})`;
              if (actionDetails.method) {
                activityDetails += ` - วิธี: ${actionDetails.method}`;
              }
            } else if (log.event_type === 'logout') {
              activityDetails = `ออกจากระบบ`;
            } else {
              activityDetails = log.event_type;
            }
            
            return (
              <div key={log.id} className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
                <div className="p-3 border-b border-gray-100 flex items-center justify-between">
                  <div onClick={() => navigate(`/admin/dashboard/activity-logs/user/${log.member_id}`)}
                       className="flex items-center cursor-pointer">
                    <div className={`w-2 h-2 rounded-full mr-2 ${additionalInfo.user_type === 'admin' ? 'bg-red-400' : 'bg-green-400'}`}></div>
                    <span className="font-medium text-gray-900">{log.username || '-'}</span>
                    <span className="ml-1 text-xs text-gray-500">({userTypeText})</span>
                  </div>
                  <span className={`px-2 py-1 text-xs rounded-full 
                    ${log.event_type === 'login' ? 'bg-green-100 text-green-800' : 
                    log.event_type === 'logout' ? 'bg-yellow-100 text-yellow-800' : 
                    log.event_type === 'page_view' ? 'bg-blue-100 text-blue-800' : 
                    'bg-gray-100 text-gray-800'}`}>
                    {log.event_type}
                  </span>
                </div>
                <div className="p-3">
                  <p className="text-sm mb-2">{activityDetails}</p>
                  <div className="flex justify-between text-xs text-gray-500">
                    <div>{formatDate(log.event_time)}</div>
                    <div>{log.device_type || 'Desktop'}</div>
                  </div>
                  {(actionDetails && Object.keys(actionDetails).length > 0) && (
                    <details className="mt-2 text-xs">
                      <summary className="cursor-pointer text-indigo-600 hover:text-indigo-800">
                        รายละเอียดเพิ่มเติม
                      </summary>
                      <div className="mt-1 p-2 bg-gray-50 rounded">
                        {JSON.stringify(actionDetails)}
                      </div>
                    </details>
                  )}
                  {durationText && (
                    <div className="text-xs text-gray-500 mt-1">
                      ระยะเวลา: {durationText}
                    </div>
                  )}
                </div>
              </div>
            );
          }) : (
            <div className="py-6 text-center text-gray-500 bg-white rounded border border-gray-200">
              ไม่พบข้อมูลกิจกรรม
            </div>
          )}
        </div>
      </Card>
    
      {/* Pagination */}
      {pagination.totalPages > 1 && (
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mt-4 px-3 gap-4">
          <div className="text-sm text-gray-500 text-center sm:text-left">
            แสดง {logs.length} รายการ จากทั้งหมด {pagination.total} รายการ
          </div>
          <div className="flex flex-wrap justify-center sm:justify-end gap-1">
            <button 
              type="button"
              onClick={() => handlePageChange(pagination.page - 1)} 
              disabled={pagination.page <= 1}
              className={`px-2 py-1 rounded text-sm ${pagination.page <= 1 ? 'bg-gray-100 text-gray-400' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}`}
            >
              &laquo;
            </button>
            {(() => {
              let pageNumbers = [];
              const maxPagesToShow = window.innerWidth < 640 ? 3 : 5; // แสดงปุ่มน้อยลงบนมือถือ
              let startPage = Math.max(1, pagination.page - Math.floor(maxPagesToShow / 2));
              let endPage = Math.min(pagination.totalPages, startPage + maxPagesToShow - 1);
              if (endPage - startPage + 1 < maxPagesToShow && startPage > 1) {
                startPage = Math.max(1, endPage - maxPagesToShow + 1);
              }
              for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(i);
              }
              return pageNumbers.map(number => (
                <button
                  key={number}
                  onClick={() => handlePageChange(number)}
                  className={`w-8 h-8 rounded text-sm ${pagination.page === number ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}`}
                >
                  {number}
                </button>
              ));
            })()}
            <button 
              onClick={() => handlePageChange(pagination.page + 1)} 
              disabled={pagination.page >= pagination.totalPages}
              className={`px-2 py-1 rounded text-sm ${pagination.page >= pagination.totalPages ? 'bg-gray-100 text-gray-400' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}`}
            >
              &raquo;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActivityLogsDashboard;