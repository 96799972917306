import React, { useEffect, useState } from 'react';
// เปลี่ยนจาก axios ทั่วไปเป็น axios ที่มีการตั้งค่าไว้แล้ว
import axios from '../utils/axiosConfig';
import { API_URL } from '../utils/configBridge';
import { format, isValid } from 'date-fns'; 
import { Calendar, X, Paperclip, Download } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { isMarkdownContent, markdownComponents } from '../lib/markdownUtils';
import '../styles/markdown.css';

const AnnouncementModalSkeleton = () => (
  <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex justify-center items-center p-4">
    <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full animate-fadeIn">
      <div className="flex justify-between items-start p-4 border-b">
        <div className="h-6 w-60 bg-gray-200 rounded animate-pulse"></div>
        <button className="text-gray-400 hover:text-gray-700 transition-colors">
          <X className="h-5 w-5" />
        </button>
      </div>
      
      <div className="p-4 max-h-[70vh] overflow-auto space-y-4 animate-pulse">
        <div className="flex items-center mb-4">
          <Calendar className="h-4 w-4 mr-2 text-gray-200" />
          <div className="h-4 w-32 bg-gray-200 rounded"></div>
        </div>
        
        <div className="space-y-2">
          <div className="h-4 w-full bg-gray-200 rounded"></div>
          <div className="h-4 w-full bg-gray-200 rounded"></div>
          <div className="h-4 w-3/4 bg-gray-200 rounded"></div>
        </div>
        
        <div className="space-y-2 mt-4">
          <div className="h-4 w-full bg-gray-200 rounded"></div>
          <div className="h-4 w-5/6 bg-gray-200 rounded"></div>
          <div className="h-4 w-4/5 bg-gray-200 rounded"></div>
        </div>
        
        <div className="mt-6 border-t pt-4">
          <div className="flex items-center mb-3">
            <Paperclip className="h-4 w-4 mr-1 text-gray-200" />
            <div className="h-5 w-20 bg-gray-200 rounded"></div>
          </div>
          
          <div className="space-y-2">
            {[...Array(2)].map((_, index) => (
              <div key={index} className="flex items-center justify-between p-2 bg-gray-50 rounded border">
                <div className="flex items-center space-x-2">
                  <Paperclip className="h-4 w-4 text-gray-200" />
                  <div className="h-4 w-40 bg-gray-200 rounded"></div>
                </div>
                <div className="h-4 w-4 bg-gray-200 rounded-full"></div>
              </div>
            ))}
          </div>
        </div>
      </div>
      
      <div className="bg-gray-50 px-4 py-3 flex justify-end rounded-b-lg">
        <div className="h-8 w-16 bg-gray-200 rounded animate-pulse"></div>
      </div>
    </div>
  </div>
);

const Announcements = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);

  useEffect(() => {
    axios
      .get('/announcements')
      .then((response) => {
        const sortedData = [...response.data]
          .sort((a, b) => {
            if (a.order_position !== undefined && b.order_position !== undefined) {
              return b.order_position - a.order_position;
            }
            return b.id - a.id;
          })
          .slice(0, 10);
          
        setAnnouncements(sortedData);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  const openAnnouncementModal = (announcement) => {
    setSelectedAnnouncement(announcement);
    setIsModalOpen(true);
  };

  const closeAnnouncementModal = () => {
    setIsModalOpen(false);
  };

  if (loading) {
    return (
      <div className="bg-white p-1 rounded-lg">
        <h3 className="text-lg text-stone-800 font-bold">📢 ประกาศจากบริษัท</h3>
        <div className="mt-0">
          <div className="flex flex-col gap-1">
            {/* ส่วนหัวข้อ */}
            <div className="flex justify-start p-1 my-4 mb-2">
              <div className="w-[15%]">
                <p className="text-sm font-semibold">วันที่</p>
              </div>
              <div className="w-[85%] mr-10">
                <p className="text-sm font-semibold">เรื่อง</p>
              </div>
            </div>
            
            {/* ส่วน Skeleton Loading */}
            <div className="max-h-[160px] overflow-y-auto pr-2 scrollbar-hover-show">
              <div className="flex flex-col gap-1">
                {[...Array(5)].map((_, index) => (
                  <div 
                    key={index} 
                    className="flex justify-start p-2 rounded-lg border-b animate-pulse"
                  >
                    <div className="w-[15%] mr-3">
                      <div className="h-4 w-12 bg-gray-200 rounded"></div>
                    </div>
                    <div className="w-[85%]">
                      <div className="h-4 w-4/5 bg-gray-200 rounded"></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return <div>เกิดข้อผิดพลาด: {error.message}</div>;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (!isValid(date)) return 'วันที่ไม่ถูกต้อง';
    
    return format(date, 'd MMMM yyyy');
  };

  return (
    <div className="bg-white p-1 rounded-lg">
      <h3 className="text-lg text-stone-800 font-bold">📢 ประกาศจากบริษัท</h3>
      <div className="mt-0">
        {announcements.length === 0 ? (
          <p className="text-sm">ไม่มีประกาศ</p>
        ) : (
          <div className="flex flex-col gap-1">
            <div className="flex justify-start p-1 my-4 mb-2">
              <div className="w-[15%]">
                <p className="text-sm font-semibold">วันที่</p>
              </div>
              <div className="w-[85%] mr-10">
                <p className="text-sm font-semibold">เรื่อง</p>
              </div>
            </div>
            
            <div className="max-h-[160px] overflow-y-auto pr-2 scrollbar-hover-show">
              <div className="flex flex-col gap-1">
                {announcements.map((announcement) => {
                  const date = new Date(announcement.date);
                  return (
                    <div 
                      key={announcement.id} 
                      className="flex justify-start p-2 rounded-lg border-b hover:bg-gray-50 cursor-pointer transition-colors"
                      onClick={() => openAnnouncementModal(announcement)}
                    >
                      <div className="w-[15%] mr-3">
                        <p className="text-sm">
                          {isValid(date) ? format(date, 'd/M/yy') : 'วันที่ไม่ถูกต้อง'}
                        </p>
                      </div>
                      <div className="w-[85%]">
                        <p className="text-sm">{announcement.title}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>

      {isModalOpen && (
        selectedAnnouncement ? (
          <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex justify-center items-center p-4">
            <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full animate-fadeIn">
              <div className="flex justify-between items-start p-4 border-b">
                <h3 className="text-xl font-semibold text-gray-900">{selectedAnnouncement.title}</h3>
                <button
                  onClick={closeAnnouncementModal}
                  className="text-gray-400 hover:text-gray-700 transition-colors"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>
              
              <div className="p-4 max-h-[70vh] overflow-auto">
                <div className="flex items-center mb-4 text-gray-600">
                  <Calendar className="h-4 w-4 mr-2" />
                  <span>{formatDate(selectedAnnouncement.date)}</span>
                </div>
                
                {(selectedAnnouncement.content_format === 'markdown' || 
                  isMarkdownContent(selectedAnnouncement.content || selectedAnnouncement.description)) ? (
                  <div className="markdown-body">
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm]}
                      rehypePlugins={[rehypeRaw]}
                      components={markdownComponents}
                    >
                      {selectedAnnouncement.content || selectedAnnouncement.description || 'ไม่มีรายละเอียดเพิ่มเติม'}
                    </ReactMarkdown>
                  </div>
                ) : (
                  <div className="prose max-w-none">
                    <p className="text-gray-800 whitespace-pre-wrap">
                      {selectedAnnouncement.content || selectedAnnouncement.description || 'ไม่มีรายละเอียดเพิ่มเติม'}
                    </p>
                  </div>
                )}

                {/* แสดงไฟล์แนบ */}
                {selectedAnnouncement.attachments && 
                  (Array.isArray(selectedAnnouncement.attachments) ? 
                    selectedAnnouncement.attachments : 
                    typeof selectedAnnouncement.attachments === 'string' ? 
                      JSON.parse(selectedAnnouncement.attachments) : 
                      []).length > 0 && (
                  <div className="mt-6 border-t pt-4">
                    <h4 className="font-medium text-gray-900 mb-2 flex items-center">
                      <Paperclip className="h-4 w-4 mr-1" />
                      ไฟล์แนบ
                    </h4>
                    <ul className="space-y-2">
                      {(Array.isArray(selectedAnnouncement.attachments) ? 
                        selectedAnnouncement.attachments : 
                        typeof selectedAnnouncement.attachments === 'string' ? 
                          JSON.parse(selectedAnnouncement.attachments) : 
                          []).map((file, index) => (
                        <li key={index} className="flex items-center justify-between p-2 bg-gray-50 rounded border">
                          <div className="flex items-center">
                            <Paperclip className="h-4 w-4 mr-2 text-gray-500" />
                            <span className="text-sm">{file.name}</span>
                          </div>
                          <a 
                            // แก้ไข URL ให้ใช้ API_URL
                            href={`${API_URL}/announcements/download/${encodeURIComponent(file.path.split('/').pop())}`} 
                            download
                            target="_blank"
                            rel="noreferrer"
                            className="text-blue-500 hover:text-blue-700"
                          >
                            <Download className="h-4 w-4" />
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              
              <div className="bg-gray-50 px-4 py-3 flex justify-end rounded-b-lg">
                <button
                  onClick={closeAnnouncementModal}
                  className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors"
                >
                  ปิด
                </button>
              </div>
            </div>
          </div>
        ) : (
          <AnnouncementModalSkeleton />
        )
      )}
    </div>
  );
};

export default Announcements;
