import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "../../utils/axiosConfig";
import { UPLOAD_URL } from "../../utils/configBridge";
import { format } from "date-fns";
import { 
  Calendar, 
  User, 
  Tag,  
  ArrowLeft, 
  Clock,
  Printer,
  Share2
} from "lucide-react";
import to_logo from "../../img/To-mini_logo.png";
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { isMarkdownContent, markdownComponents } from '../../lib/markdownUtils';
import '../../styles/markdown.css';

const ArticleDetail = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [relatedArticles, setRelatedArticles] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate('/');
      return;
    }

    const fetchArticle = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`/article/${slug}`);
        setArticle(response.data);
        
        const allArticlesResponse = await axios.get('/articles');
        const filtered = allArticlesResponse.data
          .filter(a => a.category === response.data.category && a.article_id !== response.data.article_id && a.is_active === 1)
          .sort((a, b) => new Date(b.date_posted) - new Date(a.date_posted))
          .slice(0, 3);
        
        setRelatedArticles(filtered);
        
      } catch (err) {
        console.error("Error fetching article:", err);
        setError("ไม่พบบทความที่คุณต้องการ หรือบทความอาจถูกลบไปแล้ว");
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [slug, navigate]);

  useEffect(() => {
    if (article) {
      document.title = `${article.title} | คลังความรู้`;
      
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', article.summary || article.content.substring(0, 150) + '...');
      } else {
        const meta = document.createElement('meta');
        meta.name = 'description';
        meta.content = article.summary || article.content.substring(0, 150) + '...';
        document.head.appendChild(meta);
      }
    }
    
    return () => {
      document.title = 'คลังความรู้';
      
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', 'คลังความรู้สำหรับบุคลากร');
      }
    };
  }, [article]);

  if (loading) {
    return (
      <div className="container mx-auto px-4 py-12 min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto px-4 py-12 min-h-screen">
        <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-lg p-8">
          <div className="text-center">
            <h1 className="text-2xl font-bold text-gray-800 mb-4">ขออภัย</h1>
            <p className="text-gray-600">{error}</p>
            <button 
              onClick={() => navigate('/knowledge')}
              className="mt-6 px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
            >
              กลับไปยังหน้าบทความ
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (!article) return null;

  const customMarkdownComponents = {
    ...markdownComponents,
    img: ({node, alt, src, title, ...props}) => {
      let width = null;
      let height = null;
      let align = 'left';
      let className = '';
      
      let imageSrc = src;
      if (src.startsWith('/') && !src.startsWith('//')) {
        imageSrc = `${UPLOAD_URL}${src}`;
      } else if (!src.startsWith('http')) {
        imageSrc = `${UPLOAD_URL}/${src}`;
      }
      
      if (alt) {
        const sizeMatch = alt.match(/=(\d+%?)(?:x(\d+%?))?$/);
        if (sizeMatch) {
          width = sizeMatch[1];
          height = sizeMatch[2] || 'auto';
          alt = alt.replace(/=(\d+%?)(?:x(\d+%?))?$/, '').trim();
        }
        
        const alignMatch = alt.match(/\[(left|center|right)\]/);
        if (alignMatch) {
          align = alignMatch[1];
          alt = alt.replace(/\[(left|center|right)\)]/, '').trim();
          
          if (align === 'center') {
            className = 'mx-auto block';
          } else if (align === 'right') {
            className = 'ml-auto block';
          }
        }
      }
      
      return (
        <div style={{textAlign: align, margin: '1.5em 0'}}>
          <img 
            src={imageSrc} 
            alt={alt || ''} 
            title={title || alt || ''} 
            style={{
              width: width || '100%',
              height: height || 'auto',
              maxWidth: '100%'
            }}
            className={`rounded-lg shadow-md ${className}`}
            onError={(e) => {
              console.error(`Failed to load image: ${imageSrc}`);
              e.target.src = `${UPLOAD_URL}/no-image.jpg`;
            }}
            {...props} 
          />
          {title && (
            <p className="text-center text-gray-500 text-sm mt-1">{title}</p>
          )}
        </div>
      );
    }
  };

  return (
    <div className="bg-gray-50 min-h-screen pb-16">
      <div 
        className="w-full h-64 md:h-96 bg-cover bg-center relative"
        style={article.image_filename ? {
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url(${UPLOAD_URL}/article/${article.image_filename})`
        } : {
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url(https://images.unsplash.com/photo-1499750310107-5fef28a66643?ixlib=rb-4.0.3)`
        }}
      >
        <div className="container mx-auto px-4 h-full flex flex-col justify-end pb-8 relative">
          <button 
            onClick={() => navigate('/knowledge')} 
            className="absolute top-6 left-6 flex items-center gap-2 text-white bg-black bg-opacity-30 px-4 py-2 rounded-full hover:bg-opacity-50 transition-all duration-300"
          >
            <ArrowLeft className="h-4 w-4" />
            <span>กลับ</span>
          </button>
          <div>
            <span className="inline-block px-3 py-1 bg-blue-600 text-white text-sm font-medium rounded-full mb-4">
              {article.category || 'ทั่วไป'}
            </span>
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-white leading-tight">
              {article.title}
            </h1>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 py-8">
        <div className="max-w-4xl mx-auto">
          <div className="flex flex-wrap items-center gap-x-6 gap-y-3 py-4 text-gray-600 border-b border-gray-200 mb-8">
            <div className="flex items-center">
              <Calendar className="h-4 w-4 mr-2" />
              <span>{article.date_posted ? format(new Date(article.date_posted), 'dd MMMM yyyy') : '-'}</span>
            </div>
            <div className="flex items-center">
              <User className="h-4 w-4 mr-2" />
              <span>{article.author || 'Admin'}</span>
            </div>
            <div className="flex items-center">
              <Tag className="h-4 w-4 mr-2" />
              <span>{article.category || 'ทั่วไป'}</span>
            </div>
            <div className="flex items-center">
              <Clock className="h-4 w-4 mr-2" />
              <span>อ่านใช้เวลาประมาณ {Math.max(1, Math.ceil(article.content.length / 1000))} นาที</span>
            </div>
          </div>

          <div className="flex items-center gap-4 mb-6">
            <button 
              onClick={() => window.print()} 
              className="flex items-center gap-2 text-gray-600 hover:text-blue-600 transition-colors"
            >
              <Printer className="h-4 w-4" />
              <span>พิมพ์บทความ</span>
            </button>
            
            <button 
              onClick={() => {
                navigator.share({
                  title: article.title,
                  text: article.summary || article.title,
                  url: window.location.href
                }).catch(err => console.log('Error sharing:', err));
              }}
              className="flex items-center gap-2 text-gray-600 hover:text-blue-600 transition-colors"
            >
              <Share2 className="h-4 w-4" />
              <span>แชร์</span>
            </button>
          </div>

          <div className="prose prose-lg max-w-none text-left">
            <div className="prose max-w-none article-content">
              {article && ((article.content_format === 'markdown' || isMarkdownContent(article.content)) ? (
                <div className="markdown-body">
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                    components={customMarkdownComponents}
                  >
                    {article.content}
                  </ReactMarkdown>
                </div>
              ) : (
                <div dangerouslySetInnerHTML={{ __html: article.content }} />
              ))}
            </div>
          </div>

          <div className="mt-12 bg-gray-100 rounded-xl p-6 flex flex-col md:flex-row gap-6 items-center">
            <div className="w-24 h-24 bg-blue-200 rounded-full flex items-center justify-center flex-shrink-0 sm:ml-10">
              <img src= {to_logo} alt="Article Logo" className="h-24 w-24 rounded-full" />
            </div>  
            <div className="justify-center  sm:ml-10">
              <h3 className="text-xl font-bold text-gray-800 mb-2">{article.author || 'Admin'}</h3>
              <p className="text-gray-600">ผู้เขียนบทความและผู้เชี่ยวชาญด้านเนื้อหา ได้แบ่งปันความรู้ที่มีประโยชน์ผ่านบทความนี้</p>
            </div>
          </div>
        </div>
      </div>

      {relatedArticles.length > 0 && (
        <div className="container mx-auto px-4 py-8 mt-8">
          <div className="max-w-4xl mx-auto">
            <h2 className="text-2xl font-bold text-gray-800 mb-6">บทความที่เกี่ยวข้อง</h2>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {relatedArticles.map(related => (
                <div key={related.article_id} className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-all duration-300">
                  <div 
                    className="h-40 bg-cover bg-center"
                    style={related.image_filename ? {
                      backgroundImage: `url(${UPLOAD_URL}/article/${related.image_filename})`
                    } : {
                      backgroundImage: `url(https://images.unsplash.com/photo-1501504905252-473c47e087f8?ixlib=rb-4.0.3)`
                    }}
                  ></div>
                  
                  <div className="p-4">
                    <span className="inline-block px-2 py-1 text-xs font-semibold bg-blue-100 text-blue-800 rounded-full mb-2">
                      {related.category || 'ทั่วไป'}
                    </span>
                    <h3 className="text-lg font-medium text-gray-800 mb-2 line-clamp-2">{related.title}</h3>
                    <p className="text-gray-600  text-sm mb-4 line-clamp-2">
                      {related.summary || (related.content && related.content.substring(0, 100))}
                    </p>
                    
                    <a 
                      href={`/knowledge/${related.slug || related.article_id}`}
                      className="inline-block text-blue-600 font-medium text-sm hover:text-blue-800 transition-colors"
                    >
                      อ่านเพิ่มเติม →
                    </a>
                  </div>
                </div>
              ))}
            </div>
            
            <div className="text-center mt-8">
              <button 
                onClick={() => navigate('/knowledge')}
                className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors inline-flex items-center"
              >
                <ArrowLeft className="h-4 w-4 mr-2" />
                กลับไปที่บทความทั้งหมด
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ArticleDetail;