import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import activityLogger from '../utils/activityLogger';

const useActivityLogging = () => {
  const location = useLocation();
  
  // บันทึกเมื่อมีการเปลี่ยนหน้า
  useEffect(() => {
    // บันทึกการเข้าชมหน้าใหม่
    activityLogger.pageView();
    
    // เมื่อออกจากหน้านี้
    return () => {
      // บันทึกการออกจากหน้า
      activityLogger.pageExit();
    };
  }, [location.pathname]);
  
  // ตั้งค่า event listeners สำหรับตรวจจับการออกจากแอพ
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        activityLogger.logActivity({
          event_type: 'app_inactive',
          additional_info: {
            page: location.pathname,
            duration: Math.floor((Date.now() - activityLogger.pageLoadTime) / 1000)
          }
        });
      } else if (document.visibilityState === 'visible') {
        // กลับมาที่แอพอีกครั้ง
        activityLogger.logActivity({
          event_type: 'app_active',
          additional_info: {
            page: location.pathname
          }
        });
      }
    };
    
    document.addEventListener('visibilitychange', handleVisibilityChange);
    
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [location.pathname]);
  
  return null;
};

export default useActivityLogging;