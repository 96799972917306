import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { format, isValid } from 'date-fns';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const CompanyForms = () => {
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios
      .get('/company-forms')
      .then((response) => {
        // เรียงข้อมูลตาม order_position จากมากไปน้อย หรือ id ถ้าไม่มี order_position
        const sortedData = [...response.data]
          .sort((a, b) => {
            if (a.order_position !== undefined && b.order_position !== undefined) {
              return b.order_position - a.order_position;
            }
            return b.id - a.id;
          });
          
        setForms(sortedData);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  // ฟังก์ชั่นดาวน์โหลดไฟล์ (แก้ไขให้ส่ง token ไปด้วย)
  const handleDownload = (form) => {
    // ดึง token จาก localStorage
    const token = localStorage.getItem('token');
    
    // ถ้าไม่มี token ให้แจ้งเตือนผู้ใช้ว่าต้อง login ก่อน
    if (!token) {
      MySwal.fire({
        title: 'กรุณาเข้าสู่ระบบ',
        text: 'คุณต้องเข้าสู่ระบบก่อนจึงจะสามารถดาวน์โหลดแบบฟอร์มได้',
        icon: 'warning',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#3085d6'
      });
      return;
    }

    // ใช้ axios ในการดาวน์โหลดแทนที่จะใช้ window.location.href
    axios({
      url: `/company-forms/download/${form.id}`,
      method: 'GET',
      responseType: 'blob', // สำคัญ! ระบุว่าต้องการข้อมูลเป็น blob
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((response) => {
      // สร้าง URL จาก blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      // สร้าง link ชั่วคราว
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', form.file_name || `form-${form.id}.pdf`);
      document.body.appendChild(link);
      link.click();
      // ทำความสะอาด
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    }).catch((error) => {
      console.error('Error downloading file:', error);
      
      // ตรวจสอบว่าเป็น error เรื่อง auth หรือไม่
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        MySwal.fire({
          title: 'กรุณาเข้าสู่ระบบใหม่',
          text: 'Session หมดอายุ กรุณาเข้าสู่ระบบอีกครั้ง',
          icon: 'error',
          confirmButtonText: 'เข้าสู่ระบบ',
          confirmButtonColor: '#3085d6'
        }).then(() => {
          // ล้าง token และ redirect ไปยังหน้า login
          localStorage.removeItem('token');
          window.location.href = '/login';
        });
      } else {
        MySwal.fire({
          title: 'เกิดข้อผิดพลาด',
          text: 'ไม่สามารถดาวน์โหลดไฟล์ได้',
          icon: 'error',
          confirmButtonText: 'ตกลง',
          confirmButtonColor: '#3085d6'
        });
      }
    });
  };

  if (loading) {
    return (
      <div className="bg-white p-1 rounded-lg">
        <h3 className="text-lg text-stone-800 font-bold">📋 แบบฟอร์มของบริษัท</h3>
        <div className="mt-0">
          <div className="flex flex-col gap-1">
            {/* ส่วนหัวข้อ */}
            <div className="flex justify-start p-1 my-4 mb-2">
              <div className="w-[15%]">
                <p className="text-sm font-semibold">วันที่อัพเดท</p>
              </div>
              <div className="w-[85%] mr-10">
                <p className="text-sm font-semibold text-end">แบบฟอร์ม</p>
              </div>
            </div>
            
            {/* ส่วน Skeleton Loading */}
            <div className="max-h-[160px] overflow-y-auto pr-2 scrollbar-hover-show">
              <div className="flex flex-col gap-1">
                {[...Array(5)].map((_, index) => (
                  <div 
                    key={index} 
                    className="flex justify-start p-2 rounded-lg border-b animate-pulse"
                  >
                    <div className="w-[15%] mr-3">
                      <div className="h-4 w-12 bg-gray-200 rounded"></div>
                    </div>
                    <div className="w-[85%]">
                      <div className="h-4 w-24 bg-gray-200 rounded float-right mr-8"></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return <div>เกิดข้อผิดพลาด: {error.message}</div>;
  }

  return (
    <div className="bg-white p-1 rounded-lg">
      <h3 className="text-lg text-stone-800 font-bold">📋 แบบฟอร์มของบริษัท</h3>
      <div className="mt-0">
        {forms.length === 0 ? (
          <p className="text-sm">ไม่มีแบบฟอร์ม</p>
        ) : (
          <div className="flex flex-col gap-1">
            {/* ส่วนหัวข้อ */}
            <div className="flex justify-start p-1 my-4 mb-2">
              <div className="w-[15%]">
                <p className="text-sm font-semibold">วันที่อัพเดท</p>
              </div>
              <div className="w-[85%] mr-10">
                <p className="text-sm font-semibold text-end">แบบฟอร์ม</p>
              </div>
            </div>
            
            {/* ส่วนเนื้อหา - จำกัด 160px และมี scroll */}
            <div className="max-h-[160px] overflow-y-auto pr-2 scrollbar-hover-show">
              <div className="flex flex-col gap-1">
                {forms.map((form) => {
                  // ใช้ updated_at ถ้ามีค่า หากไม่มีค่าให้ใช้ created_at เป็น fallback
                  const date = new Date(form.updated_at || form.created_at);
                  return (
                    <div 
                      key={form.id} 
                      className="flex justify-start p-2 rounded-lg border-b cursor-pointer hover:bg-gray-50"
                      onClick={() => handleDownload(form)}
                    >
                      <div className="w-[15%] mr-3">
                        <p className="text-sm">
                          {isValid(date) ? format(date, 'd/M/yy') : 'วันที่ไม่ถูกต้อง'}
                        </p>
                      </div>
                      <div className="w-[85%]">
                        <p className="text-sm text-blue-700 hover:underline text-end mr-8">{form.title}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanyForms;