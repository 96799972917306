// ไฟล์นี้จะทำหน้าที่เป็นตัวกลางในการอ่านค่าจาก .env

// อ่านค่า URL จาก environment variables
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';
const UPLOAD_URL = process.env.REACT_APP_UPLOAD_URL || 'http://localhost:5000/uploads';
const SITE_URL = process.env.REACT_APP_SITE_URL || '';

// ค่า config สำหรับ token และการตั้งค่าอื่นๆ
const configValues = {
  // Token settings
  tokens: {
    admin: 'admin_token',
    user: 'token',
  },
  
  // Token expiration keys
  tokenExpirations: {
    admin: 'adminTokenExpiration',
    adminDisplay: 'adminTokenExpirationDisplay',
    user: 'tokenExpiration',
    userDisplay: 'tokenExpirationDisplay',
  },
  
  // Token expiration display keys
  tokenExpirationDisplays: {
    admin: 'adminTokenExpirationDisplay',
    user: 'tokenExpirationDisplay',
  },

  // Endpoints
  endpoints: {
    refreshToken: '/refresh_token',
    refreshUserToken: '/refresh_token/user',
    refreshAdminToken: '/refresh_token/admin'
  }
};

export { 
  API_URL, 
  UPLOAD_URL, 
  SITE_URL,
  configValues
};