import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { format, isValid } from 'date-fns';

const Events = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/events?t=${new Date().getTime()}`);
      
      // เนื่องจาก backend จะส่งข้อมูลที่เรียงตาม order_position ASC มาให้แล้ว (น้อยไปมาก)
      // ไม่จำเป็นต้อง sort ข้อมูลอีก เพียงนำไปใช้ได้เลย
      setEvents(response.data.slice(0, 10)); // แสดง 10 รายการแรก
      setLoading(false);
    } catch (err) {
      console.error('เกิดข้อผิดพลาดในการดึงข้อมูลกิจกรรม:', err);
      setError('ไม่สามารถโหลดข้อมูลกิจกรรมได้');
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="bg-white p-1 rounded-lg">
        <h3 className="text-lg text-stone-800 font-bold">🎉 กิจกรรม</h3>
        <div className="mt-4 space-y-2">
          {[...Array(4)].map((_, index) => (
            <div key={index} className="animate-pulse flex items-center">
              <div className="w-[15%] h-4 bg-gray-200 rounded"></div>
              <div className="ml-3 w-[85%] h-4 bg-gray-200 rounded"></div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white p-1 rounded-lg">
        <h3 className="text-lg text-stone-800 font-bold">🎉 กิจกรรม</h3>
        <div className="mt-4 text-red-500">
          <p>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white p-1 rounded-lg">
      <h3 className="text-lg text-stone-800 font-bold">🎉 กิจกรรม / วันหยุดประจำปี</h3>
      <div className="mt-0">
        {events.length === 0 ? (
          <p className="text-sm mt-20">ไม่มีกิจกรรม</p>
        ) : (
          <div className="flex flex-col gap-1">
            <div className="flex justify-start p-1 my-4 mb-2">
              <div className="w-[30%]">
                <p className="text-sm font-semibold">วันที่</p>
              </div>
              <div className="w-[70%] mr-10">
                <p className="text-sm font-semibold">กิจกรรม</p>
              </div>
            </div>
            <div className="max-h-[300px] overflow-y-auto pr-2 scrollbar-hover-show">
              <div className="flex flex-col gap-1">
                {events.map((event) => {
                  const startDate = new Date(event.start);
                  const endDate = event.end ? new Date(event.end) : null;
                  const today = new Date();
                  today.setHours(0, 0, 0, 0); // ตั้งเวลาให้เป็น 00:00:00
                  
                  // ตรวจสอบว่าเป็นกิจกรรมที่ผ่านไปแล้วหรือไม่
                  const isPastEvent = endDate ? endDate < today : startDate < today;
                  
                  return (
                    <div 
                      key={event.id} 
                      className={`flex justify-start p-2 rounded-lg border-b hover:bg-gray-50 ${
                        isPastEvent ? 'bg-gray-100' : ''
                      }`}
                    >
                      <div className="w-[30%] mr-3 flex items-center">
                        <div 
                          className={`w-2 h-2 rounded-full mr-2 ${
                            isPastEvent ? 'opacity-50' : ''
                          }`}
                          style={{ backgroundColor: event.color || '#3b82f6' }}
                        ></div>
                        <p className={`text-xs ${isPastEvent ? 'text-gray-500' : ''}`}>
                          {isValid(startDate) ? format(startDate, 'd/M/yy') : '-'}
                          {endDate && !event.allDay ? 
                            <span> - {isValid(endDate) ? format(endDate, 'd/M/yy') : ''}</span> 
                            : ''
                          }
                        </p>
                      </div>
                      <div className="w-[70%]">
                        <p className={`text-sm truncate ${isPastEvent ? 'text-gray-500' : ''}`} title={event.title}>
                          {event.title}
                        </p>
                        {event.description && (
                          <p className={`text-xs ${isPastEvent ? 'text-gray-400' : 'text-gray-500'} truncate mt-0.5`} title={event.description}>
                            {event.description}
                          </p>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Events;