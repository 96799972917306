import React, { useState, useEffect } from "react";
import LoginCover from "../../img/Login-cover2.jpg";
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import activityLogger from '../../utils/activityLogger';
import axios from '../../utils/axiosConfig'; // ใช้ axios ที่ตั้งค่าไว้แล้ว

export default function LoginPage() {
  const [memberId, setMemberId] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  // เช็คว่ามี token ใน localStorage หรือไม่
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      console.log("ระดับสิทธิของผู้ใช้:", decodedToken.access_level);
      navigate('/home');
    }
  }, [navigate]);

  // ฟังก์ชันจัดการเข้าสู่ระบบ
  const handleLogin = async (e) => {
    e.preventDefault();
    setMessage("");

    try {
      // ใช้ axios ที่กำหนดค่าไว้แล้ว - ไม่ต้องระบุ URL เต็ม
      const response = await axios.post('/login', {
        member_id: memberId,
        password: password
      });

      // ดำเนินการเมื่อล็อกอินสำเร็จ
      const data = response.data;
      
      setMessage("🎉 เข้าสู่ระบบสำเร็จ!");

      // เก็บ token ที่ได้รับจากเซิร์ฟเวอร์
      localStorage.setItem("token", data.token);
      
      // เพิ่มการบันทึก log เมื่อล็อกอินสำเร็จ
      activityLogger.login(data.name || memberId, false);

      localStorage.setItem("name", data.name);

      // ตั้งค่า token expiration 
      const expirationDate = new Date();
      expirationDate.setMinutes(expirationDate.getMinutes() + 1440);
      localStorage.setItem("tokenExpiration", expirationDate.toISOString());
      localStorage.setItem("tokenExpirationDisplay", expirationDate.toLocaleString('th-TH', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }));

      // Decode token และใช้ข้อมูลทั้งหมดที่จำเป็น
      const decodedToken = jwtDecode(data.token);
      console.log("ข้อมูลผู้ใช้จาก token:", decodedToken);

      // สร้าง currentUser object ที่มีข้อมูลครบถ้วน
      const currentUser = {
        id: decodedToken.id || decodedToken.user_id, // รองรับทั้งกรณี id และ user_id
        member_id: decodedToken.member_id,
        name: decodedToken.name,
        access_level: decodedToken.access_level
      };
      
      // เก็บข้อมูลผู้ใช้ทั้งหมดใน localStorage
      localStorage.setItem("currentUser", JSON.stringify(currentUser));
      console.log("เก็บข้อมูลผู้ใช้:", currentUser);

      // ใช้ข้อมูลจาก API response หากมี แทนที่จะใช้เฉพาะข้อมูลจาก token
      if (data.currentUser) {
        localStorage.setItem("currentUser", JSON.stringify(data.currentUser));
        console.log("เก็บข้อมูลผู้ใช้จาก API:", data.currentUser);
      }

      // เปลี่ยนหน้าไปยัง /home หรือ path ที่ส่งกลับมาจาก API
      navigate(data.redirectTo || '/home');
      
    } catch (error) {
      console.error("เกิดข้อผิดพลาด:", error);
      
      // แสดงข้อความข้อผิดพลาดจาก API หรือข้อความเริ่มต้นถ้าไม่มี
      if (error.response && error.response.data) {
        setMessage(error.response.data.message || "❌ เกิดข้อผิดพลาดในการเข้าสู่ระบบ");
      } else if (error.request) {
        // กรณีที่ส่ง request แล้วแต่ไม่ได้รับ response
        setMessage("❌ ไม่สามารถเชื่อมต่อเซิร์ฟเวอร์ได้");
      } else {
        setMessage("❌ เกิดข้อผิดพลาดที่ไม่ทราบสาเหตุ");
      }
    }
  };

  // ส่วนของการ render ยังคงเหมือนเดิม
  return (
    <div className="flex h-screen relative">
      {/* พื้นหลังสำหรับมือถือ - คงเดิม */}
      <div
        className="absolute inset-0 bg-cover bg-center lg:hidden z-0"
        style={{ backgroundImage: `url(${LoginCover})` }}
      >
        {/* เพิ่มโอเวอร์เลย์เพื่อให้ฟอร์มล็อกอินอ่านง่ายบนพื้นหลัง */}
        <div className="absolute inset-0 bg-black bg-opacity-60"></div>
      </div>

      {/* ส่วนรูปภาพแสดงบนเดสก์ท็อปเท่านั้น - คงเดิม */}
      <div
        className="w-1/2 bg-cover bg-center lg:block hidden"
        style={{ backgroundImage: `url(${LoginCover})` }}
      ></div>

      {/* ส่วนฟอร์มล็อกอิน - ปรับขนาดและสไตล์ */}
      <div className="w-full lg:w-1/2 flex items-center justify-center z-10 relative px-4 sm:px-6">
        <div className="w-full max-w-sm sm:max-w-md bg-white/95 dark:bg-slate-800/95 p-6 rounded-xl shadow-xl backdrop-blur-sm mx-auto">
          <h2 className="text-xl sm:text-2xl font-bold text-center mb-5 text-gray-800 dark:text-white">ลงชื่อเข้าใช้</h2>

          {/* ข้อความแจ้งเตือน */}
          {message && (
            <div
              className={`mb-4 p-3 text-center text-sm rounded-lg ${
                message.includes("สำเร็จ")
                  ? "bg-green-100 text-green-700 border border-green-200"
                  : "bg-red-50 text-red-600 border border-red-200"
              }`}
            >
              {message}
            </div>
          )}

          <form onSubmit={handleLogin} className="space-y-4">
            <div>
              <label className="block text-gray-700 dark:text-gray-300 text-sm font-medium mb-1.5">
                รหัสสมาชิก
              </label>
              <input
                type="text"
                className="w-full p-2.5 border border-gray-300 dark:border-gray-700 rounded-lg text-sm bg-white dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:text-white"
                placeholder="กรอกรหัสสมาชิก"
                value={memberId}
                onChange={(e) => setMemberId(e.target.value)}
                required
              />
            </div>
            <div>
              <label className="block text-gray-700 dark:text-gray-300 text-sm font-medium mb-1.5">
                รหัสผ่าน
              </label>
              <input
                type="password"
                className="w-full p-2.5 border border-gray-300 dark:border-gray-700 rounded-lg text-sm bg-white dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:text-white"
                placeholder="กรอกรหัสผ่าน"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-2.5 px-4 mt-2 rounded-lg hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 transition-colors text-sm font-medium"
            >
              เข้าสู่ระบบ
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
