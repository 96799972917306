// ไฟล์นี้จะทำหน้าที่เป็นตัวกลางในการอ่านค่าจาก .env และให้ URL ที่ถูกต้อง
// โดยไม่กระทบโค้ดเดิม

// อ่านค่า URL จาก environment variables
const API_URL = process.env.REACT_APP_API_URL || '/api';
const UPLOAD_URL = process.env.REACT_APP_UPLOAD_URL || '/uploads';
const SITE_URL = process.env.REACT_APP_SITE_URL || '';

// ฟังก์ชันสำหรับแปลง hardcoded URL เป็น URL จาก config
// สำหรับโค้ดที่ใช้ URL แบบ hardcoded
const getApiUrl = (path) => {
  // ถ้า path มี http://localhost:5000/api อยู่แล้ว ให้แทนที่ด้วย API_URL
  if (path.includes('http://localhost:5000/api')) {
    return path.replace('http://localhost:5000/api', API_URL);
  }
  
  // ถ้ามีแค่ /api ให้แทนที่ด้วย API_URL
  if (path.startsWith('/api')) {
    return API_URL + path.substring(4);
  }
  
  return path;
};

// ฟังก์ชันสำหรับแปลง hardcoded upload URL
const getUploadUrl = (path) => {
  if (path.includes('http://localhost:5000/uploads')) {
    return path.replace('http://localhost:5000/uploads', UPLOAD_URL);
  }
  
  if (path.startsWith('/uploads')) {
    return UPLOAD_URL + path.substring(8);
  }
  
  return path;
};

export { 
  API_URL, 
  UPLOAD_URL, 
  SITE_URL,
  getApiUrl,
  getUploadUrl
};