import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../utils/axiosConfig';
import { jwtDecode } from 'jwt-decode';
import activityLogger from '../../utils/activityLogger';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const TokenTestPage = () => {
  const navigate = useNavigate();
  
  // State สำหรับเก็บข้อมูล Token
  const [userToken, setUserToken] = useState('');
  const [adminToken, setAdminToken] = useState('');
  const [userTokenInfo, setUserTokenInfo] = useState(null);
  const [adminTokenInfo, setAdminTokenInfo] = useState(null);
  const [testOutput, setTestOutput] = useState([]);
  const [timeLeft, setTimeLeft] = useState({ user: null, admin: null });
  const [loading, setLoading] = useState(false);
  
  // ข้อมูลสำหรับทดสอบล็อกอิน
  const [loginData, setLoginData] = useState({
    userId: '',
    password: '',
    isAdmin: false
  });

  // โหลดข้อมูล Token เมื่อหน้าเว็บโหลด
  useEffect(() => {
    const userTokenFromLS = localStorage.getItem('token');
    const adminTokenFromLS = localStorage.getItem('admin_token');
    
    if (userTokenFromLS) {
      setUserToken(userTokenFromLS);
      try {
        const decoded = jwtDecode(userTokenFromLS);
        setUserTokenInfo(decoded);
      } catch (error) {
        addLogEntry('❌ User token ไม่ถูกต้อง');
      }
    }
    
    if (adminTokenFromLS) {
      setAdminToken(adminTokenFromLS);
      try {
        const decoded = jwtDecode(adminTokenFromLS);
        setAdminTokenInfo(decoded);
      } catch (error) {
        addLogEntry('❌ Admin token ไม่ถูกต้อง');
      }
    }
  }, []);

  // คำนวณเวลาที่เหลือของ Token
  useEffect(() => {
    const timer = setInterval(() => {
      if (userTokenInfo && userTokenInfo.exp) {
        const currentTime = Math.floor(Date.now() / 1000);
        const userTimeLeft = userTokenInfo.exp - currentTime;
        
        setTimeLeft(prev => ({
          ...prev,
          user: userTimeLeft > 0 ? userTimeLeft : 0
        }));
      }
      
      if (adminTokenInfo && adminTokenInfo.exp) {
        const currentTime = Math.floor(Date.now() / 1000);
        const adminTimeLeft = adminTokenInfo.exp - currentTime;
        
        setTimeLeft(prev => ({
          ...prev,
          admin: adminTimeLeft > 0 ? adminTimeLeft : 0
        }));
      }
    }, 1000);
    
    return () => clearInterval(timer);
  }, [userTokenInfo, adminTokenInfo]);

  // เพิ่มข้อมูลลงในประวัติการทดสอบ
  const addLogEntry = (message) => {
    const timestamp = new Date().toLocaleTimeString('th-TH');
    setTestOutput(prev => [`[${timestamp}] ${message}`, ...prev]);
  };

  // ล้างข้อมูล Token
  const clearTokens = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('admin_token');
    localStorage.removeItem('name');
    localStorage.removeItem('admin_name');
    localStorage.removeItem('tokenExpiration');
    localStorage.removeItem('adminTokenExpiration');
    localStorage.removeItem('tokenExpirationDisplay');
    localStorage.removeItem('adminTokenExpirationDisplay');
    localStorage.removeItem('currentUser');
    setUserToken('');
    setAdminToken('');
    setUserTokenInfo(null);
    setAdminTokenInfo(null);
    addLogEntry('🧹 ล้างข้อมูล Token ทั้งหมดแล้ว');
  };

  // ทดสอบล็อกอิน
  const handleLogin = async () => {
    setLoading(true);
    try {
      const endpoint = loginData.isAdmin ? '/adminlogin' : '/login';
      const payload = loginData.isAdmin 
        ? { username: loginData.userId, password: loginData.password }
        : { member_id: loginData.userId, password: loginData.password };
      
      addLogEntry(`🔑 กำลังล็อกอินไปยัง ${endpoint} ด้วย ${loginData.userId}`);
      
      const response = await axios.post(endpoint, payload);
      
      addLogEntry(`✅ ล็อกอินสำเร็จ: ${loginData.isAdmin ? 'แอดมิน' : 'ผู้ใช้'}`);
      
      if (loginData.isAdmin) {
        // บันทึก token แบบ admin
        localStorage.setItem('admin_token', response.data.token);
        localStorage.setItem('admin_name', response.data.name || loginData.userId);
        
        try {
          const decoded = jwtDecode(response.data.token);
          const expirationDate = new Date(decoded.exp * 1000);
          localStorage.setItem('adminTokenExpiration', expirationDate.toISOString());
          localStorage.setItem('adminTokenExpirationDisplay', expirationDate.toLocaleString('th-TH'));
          
          setAdminToken(response.data.token);
          setAdminTokenInfo(decoded);
          addLogEntry(`ℹ️ Admin Token ใหม่: exp=${expirationDate.toLocaleString('th-TH')}`);
        } catch (error) {
          addLogEntry('❌ ไม่สามารถถอดรหัส Admin Token ได้');
        }
      } else {
        // บันทึก token แบบ user
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('name', response.data.name || loginData.userId);
        
        try {
          const decoded = jwtDecode(response.data.token);
          const expirationDate = new Date(decoded.exp * 1000);
          localStorage.setItem('tokenExpiration', expirationDate.toISOString());
          localStorage.setItem('tokenExpirationDisplay', expirationDate.toLocaleString('th-TH'));
          
          setUserToken(response.data.token);
          setUserTokenInfo(decoded);
          addLogEntry(`ℹ️ User Token ใหม่: exp=${expirationDate.toLocaleString('th-TH')}`);
        } catch (error) {
          addLogEntry('❌ ไม่สามารถถอดรหัส User Token ได้');
        }
      }
      
      // ลบการใช้ window.location.reload() เพราะทำให้ token หายได้
      
    } catch (error) {
      addLogEntry(`❌ ล็อกอินล้มเหลว: ${error.response?.data?.message || error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // ทดสอบล็อกเอาท์
  const handleLogout = async (isAdmin) => {
    try {
      addLogEntry(`🚪 กำลังออกจากระบบ ${isAdmin ? 'แอดมิน' : 'ผู้ใช้'}...`);
      
      if (isAdmin) {
        await activityLogger.logAdminActivity('logout', window.location.pathname);
        localStorage.removeItem('admin_token');
        localStorage.removeItem('admin_name');
        localStorage.removeItem('adminTokenExpiration');
        localStorage.removeItem('adminTokenExpirationDisplay');
        setAdminToken('');
        setAdminTokenInfo(null);
      } else {
        await activityLogger.logout();
        localStorage.removeItem('token');
        localStorage.removeItem('name');
        localStorage.removeItem('tokenExpiration');
        localStorage.removeItem('tokenExpirationDisplay');
        localStorage.removeItem('currentUser');
        setUserToken('');
        setUserTokenInfo(null);
      }
      
      addLogEntry(`✅ ออกจากระบบ ${isAdmin ? 'แอดมิน' : 'ผู้ใช้'} สำเร็จ`);
    } catch (error) {
      addLogEntry(`❌ ออกจากระบบล้มเหลว: ${error.message}`);
    }
  };

  // ทดสอบเรียก API ที่ต้องใช้ Token
  const testProtectedAPI = async (isAdmin) => {
    try {
      setLoading(true);
      const endpoint = isAdmin ? '/admin/protected-test' : '/protected-data';
      
      addLogEntry(`🔒 กำลังทดสอบเรียก API ที่ป้องกัน: ${endpoint}`);
      
      const response = await axios.get(endpoint);
      
      addLogEntry(`✅ เรียก API สำเร็จ: ${JSON.stringify(response.data)}`);
    } catch (error) {
      addLogEntry(`❌ เรียก API ล้มเหลว: ${error.response?.data?.message || error.message}`);
      
      if (error.response?.status === 401) {
        MySwal.fire({
          title: 'Token ไม่ถูกต้องหรือหมดอายุ',
          text: 'คุณต้องการล็อกอินใหม่หรือไม่?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'ล็อกอินใหม่',
          cancelButtonText: 'ยกเลิก'
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(isAdmin ? '/adminlogin' : '/login');
          }
        });
      }
    } finally {
      setLoading(false);
    }
  };

  // รีเฟรช Token แบบ Manual
  const refreshTokenManually = async (isAdmin) => {
    try {
      // ตรวจสอบว่ามี token หรือไม่
      const tokenToRefresh = isAdmin ? localStorage.getItem('admin_token') : localStorage.getItem('token');
      
      if (!tokenToRefresh) {
        addLogEntry(`❌ ไม่พบ ${isAdmin ? 'Admin' : 'User'} Token ที่จะ refresh`);
        return;
      }
      
      addLogEntry(`🔄 กำลัง refresh ${isAdmin ? 'Admin' : 'User'} Token...`);
      
      // เลือก endpoint ตามประเภทของ token
      const endpoint = isAdmin ? '/refresh_token/admin' : '/refresh_token/user';
      
      const response = await axios.post(endpoint, {}, {
        headers: {
          'Authorization': `Bearer ${tokenToRefresh}`
        }
      });
      
      if (response.data.token) {
        if (isAdmin) {
          localStorage.setItem('admin_token', response.data.token);
          setAdminToken(response.data.token);
          
          try {
            const decoded = jwtDecode(response.data.token);
            setAdminTokenInfo(decoded);
            const expirationDate = new Date(decoded.exp * 1000);
            
            localStorage.setItem('adminTokenExpiration', expirationDate.toISOString());
            localStorage.setItem('adminTokenExpirationDisplay', expirationDate.toLocaleString('th-TH'));
            
            addLogEntry(`✅ Admin Token รีเฟรชสำเร็จ หมดอายุ: ${expirationDate.toLocaleString('th-TH')}`);
            addLogEntry(`ℹ️ admin_token: ${response.data.token.substring(0, 15)}...`);
          } catch (error) {
            addLogEntry('❌ ไม่สามารถถอดรหัส Admin Token ได้');
          }
        } else {
          localStorage.setItem('token', response.data.token);
          setUserToken(response.data.token);
          
          try {
            const decoded = jwtDecode(response.data.token);
            setUserTokenInfo(decoded);
            const expirationDate = new Date(decoded.exp * 1000);
            
            localStorage.setItem('tokenExpiration', expirationDate.toISOString());
            localStorage.setItem('tokenExpirationDisplay', expirationDate.toLocaleString('th-TH'));
            
            addLogEntry(`✅ User Token รีเฟรชสำเร็จ หมดอายุ: ${expirationDate.toLocaleString('th-TH')}`);
            addLogEntry(`ℹ️ token: ${response.data.token.substring(0, 15)}...`);
          } catch (error) {
            addLogEntry('❌ ไม่สามารถถอดรหัส User Token ได้');
          }
        }
      }
    } catch (error) {
      addLogEntry(`❌ รีเฟรช ${isAdmin ? 'Admin' : 'User'} Token ล้มเหลว: ${error.response?.data?.message || error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // สร้าง Token ที่ใกล้หมดอายุเพื่อทดสอบ
  const createNearExpiryToken = async (isAdmin) => {
    try {
      setLoading(true);
      // API endpoint พิเศษเพื่อทดสอบการสร้าง token ที่ใกล้หมดอายุ (30 วินาที)
      const endpoint = '/test/create-expiring-token';
      
      addLogEntry(`⏳ กำลังสร้าง ${isAdmin ? 'Admin' : 'User'} Token ที่ใกล้หมดอายุ (30 วินาที)...`);
      
      const response = await axios.post(endpoint, {
        type: isAdmin ? 'admin' : 'user',
        userId: loginData.userId || (isAdmin ? 'admin' : 'user1')
      });
      
      if (response.data.token) {
        if (isAdmin) {
          localStorage.setItem('admin_token', response.data.token);
          setAdminToken(response.data.token);
          
          try {
            const decoded = jwtDecode(response.data.token);
            setAdminTokenInfo(decoded);
            const expirationDate = new Date(decoded.exp * 1000);
                    
            localStorage.setItem('adminTokenExpiration', expirationDate.toISOString());
            localStorage.setItem('adminTokenExpirationDisplay', expirationDate.toLocaleString('th-TH'));
            
            addLogEntry(`✅ สร้าง Admin Token ที่ใกล้หมดอายุสำเร็จ: ${expirationDate.toLocaleString('th-TH')}`);
          } catch (error) {
            addLogEntry('❌ ไม่สามารถถอดรหัส Admin Token ได้');
          }
        } else {
          localStorage.setItem('token', response.data.token);
          setUserToken(response.data.token);
          
          try {
            const decoded = jwtDecode(response.data.token);
            setUserTokenInfo(decoded);
            const expirationDate = new Date(decoded.exp * 1000);
            
            localStorage.setItem('tokenExpiration', expirationDate.toISOString());
            localStorage.setItem('tokenExpirationDisplay', expirationDate.toLocaleString('th-TH'));
            
            addLogEntry(`✅ สร้าง User Token ที่ใกล้หมดอายุสำเร็จ: ${expirationDate.toLocaleString('th-TH')}`);
          } catch (error) {
            addLogEntry('❌ ไม่สามารถถอดรหัส User Token ได้');
          }
        }
        
        // รีโหลดหน้าเพื่อให้ axiosConfig ทำงานถูกต้อง
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (error) {
      addLogEntry(`❌ สร้าง Token ที่ใกล้หมดอายุล้มเหลว: ${error.response?.data?.message || error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // แปลงวินาทีเป็นรูปแบบ mm:ss
  const formatTime = (seconds) => {
    if (seconds === null || seconds === undefined) return '-';
    
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-6 text-center">ทดสอบ API Token</h1>
      
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* ส่วนล็อกอิน */}
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4">ล็อกอิน / ล็อกเอาท์</h2>
             
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium">
              <input
                type="radio"
                name="userType"
                checked={!loginData.isAdmin}
                onChange={() => setLoginData({...loginData, isAdmin: false})}
                className="mr-2"
              />
              ผู้ใช้งานทั่วไป
            </label>
            
            <label className="block mb-2 text-sm font-medium">
              <input
                type="radio"
                name="userType"
                checked={loginData.isAdmin}
                onChange={() => setLoginData({...loginData, isAdmin: true})}
                className="mr-2"
              />
              แอดมิน
            </label>
          </div>
          
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium">
              {loginData.isAdmin ? 'ชื่อผู้ใช้' : 'รหัสสมาชิก'}
            </label>
            <input
              type="text"
              className="w-full p-2 border rounded"
              value={loginData.userId}
              onChange={(e) => setLoginData({...loginData, userId: e.target.value})}
            />
          </div>
          
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium">รหัสผ่าน</label>
            <input
              type="password"
              className="w-full p-2 border rounded"
              value={loginData.password}
              onChange={(e) => setLoginData({...loginData, password: e.target.value})}
            />
          </div>
          
          <div className="flex flex-wrap gap-2 mb-4">
            <button
              onClick={handleLogin}
              disabled={loading}
              className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
            >
              {loading ? 'กำลังทำงาน...' : 'ล็อกอิน'}
            </button>
            
            <button
              onClick={() => handleLogout(false)}
              disabled={!userToken || loading}
              className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded"
            >
              ล็อกเอาท์ (ผู้ใช้)
            </button>
            
            <button
              onClick={() => handleLogout(true)}
              disabled={!adminToken || loading}
              className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded"
            >
              ล็อกเอาท์ (แอดมิน)
            </button>
              
            <button
              onClick={clearTokens}
              className="bg-gray-500 hover:bg-gray-600 text-white py-2 px-4 rounded"
            >
              ล้าง Token ทั้งหมด
            </button>
          </div>
        </div>
        
        {/* ส่วนข้อมูล Token */}
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4">ข้อมูล Token ปัจจุบัน</h2>
          
          <div className="mb-4">
            <h3 className="font-medium mb-2">User Token</h3>
            <div className="bg-gray-100 dark:bg-gray-700 p-3 rounded text-sm break-words">
              {userToken ? (
                <div>
                  <p className="mb-1"><strong>Token:</strong> {userToken.substring(0, 20)}...</p>
                  <p className="mb-1"><strong>ชื่อผู้ใช้:</strong> {userTokenInfo?.name || '-'}</p>
                  <p className="mb-1"><strong>รหัสสมาชิก:</strong> {userTokenInfo?.member_id || '-'}</p>
                  <p className="mb-1"><strong>สิทธิ์:</strong> {userTokenInfo?.access_level || '-'}</p>
                  <p className="mb-1">
                    <strong>หมดอายุ:</strong> {userTokenInfo ? new Date(userTokenInfo.exp * 1000).toLocaleString('th-TH') : '-'}
                  </p>
                  <p className="mb-1">
                    <strong>เวลาที่เหลือ:</strong> <span className={timeLeft.user < 60 ? 'text-red-500 font-bold' : ''}>{formatTime(timeLeft.user)}</span>
                  </p>
                </div>
              ) : (
                <p>ไม่มี User Token</p>
              )}
            </div>
          </div>
          
          <div className="mb-4">
            <h3 className="font-medium mb-2">Admin Token</h3>
            <div className="bg-gray-100 dark:bg-gray-700 p-3 rounded text-sm break-words">
              {adminToken ? (
                <div>
                  <p className="mb-1"><strong>Token:</strong> {adminToken.substring(0, 20)}...</p>
                  <p className="mb-1"><strong>ชื่อผู้ใช้:</strong> {adminTokenInfo?.name || '-'}</p>
                  <p className="mb-1"><strong>Admin ID:</strong> {adminTokenInfo?.admin_id || '-'}</p>
                  <p className="mb-1"><strong>สิทธิ์:</strong> {adminTokenInfo?.access_level || '-'}</p>
                  <p className="mb-1">
                    <strong>หมดอายุ:</strong> {adminTokenInfo ? new Date(adminTokenInfo.exp * 1000).toLocaleString('th-TH') : '-'}
                  </p>
                  <p className="mb-1">
                    <strong>เวลาที่เหลือ:</strong> <span className={timeLeft.admin < 60 ? 'text-red-500 font-bold' : ''}>{formatTime(timeLeft.admin)}</span>
                  </p>
                </div>
              ) : (
                <p>ไม่มี Admin Token</p>
              )}
            </div>
          </div>
        </div>
        
        {/* ส่วนทดสอบ Token */}
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4">ทดสอบ Token</h2>
          
          <div className="flex flex-wrap gap-2 mb-4">
            <button
              onClick={() => testProtectedAPI(false)}
              disabled={!userToken || loading}
              className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded"
            >
              ทดสอบ API ผู้ใช้
            </button>
            
            <button
              onClick={() => testProtectedAPI(true)}
              disabled={!adminToken || loading}
              className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded"
            >
              ทดสอบ API แอดมิน
            </button>
            
            <button
              onClick={() => refreshTokenManually(false)}
              disabled={!userToken || loading}
              className="bg-yellow-500 hover:bg-yellow-600 text-white py-2 px-4 rounded"
            >
              รีเฟรช User Token
            </button>
              
            <button
              onClick={() => refreshTokenManually(true)}
              disabled={!adminToken || loading}
              className="bg-yellow-500 hover:bg-yellow-600 text-white py-2 px-4 rounded"
            >
              รีเฟรช Admin Token
            </button>
            
            <button
              onClick={() => createNearExpiryToken(false)}
              disabled={loading}
              className="bg-purple-500 hover:bg-purple-600 text-white py-2 px-4 rounded"
            >
              สร้าง User Token ใกล้หมดอายุ
            </button>
            
            <button
              onClick={() => createNearExpiryToken(true)}
              disabled={loading}
              className="bg-purple-500 hover:bg-purple-600 text-white py-2 px-4 rounded"
            >
              สร้าง Admin Token ใกล้หมดอายุ
            </button>
          </div>
          
          <p className="text-sm text-gray-600 dark:text-gray-400 mb-1">
            หมายเหตุ: การสร้าง Token ใกล้หมดอายุจะช่วยให้คุณทดสอบการต่ออายุอัตโนมัติ
          </p>
        </div>
        
        {/* ส่วนแสดงประวัติการทดสอบ */}
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4">ประวัติการทดสอบ</h2>
          
          <div className="bg-gray-100 dark:bg-gray-700 p-3 rounded h-80 overflow-y-auto text-sm">
            {testOutput.length > 0 ? (
              testOutput.map((log, index) => (
                <div key={index} className="mb-1 border-b pb-1 border-gray-200 dark:border-gray-600">
                  {log}
                </div>
              ))
            ) : (
              <p className="text-gray-500 dark:text-gray-400">ยังไม่มีประวัติการทดสอบ</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenTestPage;