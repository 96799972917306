import React, { useState, useEffect, useRef } from 'react';

const departments = [
  { id: "acc", label: "Accounting", color: "bg-blue-100 text-blue-800" },
  { id: "fin", label: "Finance", color: "bg-green-100 text-green-800" },
  { id: "wh", label: "Warehouse", color: "bg-yellow-100 text-yellow-800" },
  { id: "hr", label: "Human Resources", color: "bg-purple-100 text-purple-800" },
  { id: "it", label: "Information Technology", color: "bg-red-100 text-red-800" }
];

const EditAccessLevelModal = ({ isOpen, onClose, onSubmit, item, isAdmin }) => {
  const [accessLevels, setAccessLevels] = useState([]);
  const modalRef = useRef(null);
  
  useEffect(() => {
    if (isOpen && item) {
      // กำหนดค่า accessLevels จาก item
      if (item.access_level === 'all') {
        setAccessLevels([]);
      } else {
        setAccessLevels(item.access_level?.split(',') || []);
      }
    }
  }, [isOpen, item]);
  
  // เพิ่ม event listener สำหรับการคลิกนอก modal
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target) && isOpen) {
        onClose();
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  const handleAccessLevelChange = (deptId) => {
    setAccessLevels(prev => {
      if (prev.includes(deptId)) {
        // ลบออก
        return prev.filter(id => id !== deptId);
      } else {
        // เพิ่มเข้าไป
        return [...prev, deptId];
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const effectiveAccessLevel = accessLevels.length > 0 
      ? accessLevels.join(',') 
      : 'all';
    
    onSubmit(item.id, effectiveAccessLevel, item.isFolder);
  };

  // ถ้า modal ไม่เปิด ไม่ต้องแสดงอะไร
  if (!isOpen) return null;
  
  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex justify-center items-center">
      <div 
        ref={modalRef}
        className="bg-white rounded-lg shadow-xl p-6 w-full max-w-lg mx-4 my-8 animate-fadeIn"
      >
        <h2 className="text-2xl font-bold mb-2">แก้ไขสิทธิ์การเข้าถึง</h2>
        <p className="mb-4 text-gray-600">
          {item?.isFolder ? "โฟลเดอร์" : "ไฟล์"}: <strong>{item?.name}</strong>
        </p>
        
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">แผนกที่มีสิทธิ์เข้าถึง</label>
            <div className="space-y-2 max-h-48 overflow-y-auto p-2 border rounded">
              {isAdmin && (
                <div className="flex items-center mb-2 p-1 hover:bg-gray-100 rounded">
                  <input
                    type="checkbox"
                    id="access-all"
                    checked={accessLevels.length === 0}
                    onChange={() => setAccessLevels([])}
                    className="mr-2 h-4 w-4 text-blue-600 focus:ring-blue-500"
                  />
                  <label htmlFor="access-all" className="select-none cursor-pointer flex-1">
                    ทุกแผนก (All)
                  </label>
                  <span className="px-2 py-0.5 text-xs rounded bg-gray-100 text-gray-800">
                    ทุกแผนก
                  </span>
                </div>
              )}
              
              {departments.map(dept => (
                <div key={dept.id} className="flex items-center p-1 hover:bg-gray-100 rounded">
                  <input
                    type="checkbox"
                    id={`edit-access-${dept.id}`}
                    checked={accessLevels.includes(dept.id)}
                    onChange={() => handleAccessLevelChange(dept.id)}
                    className="mr-2 h-4 w-4 text-blue-600 focus:ring-blue-500"
                  />
                  <label 
                    htmlFor={`edit-access-${dept.id}`} 
                    className="select-none cursor-pointer flex-1"
                  >
                    {dept.label}
                  </label>
                  <span className={`px-2 py-0.5 text-xs rounded ${dept.color}`}>
                    {dept.id}
                  </span>
                </div>
              ))}
            </div>
          </div>
          
          {/* แสดงตัวอย่างที่เลือก */}
          {accessLevels.length > 0 ? (
            <div className="mt-3 mb-4">
              <p className="text-sm text-gray-600 mb-1">แผนกที่จะมีสิทธิ์เข้าถึง:</p>
              <div className="flex flex-wrap gap-1">
                {accessLevels.map(level => {
                  const dept = departments.find(d => d.id === level);
                  return dept ? (
                    <span key={level} className={`px-2 py-0.5 text-xs rounded ${dept.color}`}>
                      {dept.label}
                    </span>
                  ) : null;
                })}
              </div>
            </div>
          ) : (
            <p className="text-sm text-gray-600 mb-4">
              ทุกแผนกจะมีสิทธิ์เข้าถึง
            </p>
          )}
          
          <div className="flex justify-end space-x-2 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400 transition duration-200"
            >
              ยกเลิก
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-200"
            >
              บันทึก
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditAccessLevelModal;