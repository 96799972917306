import React, { useState, useEffect, useCallback } from 'react';
import { Card } from "../ui/card";
import { Plus, Search, Edit2, Trash2, ArrowLeft, MoveVertical, Calendar } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { format, isValid } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// ตรวจสอบว่าติดตั้ง @hello-pangea/dnd แล้วหรือยัง
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

const MySwal = withReactContent(Swal);

const EventManagement = () => {
  const [events, setEvents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [editingEvent, setEditingEvent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isOrderChanged, setIsOrderChanged] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    startDate: new Date(),
    endDate: '',
    description: '',
    color: '#3b82f6',
    allDay: false
  });
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  // แก้ไขฟังก์ชัน fetchEvents
  const fetchEvents = useCallback(async () => {
    try {
      setLoading(true);
      // เพิ่ม timestamp เพื่อป้องกัน cache
      const response = await axios.get(`/events?t=${new Date().getTime()}`);
      
      // เพิ่มการตรวจสอบข้อมูลวันที่
      const validatedEvents = response.data.map(event => ({
        ...event,
        // แปลง null/undefined เป็นค่าว่างเพื่อป้องกัน format error
        start: event.start || null,
        end: event.end || null
      }));
      
      // ไม่ต้อง sort อีก เพราะ backend จะ sort ให้แล้ว
      setEvents(validatedEvents);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching events:', err);
      setError('ไม่สามารถโหลดข้อมูลกิจกรรมได้');
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  const resetForm = () => {
    setEditingEvent(null);
    setFormData({
      title: '',
      startDate: new Date(),
      endDate: '',
      description: '',
      color: '#3b82f6',
      allDay: false
    });
  };

  // แก้ไขฟังก์ชัน handleSubmit เพิ่มการตรวจสอบวันที่
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!formData.title.trim()) {
      MySwal.fire({
        title: 'ข้อมูลไม่ครบถ้วน',
        text: 'กรุณากรอกชื่อกิจกรรม',
        icon: 'warning',
        confirmButtonText: 'ตกลง'
      });
      return;
    }
    
    try {
      setSubmitting(true);
      
      let eventData = {
        title: formData.title,
        startDate: formData.startDate ? format(formData.startDate, 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd'),
        endDate: formData.allDay ? null : 
                formData.endDate ? format(formData.endDate, 'yyyy-MM-dd') : null,
        description: formData.description,
        color: formData.color,
        allDay: formData.allDay
      };

      if (editingEvent) {
        await axios.put(`/events/${editingEvent.id}`, eventData);
        
        // เพิ่ม action ที่ทำหลังการแก้ไข เช่น การโหลดข้อมูลใหม่
        fetchEvents(); 
      } else {
        await axios.post('/events', eventData);
        
        // เพิ่ม action ที่ทำหลังการเพิ่ม เช่น การโหลดข้อมูลใหม่
        fetchEvents();
      }

      setIsFormVisible(false);
      setFormData({
        title: '',
        startDate: new Date(),
        endDate: null,
        description: '',
        color: '#3b82f6',
        allDay: false
      });
      setEditingEvent(null);

      MySwal.fire({
        title: 'สำเร็จ',
        text: editingEvent ? 'แก้ไขกิจกรรมเรียบร้อยแล้ว' : 'เพิ่มกิจกรรมเรียบร้อยแล้ว',
        icon: 'success',
        confirmButtonText: 'ตกลง'
      });
      
    } catch (error) {
      console.error('Error saving event:', error);
      
      MySwal.fire({
        title: 'เกิดข้อผิดพลาด',
        text: 'ไม่สามารถบันทึกข้อมูลได้',
        icon: 'error',
        confirmButtonText: 'ตกลง'
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleDelete = async (id) => {
    MySwal.fire({
      title: 'คุณแน่ใจหรือไม่?',
      text: 'คุณจะไม่สามารถย้อนกลับได้!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ใช่, ลบเลย!',
      cancelButtonText: 'ยกเลิก'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.delete(`/events/${id}`);
          if (response.status === 200) {
            fetchEvents();
            MySwal.fire({
              title: 'ลบแล้ว!',
              text: 'กิจกรรมถูกลบเรียบร้อยแล้ว',
              icon: 'success',
              confirmButtonText: 'ตกลง',
              confirmButtonColor: '#3085d6'
            });
          }
        } catch (error) {
          MySwal.fire('เกิดข้อผิดพลาด!', 'ไม่สามารถลบกิจกรรมได้', 'error');
        }
      }
    });
  };

  // แก้ไขฟังก์ชัน handleEdit เพื่อตรวจสอบความถูกต้องของวันที่
  const handleEdit = (event) => {
    setEditingEvent(event);
    
    // Convert dates
    const startDate = event.start ? new Date(event.start) : new Date();
    let endDate = null;
    
    if (event.end) {
      endDate = new Date(event.end);
      // ตรวจสอบว่า endDate มากกว่า startDate จริงๆ
      if (endDate <= startDate) {
        // กรณีข้อมูลในฐานข้อมูลไม่ถูกต้อง ไม่ควรใช้ endDate นั้น
        console.warn("End date is not greater than start date, resetting end date");
        endDate = null;
      }
    }

    setFormData({
      title: event.title || '',
      startDate: startDate,
      endDate: endDate,
      description: event.description || '',
      color: event.color || event.backgroundColor || '#3b82f6',
      allDay: event.allDay === undefined ? false : event.allDay
    });
    
    setIsFormVisible(true);
  };
  
  // Handle drag end
  const handleDragEnd = (result) => {
    if (!result.destination) return;
    
    const items = Array.from(events);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    
    // ค่า order_position น้อย = แสดงก่อน (บนสุด), ค่ามาก = แสดงหลัง (ล่างสุด)
    const updatedItems = items.map((item, index) => {
      return {
        ...item,
        order_position: index + 1 // เริ่มจาก 1, 2, 3, ... (บรรทัดบนสุดมีค่าน้อยสุด)
      };
    });
    
    setEvents(updatedItems);
    setIsOrderChanged(true);
    
    console.log('After drag order:', updatedItems.map(i => ({ id: i.id, pos: i.order_position })));
  };

  // แก้ไขฟังก์ชัน handleSaveOrder
  const handleSaveOrder = async () => {
    try {
      // ค่า order_position น้อย = อยู่บนสุด, ค่ามาก = อยู่ล่างสุด
      const orderData = events.map((item, index) => {
        const id = typeof item.id === 'string' ? parseInt(item.id, 10) : item.id;
        return {
          id: id,
          order_position: index + 1 // เริ่มจาก 1, 2, 3, ... (ค่าน้อยอยู่ด้านบน)
        };
      });
      
      console.log('Sending order data to API:', orderData);

      const response = await axios({
        method: 'post',
        url: '/events/reorder',
        data: {
          eventOrders: orderData
        },
        headers: {
          'Content-Type': 'application/json'
        }
      });

      console.log('API response:', response.data);

      if (response.data.success) {
        setIsOrderChanged(false);
        await fetchEvents();
        
        MySwal.fire({
          title: 'สำเร็จ!',
          text: 'บันทึกลำดับกิจกรรมเรียบร้อยแล้ว',
          icon: 'success',
          confirmButtonText: 'ตกลง',
          confirmButtonColor: '#3085d6'
        });
      } else {
        throw new Error(response.data.message || 'บันทึกลำดับไม่สำเร็จ');
      }
    } catch (error) {
      console.error('Error saving order:', error);
      MySwal.fire('เกิดข้อผิดพลาด!', 'ไม่สามารถบันทึกลำดับกิจกรรมได้', 'error');
    }
  };

  // Filter events by search term
  const filteredEvents = events.filter(event => 
    event.title && event.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="p-8 bg-gray-50 min-h-screen">
      {/* Back button */}
      <div className="flex justify-end items-center">
        <button
          onClick={() => navigate('/admin/dashboard/data_management')}
          className="flex gap-2 bg-none text-gray-600 px-4 py-2 mb-5 rounded-md hover:text-gray-800 transition-colors"
        >
          <ArrowLeft className="h-5 w-5" />
          ย้อนกลับ
        </button>
      </div>

      {/* Header */}
      <div className="mb-8 flex justify-center items-center">
        <div>
          <h1 className="text-3xl font-bold text-gray-800">จัดการปฏิทินกิจกรรม</h1>
          <p className="text-gray-600 mt-2">เพิ่ม แก้ไข และลบกิจกรรมในปฏิทิน</p>
        </div>
      </div>

      {/* Search and Add */}
      <Card className="p-6 mb-6">
        <div className="flex justify-between items-center mb-4">
          <div className="relative w-64">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
            <input
              type="text"
              placeholder="ค้นหากิจกรรม..."
              className="pl-10 pr-4 py-2 w-full border rounded-md focus:ring-gray-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="flex gap-2">
            {isOrderChanged && (
              <button
                onClick={handleSaveOrder}
                className="flex items-center gap-1 bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700 transition-colors"
              >
                บันทึกลำดับ
              </button>
            )}
            <button
              onClick={() => {
                resetForm();
                setIsFormVisible(true);
              }}
              className="flex items-center gap-1 sm:gap-2 bg-blue-600 text-white px-4 py-3 sm:px-4 sm:py-2 text-xs sm:text-base rounded-md hover:bg-blue-700 transition-colors"
            >
              <Plus className="h-3 w-3 sm:h-5 sm:w-5" />
              <span className="hidden sm:inline">เพิ่มกิจกรรม</span>
              <span className="sm:hidden">เพิ่ม</span>
            </button>
          </div>
        </div>

        {/* Form */}
        {isFormVisible && (
          <form onSubmit={handleSubmit} className="mb-6 border p-4 rounded-md text-start">
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">ชื่อกิจกรรม</label>
              <input
                type="text"
                className="w-full border rounded-md px-3 py-2"
                placeholder="ระบุชื่อกิจกรรม..."
                value={formData.title}
                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                required
              />
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">วันที่เริ่มต้น</label>
                <DatePicker
                  selected={formData.startDate instanceof Date ? formData.startDate : null}
                  onChange={(date) => setFormData({ ...formData, startDate: date })}
                  dateFormat="dd/MM/yyyy"
                  className="w-full border rounded-md px-3 py-2"
                  placeholderText="เลือกวันที่เริ่มต้น..."
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">วันที่สิ้นสุด (ถ้ามี)</label>
                <DatePicker
                  selected={formData.endDate instanceof Date ? formData.endDate : null}
                  onChange={(date) => setFormData({ ...formData, endDate: date })}
                  dateFormat="dd/MM/yyyy"
                  className="w-full border rounded-md px-3 py-2"
                  placeholderText="เลือกวันที่สิ้นสุด..."
                  disabled={formData.allDay}
                />
                {!formData.allDay && !formData.endDate && (
                  <p className="text-xs text-red-500 mt-1">
                    กรุณาเลือกวันที่สิ้นสุด หรือเลือกตัวเลือก "ทั้งวัน"
                  </p>
                )}
              </div>
            </div>
        
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">สี</label>
                <input
                  type="color"
                  className="w-10 h-10 border rounded-md px-1 py-1"
                  value={formData.color}
                  onChange={(e) => setFormData({ ...formData, color: e.target.value })}
                />
              </div>
              <div className="flex items-center mt-7">
                <input
                  type="checkbox"
                  id="allDay"
                  checked={formData.allDay}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFormData({ 
                        ...formData, 
                        allDay: e.target.checked,
                        endDate: null
                      });
                    } else {
                      setFormData({ 
                        ...formData, 
                        allDay: e.target.checked 
                      });
                    }
                  }}
                  className="mr-4 h-6 w-6"
                />
                <label htmlFor="allDay" className="text-sm font-medium text-gray-700">
                  ทั้งวัน (ไม่ระบุวันสิ้นสุด)
                </label>
              </div>
            </div>
            
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">รายละเอียด (ถ้ามี)</label>
              <textarea
                className="w-full border rounded-md px-3 py-2"
                placeholder="รายละเอียดกิจกรรม..."
                rows="3"
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              />
            </div>
            
            <div className="flex gap-4">
              {/* ปุ่มบันทึกจะแสดงเฉพาะเมื่อมีวันสิ้นสุดหรือเลือก allDay */}
              {(formData.allDay || (formData.endDate && formData.endDate instanceof Date)) ? (
                <button
                  type="submit"
                  className="bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700 transition-colors"
                >
                  บันทึก
                </button>
              ) : (
                <button
                  type="button"
                  className="bg-gray-400 text-white px-4 py-2 rounded-md cursor-not-allowed"
                  disabled
                  title="กรุณาเลือกวันที่สิ้นสุด หรือเลือกตัวเลือก 'ทั้งวัน'"
                >
                  บันทึก
                </button>
              )}
              <button
                type="button"
                onClick={() => {
                  setIsFormVisible(false);
                  resetForm();
                }}
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-400 transition-colors"
              >
                ยกเลิก
              </button>
            </div>
          </form>
        )}
      </Card>

      {/* Event Table */}
      <Card className="p-3 sm:p-6 overflow-hidden">
  {loading ? (
    <div className="px-6 py-8 text-center">
      <div className="inline-block animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      <p className="mt-2 text-gray-500">กำลังโหลดข้อมูล...</p>
    </div>
  ) : error ? (
    <div className="px-6 py-8 text-center text-red-500">{error}</div>
  ) : filteredEvents.length === 0 ? (
    <div className="px-6 py-8 text-center text-gray-500">ไม่มีกิจกรรมในระบบ</div>
  ) : (
    <>
      {/* Desktop View */}
      <div className="hidden sm:block overflow-x-auto">
        <div className="min-w-full">
          {/* Table Headers */}
          <div className="flex bg-gray-50 border-b">
            <div className="w-[10%] px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">ลำดับ</div>
            <div className="w-[20%] px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">วันที่</div>
            <div className="w-[40%] px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">ชื่อกิจกรรม</div>
            <div className="w-[10%] px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">สี</div>
            <div className="w-[20%] px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">จัดการ</div>
          </div>

          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="events-list" type="EVENT">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="bg-white"
                >
                  {filteredEvents.map((event, index) => (
                    <Draggable 
                      key={event.id.toString()} 
                      draggableId={event.id.toString()} 
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`flex items-center border-b hover:bg-gray-50 min-h-[80px] ${
                            snapshot.isDragging ? 'bg-gray-100' : ''
                          }`}
                        >
                          {/* Order */}
                          <div className="w-[10%] px-3 py-4 whitespace-nowrap text-center">
                            <div className="flex justify-center cursor-move">
                              <MoveVertical className="h-5 w-5 text-gray-500" />
                            </div>
                          </div>

                          {/* Date */}
                          <div className="w-[20%] px-6 py-4 flex justify-center">
                            <div className="flex flex-col items-center">
                              <span className="font-medium">
                                {event.start && isValid(new Date(event.start)) 
                                  ? format(new Date(event.start), 'd MMM yyyy') 
                                  : 'ไม่ระบุวันที่'}
                              </span>
                              {event.end && !event.allDay && (
                                <span className="text-xs text-gray-500 mt-1">
                                  ถึง {isValid(new Date(event.end)) 
                                    ? format(new Date(event.end), 'd MMM yyyy')
                                    : ''}
                                </span>
                              )}
                            </div>
                          </div>

                          {/* Title */}
                          <div className="w-[40%] px-6 py-4">
                            <div className="flex items-center">
                              <Calendar className="h-5 w-5 mr-2 text-gray-500 flex-shrink-0" />
                              <span className="font-medium">{event.title || 'ไม่มีชื่อ'}</span>
                            </div>
                            {event.description && (
                              <div className="mt-1 pl-7 text-sm text-gray-500 line-clamp-1">
                                {event.description}
                              </div>
                            )}
                          </div>

                          {/* Color */}
                          <div className="w-[10%] px-3 py-4 flex justify-center">
                            <div 
                              className="w-6 h-6 rounded-full flex-shrink-0" 
                              style={{ backgroundColor: event.backgroundColor || event.color || '#3b82f6' }}
                            ></div>
                          </div>

                          {/* Actions */}
                          <div className="w-[20%] px-6 py-4 flex justify-center">
                            <div className="flex space-x-3">
                              <button
                                onClick={() => handleEdit(event)}
                                className="text-blue-600 hover:text-blue-900 p-1 rounded-full hover:bg-blue-50"
                              >
                                <Edit2 className="h-5 w-5" />
                              </button>
                              <button
                                onClick={() => handleDelete(event.id)}
                                className="text-red-600 hover:text-red-900 p-1 rounded-full hover:bg-red-50"
                              >
                                <Trash2 className="h-5 w-5" />
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
      
      {/* Mobile View */}
      <div className="sm:hidden">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="mobile-events-list" type="MOBILE_EVENT">
            {(provided) => (
              <div 
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="space-y-3"
              >
                {filteredEvents.map((event, index) => (
                  <Draggable 
                    key={`mobile-${event.id.toString()}`} 
                    draggableId={`mobile-${event.id.toString()}`} 
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={`border rounded-lg overflow-hidden ${snapshot.isDragging ? 'bg-gray-100' : 'bg-white'}`}
                      >
                        <div className="p-3 bg-gray-50 border-b flex justify-between items-center">
                          <div className="flex items-center">
                            <div 
                              className="w-4 h-4 rounded-full mr-2" 
                              style={{ backgroundColor: event.backgroundColor || event.color || '#3b82f6' }}
                            ></div>
                            <div className="text-xs font-medium text-gray-600">
                              {event.start && isValid(new Date(event.start)) 
                                ? format(new Date(event.start), 'd MMM yyyy') 
                                : 'ไม่ระบุวันที่'}
                            </div>
                          </div>
                          <div className="flex items-center">
                            {event.allDay ? (
                              <span className="text-xs bg-blue-100 text-blue-800 rounded-full px-2 py-0.5 mr-2">
                                ทั้งวัน
                              </span>
                            ) : event.end ? (
                              <span className="text-xs bg-blue-100 text-blue-800 rounded-full px-2 py-0.5 mr-2">
                                ถึง {isValid(new Date(event.end)) ? format(new Date(event.end), 'd/M/yy') : ''}
                              </span>
                            ) : null}
                            <div {...provided.dragHandleProps} className="cursor-move">
                              <MoveVertical className="h-4 w-4 text-gray-500" />
                            </div>
                          </div>
                        </div>
                        <div className="p-3">
                          <div className="flex items-center mb-2">
                            <Calendar className="h-4 w-4 text-gray-500 mr-2 flex-shrink-0" />
                            <h3 className="font-medium text-gray-800">{event.title || 'ไม่มีชื่อ'}</h3>
                          </div>
                          {event.description && (
                            <p className="text-xs text-gray-600 mb-2 ml-6 line-clamp-2">
                              {event.description}
                            </p>
                          )}
                        </div>
                        <div className="p-3 bg-gray-50 border-t flex justify-end space-x-2">
                          <button
                            onClick={() => handleEdit(event)}
                            className="flex items-center gap-1 bg-blue-50 text-blue-600 hover:bg-blue-100 px-3 py-1 rounded-md text-xs"
                          >
                            <Edit2 className="h-3 w-3" />
                            แก้ไข
                          </button>
                          <button
                            onClick={() => handleDelete(event.id)}
                            className="flex items-center gap-1 bg-red-50 text-red-600 hover:bg-red-100 px-3 py-1 rounded-md text-xs"
                          >
                            <Trash2 className="h-3 w-3" />
                            ลบ
                          </button>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  )}
</Card>
    </div>
  );
};

export default EventManagement;