import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const AdminAuthContext = createContext();

export const AdminAuthProvider = ({ children }) => {
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [hasNavigated, setHasNavigated] = useState(false);

  useEffect(() => {
    const adminToken = localStorage.getItem("admin_token");
    const adminTokenExpiration = localStorage.getItem("adminTokenExpiration");

    // paths สำหรับ admin
    const adminPublicPaths = ['/adminlogin'];
    const isAdminPath = location.pathname.startsWith('/admin') || adminPublicPaths.includes(location.pathname);

    if (!adminToken) {
      setIsAdminAuthenticated(false);
      if (isAdminPath && !hasNavigated) {
        setHasNavigated(true);
        navigate('/adminlogin');
      }
    } else {
      if (adminTokenExpiration) {
        try {
          // แปลงวันที่จากรูปแบบไทยกลับเป็น Date object
          const expDate = new Date(adminTokenExpiration);
          if (isNaN(expDate.getTime()) || expDate < new Date()) {
            // เมื่อ token หมดอายุให้ลบข้อมูลออกจาก localStorage
            localStorage.removeItem("admin_token");
            localStorage.removeItem("adminTokenExpiration");
            localStorage.removeItem("adminTokenExpirationDisplay"); // เพิ่มบรรทัดนี้
            localStorage.removeItem("admin_name");
            localStorage.removeItem("admin_access_level");
            
            setIsAdminAuthenticated(false);
            if (isAdminPath && !hasNavigated) {
              setHasNavigated(true);
              navigate('/adminlogin');
            }
          } else {
            setIsAdminAuthenticated(true);
          }
        } catch (e) {
          console.error("Error parsing date:", e);
          localStorage.removeItem("admin_token");
          localStorage.removeItem("adminTokenExpiration");
          localStorage.removeItem("adminTokenExpirationDisplay"); // เพิ่มบรรทัดนี้
          localStorage.removeItem("admin_name");
          localStorage.removeItem("admin_access_level");
          setIsAdminAuthenticated(false);
        }
      } else {
        // ไม่มี adminTokenExpiration
        setIsAdminAuthenticated(!!localStorage.getItem("admin_token"));
      }
    }
  }, [navigate, location, hasNavigated]);

  return (
    <AdminAuthContext.Provider value={{ isAdminAuthenticated }}>
      {children}
    </AdminAuthContext.Provider>
  );
};

export const useAdminAuth = () => useContext(AdminAuthContext);