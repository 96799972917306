// ไฟล์นี้สำหรับจัดการการทำงานกับข้อมูล และ state management ทั้งหมด
// สร้าง, ลบ, กู้คืน, ดาวน์โหลด, อัปโหลด
// ค้นหา, กรองข้อมูล
// จัดการ state ของแอปพลิเคชัน

import React, { createContext, useState, useContext, useEffect, useCallback, useRef } from 'react';
import { callApi, showError, showSuccess, showLoading, confirmDelete, showDialog } from './FileUtils';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

// สร้าง Context สำหรับข้อมูลไฟล์และโฟลเดอร์
const FileDataContext = createContext(null);

// Provider component
export const FileDataProvider = ({ children, navigate }) => {
  // ข้อมูลหลัก
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState([]);
  const [folders, setFolders] = useState([]);
  const [currentFolderId, setCurrentFolderId] = useState(null);
  const [folderPath, setFolderPath] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [viewMode, setViewMode] = useState("list");

  // ข้อมูลถังขยะ
  const [showDeleted, setShowDeleted] = useState(false);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [deletedFolders, setDeletedFolders] = useState([]);
  
  // ใช้ useRef เพื่อเก็บค่า state ระหว่างการ render
  // useRef จะไม่ trigger re-render เมื่อค่าเปลี่ยน ทำให้ไม่เกิด infinite loop
  const navigationStateRef = useRef({
    normalMode: {
      folderId: null,
      path: []
    },
    trashMode: {
      active: false
    }
  });

  // ดึงข้อมูลโฟลเดอร์
  const fetchFolders = useCallback(async (parentId = null) => {
    try {
      setLoading(true);
      const url = parentId 
        ? `/folders/${parentId}/subfolders`
        : '/folders';
      
      // ตรวจสอบว่าเป็น admin หรือไม่
      const isAdmin = localStorage.getItem('admin_token') !== null;
      
      if (isAdmin) {
        // ถ้าเป็น admin ใช้ admin token
        const token = localStorage.getItem('admin_token');
        const response = await axios.get(url, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setFolders(response.data.folders || []);
      } else {
        // ถ้าเป็นผู้ใช้ทั่วไปใช้ callApi ตามเดิม
        const data = await callApi('get', url, null, navigate);
        setFolders(data.folders || []);
      }
      
      setLoading(false);
    } catch (error) {
      console.error("Error fetching folders:", error);
      showError(`ไม่สามารถโหลดข้อมูลโฟลเดอร์ได้`, error);
      setLoading(false);
    }
  }, [navigate]);

  // ดึงข้อมูลไฟล์
  const fetchFiles = useCallback(async (folderId = null) => {
    if (!folderId) {
      setFiles([]);
      return;
    }
    
    try {
      setLoading(true);
      const data = await callApi('get', `/folders/${folderId}/files`, null, navigate);
      setFiles(data.files || []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching files:", error);
      showError("ไม่สามารถโหลดข้อมูลไฟล์ได้", error);
      setLoading(false);
    }
  }, [navigate]);

  // ดึงไฟล์ที่ถูกลบ
  const fetchDeletedFiles = useCallback(async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('admin_token');
      if (!token) {
        throw new Error('ไม่พบ token');
      }
      
      console.log("Fetching deleted files with token:", token.substring(0, 10) + "...");
      
      // ใช้ axios ในการส่ง token ที่ถูกต้อง
      const response = await axios.get('/files/deleted', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      setDeletedFiles(response.data || []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching deleted files:", error);
      showError("ไม่สามารถโหลดข้อมูลไฟล์ที่ถูกลบได้", error);
      setLoading(false);
    }
  }, []);

  // ดึงโฟลเดอร์ที่ถูกลบ
  const fetchDeletedFolders = useCallback(async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('admin_token');
      if (!token) {
        throw new Error('ไม่พบ token');
      }
      
      console.log("Fetching deleted folders with token:", token.substring(0, 10) + "...");
      
      // ใช้ axios แทน callApi เพื่อส่ง token อย่างชัดเจน
      const response = await axios.get('/folders/deleted', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      setDeletedFolders(response.data.folders || []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching deleted folders:", error);
      showError("ไม่สามารถโหลดข้อมูลโฟลเดอร์ที่ถูกลบได้", error);
      setLoading(false);
    }
  }, []);

  // เปิดโฟลเดอร์
  const handleFolderClick = useCallback((folder) => {
    // ตรวจสอบว่าอยู่ในโหมดถังขยะหรือไม่ และโฟลเดอร์ถูกลบหรือไม่
    if (showDeleted || folder.is_deleted) {
      showError("ไม่สามารถเปิดโฟลเดอร์ได้", { 
        response: { data: { message: "โฟลเดอร์นี้อยู่ในถังขยะ ไม่สามารถเปิดดูเนื้อหาได้ กรุณากู้คืนโฟลเดอร์ก่อน" }}
      });
      return;
    }

    // ถ้าเป็นโฟลเดอร์ปกติ ดำเนินการตามเดิม
    const newPath = [...folderPath, folder];
    
    // บันทึกสถานะการนำทางปัจจุบัน
    navigationStateRef.current.normalMode = {
      folderId: folder.id,
      path: newPath
    };

    setCurrentFolderId(folder.id);
    setFolderPath(newPath);
    fetchFolders(folder.id);
    fetchFiles(folder.id);
  }, [showDeleted, folderPath, fetchFolders, fetchFiles]);

  // ย้อนกลับไปโฟลเดอร์ก่อนหน้า
  const handleBack = useCallback(() => {
    if (folderPath.length === 0) return;
    
    const newPath = folderPath.slice(0, -1);
    
    if (newPath.length === 0) {
      // กลับไปที่หน้าหลัก (root)
      navigationStateRef.current.normalMode = {
        folderId: null,
        path: []
      };
      
      setCurrentFolderId(null);
      setFolderPath([]);
      fetchFolders(null);
      setFiles([]);
    } else {
      // กลับไปที่โฟลเดอร์ก่อนหน้า
      const parentFolder = newPath[newPath.length - 1];
      
      navigationStateRef.current.normalMode = {
        folderId: parentFolder.id,
        path: newPath
      };
      
      setCurrentFolderId(parentFolder.id);
      setFolderPath(newPath);
      fetchFolders(parentFolder.id);
      fetchFiles(parentFolder.id);
    }
  }, [folderPath, fetchFolders, fetchFiles]);

  // แก้ไขฟังก์ชัน handleCreateFolder เพื่อใช้ axios โดยตรงแทน callApi
const handleCreateFolder = useCallback(async (folderName, accessLevel = 'all') => {
  if (!folderName) return;
  
  try {
    console.log(`กำลังสร้างโฟลเดอร์ '${folderName}' ใน parent_id:`, currentFolderId);
    console.log('access_level:', accessLevel);
    
    // ใช้ loading dialog ระหว่างรอการสร้าง
    const loadingDialog = showLoading("กำลังสร้างโฟลเดอร์...");
    
    // ดึง admin token โดยตรง
    const token = localStorage.getItem('admin_token');
    
    // ใช้ axios โดยตรงแทน callApi
    // eslint-disable-next-line no-unused-vars
    await axios.post("/folders", {
      name: folderName,
      parent_id: currentFolderId,
      access_level: accessLevel
    }, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    
    loadingDialog.close();
    showSuccess("สร้างโฟลเดอร์สำเร็จ");
    fetchFolders(currentFolderId);
  } catch (error) {
    console.error("Error creating folder:", error);
    
    // แสดงข้อความ error ที่ละเอียดมากขึ้น
    let errorMessage = "ไม่สามารถสร้างโฟลเดอร์ได้";
    if (error.response) {
      console.log("Error response data:", error.response.data);
      errorMessage = error.response.data.message || errorMessage;
    }
    
    showError(errorMessage, error);
  }
}, [currentFolderId, fetchFolders]);

  // ลบไฟล์
  const handleDeleteFile = useCallback(async (fileId) => {
    const result = await confirmDelete("ยืนยันการลบไฟล์", "คุณแน่ใจหรือว่าต้องการลบไฟล์นี้? ไฟล์จะถูกย้ายไปถังขยะ");

    if (result.isConfirmed) {
      try {
        await callApi('delete', `/files/${fileId}`, null, navigate);
        showSuccess("ลบไฟล์สำเร็จ");
        fetchFiles(currentFolderId);
      } catch (error) {
        console.error("Error deleting file:", error);
        showError("ไม่สามารถลบไฟล์ได้", error);
      }
    }
  }, [currentFolderId, fetchFiles, navigate]);

  // กู้คืนไฟล์
  const handleRestoreFile = useCallback(async (fileId) => {
    try {
      await callApi('post', `/files/${fileId}/restore`, null, navigate);
      showSuccess("กู้คืนไฟล์สำเร็จ");
      
      // รีเฟรชข้อมูล
      fetchDeletedFiles();
    } catch (error) {
      console.error("Error restoring file:", error);
      showError("ไม่สามารถกู้คืนไฟล์ได้", error);
    }
  }, [fetchDeletedFiles, navigate]);

  // ลบไฟล์ถาวร - แบบใหม่ เรียบง่ายกว่า เพียงส่ง request และคืนค่า Promise
  const handlePermanentDeleteFile = useCallback(async (fileId) => {
    try {
      const token = localStorage.getItem('admin_token');
      await axios.delete(`/files/${fileId}/permanent`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      
      // รีเฟรชข้อมูลไฟล์ในถังขยะ
      fetchDeletedFiles();
      return true;
    } catch (error) {
      console.error("Error permanently deleting file:", error);
      throw error;
    }
  }, [fetchDeletedFiles]);

  // ลบโฟลเดอร์
  const handleDeleteFolder = useCallback(async (folderId) => {
    const result = await confirmDelete("ยืนยันการลบโฟลเดอร์", "โฟลเดอร์และไฟล์ทั้งหมดจะถูกย้ายไปยังถังขยะ");

    if (result.isConfirmed) {
      try {
        await callApi('delete', `/folders/${folderId}`, null, navigate);
        showSuccess("ลบโฟลเดอร์สำเร็จ");
        
        fetchFolders(currentFolderId);
        if (showDeleted) {
          fetchDeletedFolders();
          fetchDeletedFiles();
        }
      } catch (error) {
        console.error("Error deleting folder:", error);
        showError("ไม่สามารถลบโฟลเดอร์ได้", error);
      }
    }
  }, [currentFolderId, fetchFolders, showDeleted, fetchDeletedFolders, fetchDeletedFiles, navigate]);

  // กู้คืนโฟลเดอร์
  const handleRestoreFolder = useCallback(async (folderId) => {
    try {
      // ใช้ showDialog แทน confirmDelete เพื่อใช้ปุ่ม "กู้คืน" แทน "ลบ"
      const confirmRestore = await showDialog({
        title: "ยืนยันการกู้คืนโฟลเดอร์", 
        text: "คุณต้องการกู้คืนโฟลเดอร์นี้และไฟล์ทั้งหมดภายในหรือไม่?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "กู้คืน", 
        cancelButtonText: "ยกเลิก",
        confirmButtonColor: "#4CAF50" // สีเขียวอ่อนแบบ Material Design
      });
      
      if (!confirmRestore.isConfirmed) {
        return;
      }
      
      await callApi('post', `/folders/${folderId}/restore`, null, navigate);
      showSuccess("กู้คืนโฟลเดอร์สำเร็จ");
      
      // รีเฟรชข้อมูลถังขยะ
      fetchDeletedFolders();
      fetchDeletedFiles();
    } catch (error) {
      console.error("Error restoring folder:", error);
      showError("ไม่สามารถกู้คืนโฟลเดอร์ได้", error);
    }
  }, [fetchDeletedFolders, fetchDeletedFiles, navigate]);

  // ลบโฟลเดอร์ถาวร
  const handlePermanentDeleteFolder = useCallback(async (folderId) => {
    try {
      const token = localStorage.getItem('admin_token');
      await axios.delete(`/folders/${folderId}/permanent`, {
        headers: { 'Authorization': `Bearer ${token}` },
        data: { deletePhysicalFiles: true } // ชัดเจนว่าต้องการลบไฟล์จริง
      });
      
      // รีเฟรชข้อมูลโฟลเดอร์ในถังขยะ
      fetchDeletedFolders();
      return true;
    } catch (error) {
      console.error("Error permanently deleting folder:", error);
      throw error;
    }
  }, [fetchDeletedFolders]);

  // ดาวน์โหลดไฟล์
  const handleDownloadFile = useCallback(async (fileId, fileName) => {
    try {
      // ปิด loading ที่อาจค้างอยู่ก่อน
      MySwal.close();
      
      const loadingDialog = showLoading("กำลังดาวน์โหลดไฟล์...");
      
      const token = localStorage.getItem('admin_token');
      const response = await axios.get(`/download/${fileId}`, {
        headers: { 'Authorization': `Bearer ${token}` },
        responseType: 'blob'
      });
      
      // ปิด loading dialog
      loadingDialog.close();
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      // ปิด loading ที่อาจค้างอยู่
      MySwal.close();
      
      console.error("Error downloading file:", error);
      showError("ไม่สามารถดาวน์โหลดไฟล์ได้", error);
    }
  }, []);

  // อัพโหลดไฟล์
  const handleFileUpload = useCallback(async (files) => {
    if (!currentFolderId) {
      showError("ไม่สามารถอัพโหลดได้", { response: { data: { message: "กรุณาเลือกโฟลเดอร์สำหรับอัพโหลดไฟล์" }}});
      return;
    }
    
    try {
      // ปิด loading ที่อาจค้างอยู่ก่อน
      MySwal.close();
      
      const loadingDialog = showLoading(`กำลังอัพโหลด ${files.length} ไฟล์ โปรดรอสักครู่...`);
      
      const token = localStorage.getItem('admin_token');
      let successCount = 0;
      let errorCount = 0;
      
      for (const file of files) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("folderId", currentFolderId);
        
        try {
          await axios.post("/upload", formData, {
            headers: { 
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'multipart/form-data'
            }
          });
          successCount++;
        } catch (error) {
          console.error(`Error uploading ${file.name}:`, error);
          errorCount++;
        }
      }
      
      // ปิด loading dialog
      loadingDialog.close();
      
      if (successCount > 0) {
        showSuccess(`อัพโหลดไฟล์สำเร็จ ${successCount} ไฟล์${errorCount > 0 ? ` (มีข้อผิดพลาด ${errorCount} ไฟล์)` : ''}`);
        fetchFiles(currentFolderId);
      } else if (errorCount > 0) {
        showError(`เกิดข้อผิดพลาดในการอัพโหลดไฟล์ทั้งหมด ${errorCount} ไฟล์`);
      }
    } catch (error) {
      // ปิด loading ที่อาจค้างอยู่
      MySwal.close();
      
      console.error("Error handling file upload:", error);
      showError("ไม่สามารถอัพโหลดไฟล์ได้", error);
    }
  }, [currentFolderId, fetchFiles]);

  // กรองโฟลเดอร์ตามคำค้นหา
  const filteredFolders = folders.filter(folder => 
    folder.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // กรองไฟล์ตามคำค้นหา
  const filteredFiles = files.filter(file => 
    file.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // แก้ไขฟังก์ชันเปลี่ยนโหมดถังขยะ - ใช้ navigationStateRef แทน state
  const toggleDeletedMode = useCallback((value) => {
    // เปิดถังขยะ
    if (value === true && !showDeleted) {
      // บันทึกสถานะปัจจุบันก่อนเปลี่ยนเป็นโหมดถังขยะ
      navigationStateRef.current.normalMode = {
        folderId: currentFolderId,
        path: [...folderPath]
      };
      navigationStateRef.current.trashMode.active = true;
      
      // เริ่มดึงข้อมูลถังขยะ
      fetchDeletedFolders();
      fetchDeletedFiles();
    } 
    // ปิดถังขยะ กลับไปโหมดปกติ
    else if (value === false && showDeleted) {
      // เรียกคืนสถานะโหมดปกติ
      const { folderId, path } = navigationStateRef.current.normalMode;
      navigationStateRef.current.trashMode.active = false;
      
      // ใช้ setTimeout เพื่อให้แน่ใจว่าการเปลี่ยน state ทั้งหมดได้รับการประมวลผลหลัง render cycle
      setTimeout(() => {
        setCurrentFolderId(folderId);
        setFolderPath(path);
        
        // โหลดข้อมูลตามสถานะที่บันทึกไว้
        if (folderId) {
          fetchFolders(folderId);
          fetchFiles(folderId);
        } else {
          fetchFolders(null);
          setFiles([]);
        }
      }, 0);
    }
    
    // เปลี่ยนสถานะการแสดงถังขยะ
    setShowDeleted(value);
  }, [showDeleted, currentFolderId, folderPath, fetchFolders, fetchFiles, fetchDeletedFolders, fetchDeletedFiles]);

  // กลับไปที่หน้าหลัก (root)
  const navigateToRoot = useCallback(() => {
    navigationStateRef.current.normalMode = {
      folderId: null,
      path: []
    };
    
    setCurrentFolderId(null);
    setFolderPath([]);
    fetchFolders(null);
    setFiles([]);
  }, [fetchFolders]);

  // ตรวจสอบ token และโหลดข้อมูลเริ่มต้น
  useEffect(() => {
    console.log('🔄 FileDataProvider: เริ่มการโหลดข้อมูล...');
    
    const token = localStorage.getItem('admin_token');
    
    if (!token) {
      console.log('❌ FileDataProvider: ไม่พบ token, กำลังนำทางไปหน้าเข้าสู่ระบบ');
      showDialog({
        title: 'ไม่มีสิทธิ์เข้าถึง',
        text: 'กรุณาเข้าสู่ระบบก่อน',
        icon: 'error',
        confirmButtonColor: "#3085d6",
        confirmButtonText: 'ตกลง'
      }).then(() => {
        navigate('/adminlogin');
      });
      return;
    }
    
    // เริ่มต้นโหลดข้อมูลตามโหมดปัจจุบัน
    if (showDeleted) {
      console.log('🗑️ FileDataProvider: กำลังโหลดข้อมูลถังขยะ');
      fetchDeletedFolders();
      fetchDeletedFiles();
    } else {
      console.log('📂 FileDataProvider: กำลังโหลดข้อมูลโฟลเดอร์', currentFolderId ? `(ID: ${currentFolderId})` : '(Root)');
      fetchFolders(currentFolderId);
      if (currentFolderId) {
        console.log('📄 FileDataProvider: กำลังโหลดข้อมูลไฟล์ในโฟลเดอร์ ID:', currentFolderId);
        fetchFiles(currentFolderId);
      }
    }
  }, [navigate, showDeleted, currentFolderId, fetchFolders, fetchFiles, fetchDeletedFiles, fetchDeletedFolders]);

  // ข้อมูลที่จะส่งผ่าน Context
  const value = {
    // State
    loading,
    files,
    folders,
    currentFolderId,
    folderPath,
    searchTerm,
    showDeleted,
    deletedFiles,
    deletedFolders,
    viewMode,
    filteredFolders,
    filteredFiles,
    
    // State updaters
    setLoading,
    setCurrentFolderId,
    setFolderPath,
    setSearchTerm,
    setShowDeleted: toggleDeletedMode,
    setViewMode,
    
    // Navigation helpers
    navigateToRoot,
    
    // Action handlers
    fetchFolders,
    fetchFiles,
    fetchDeletedFiles,
    fetchDeletedFolders,
    handleFolderClick,
    handleBack,
    handleCreateFolder,
    handleDeleteFile,
    handleRestoreFile,
    handlePermanentDeleteFile,
    handleDeleteFolder,
    handleRestoreFolder,
    handlePermanentDeleteFolder,
    handleDownloadFile,
    handleFileUpload
  };

  return (
    <FileDataContext.Provider value={value}>
      {children}
    </FileDataContext.Provider>
  );
};

// Custom hook สำหรับใช้เข้าถึงข้อมูลและฟังก์ชันต่างๆ
export const useFileData = () => {
  const context = useContext(FileDataContext);
  if (context === null) {
    throw new Error("useFileData must be used within a FileDataProvider");
  }
  return context;
};