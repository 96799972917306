import React, { useEffect, useState } from "react";
import axios from "axios";
import { format } from "date-fns";
import { useNavigate } from 'react-router-dom';

const Knowledge = () => {
  const [articles, setArticles] = useState([]); // State สำหรับเก็บข้อมูลบทความ
  const [loading, setLoading] = useState(true); // State สำหรับ Loading
  const [error, setError] = useState(null); // State สำหรับ Error
  const [selectedCategory, setSelectedCategory] = useState('all');
  const navigate = useNavigate();

  // State สำหรับ Pagination
  const [currentPage, setCurrentPage] = useState(1); // หน้าปัจจุบัน default หน้าที่ 1
  const articlesPerPage = 3; // จำนวนบทความต่อหน้า

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate('/');
    }
  }, [navigate]);

  // ดึงข้อมูลบทความจาก API
  useEffect(() => {
    const fetchArticles = async () => {
      try {
        setLoading(true);
        const response = await axios.get('/articles');
        
        // เรียงลำดับตาม order_position มากไปน้อย
        const sortedArticles = response.data.sort((a, b) => {
          // ถ้า order_position ไม่มีหรือเท่ากัน ให้เรียงตาม date_posted
          if (a.order_position === b.order_position || a.order_position === undefined || b.order_position === undefined) {
            return new Date(b.date_posted) - new Date(a.date_posted);
          }
          // เรียงตาม order_position จากมากไปน้อย
          return b.order_position - a.order_position;
        });
        
        setArticles(sortedArticles);
      } catch (err) {
        console.error("Error fetching articles:", err);
        setError('ไม่สามารถโหลดข้อมูลบทความได้ กรุณาลองใหม่อีกครั้ง');
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, []);

  // กรองบทความตามหมวดหมู่
  const filteredArticles = selectedCategory === 'all'
    ? articles
    : articles.filter(article => article.category === selectedCategory);

  // แสดงเฉพาะบทความที่เผยแพร่ (is_active = 1)
  const publishedArticles = filteredArticles.filter(article => article.is_active === 1);

  // คำนวณหน้า
  const totalPages = Math.ceil(publishedArticles.length / articlesPerPage);
  const startIndex = (currentPage - 1) * articlesPerPage;
  const selectedArticles = publishedArticles.slice(startIndex, startIndex + articlesPerPage);

  // ฟังก์ชันเปลี่ยนหน้า
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // ฟังก์ชันกลับหน้า
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 min-h-screen ">
      <h1 className="text-3xl font-bold text-center mb-8">คลังความรู้</h1>
      
      {/* ตัวเลือกหมวดหมู่ */}
      <div className="flex justify-center mb-8">
        <div className="flex flex-wrap gap-2">
          <button
            onClick={() => setSelectedCategory('all')}
            className={`px-4 py-2 rounded-full ${
              selectedCategory === 'all' 
                ? 'bg-blue-600 text-white' 
                : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
            }`}
          >
            ทั้งหมด
          </button>
          <button
            onClick={() => setSelectedCategory('general')}
            className={`px-4 py-2 rounded-full ${
              selectedCategory === 'general' 
                ? 'bg-blue-600 text-white' 
                : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
            }`}
          >
            ทั่วไป
          </button>
          <button
            onClick={() => setSelectedCategory('technology')}
            className={`px-4 py-2 rounded-full ${
              selectedCategory === 'technology' 
                ? 'bg-blue-600 text-white' 
                : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
            }`}
          >
            เทคโนโลยี
          </button>
          <button
            onClick={() => setSelectedCategory('guide')}
            className={`px-4 py-2 rounded-full ${
              selectedCategory === 'guide' 
                ? 'bg-blue-600 text-white' 
                : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
            }`}
          >
            คู่มือการใช้งาน
          </button>
          <button
            onClick={() => setSelectedCategory('faq')}
            className={`px-4 py-2 rounded-full ${
              selectedCategory === 'faq' 
                ? 'bg-blue-600 text-white' 
                : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
            }`}
          >
            คำถามที่พบบ่อย
          </button>
          <button
            onClick={() => setSelectedCategory('update')}
            className={`px-4 py-2 rounded-full ${
              selectedCategory === 'update' 
                ? 'bg-blue-600 text-white' 
                : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
            }`}
          >
            อัปเดตระบบ
          </button>
        </div>
      </div>
      
      {/* สถานะการโหลด */}
      {loading && (
        <div className="flex justify-center items-center py-12">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      )}
      
      {/* แสดงข้อผิดพลาด */}
      {error && (
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-8">
          <p>{error}</p>
        </div>
      )}
      
      {/* แสดงบทความ */}
      {!loading && !error && (
        <>
          {publishedArticles.length === 0 ? (
            <div className="text-center py-8">
              <p className="text-gray-500">ไม่พบบทความในหมวดหมู่นี้</p>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {selectedArticles.map(article => {
              console.log("article.image_filename", article.image_filename); // ✅ เพิ่มตรงนี้
              
              return (
                <div key={article.article_id} className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow flex flex-col h-[500px] sm:h-[520px]">
                  {article.image_filename ? (
                    <div className="h-48 overflow-hidden flex-shrink-0">
                      <img 
                        src={`${process.env.REACT_APP_UPLOAD_URL}/article/${article.image_filename}`} 
                        alt={article.title}
                        className="w-full h-full object-cover"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = `${process.env.REACT_APP_UPLOAD_URL}/article/no-image.jpg`;
                        }}
                      />
                    </div>
                  ) : (
                    <div className="h-48 bg-gray-100 flex items-center justify-center flex-shrink-0">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                      </svg>
                    </div>
                  )}
                  
                  <div className="p-6 flex flex-col flex-grow">
                    <div className="flex items-center mb-2">
                      <span className="px-2 py-1 text-xs font-semibold bg-blue-100 text-blue-800 rounded-full">
                        {article.category || 'ทั่วไป'}
                      </span>
                      <span className="ml-auto text-sm text-gray-500">
                        {article.date_posted ? format(new Date(article.date_posted), 'dd/MM/yyyy') : '-'}
                      </span>
                    </div>
                    
                    <h2 className="text-xl font-semibold mb-2 line-clamp-2">{article.title}</h2>
                    
                    <div className="text-gray-600 mb-4 line-clamp-4 flex-grow">
                      {article.summary || (article.content && article.content.substring(0, 200))}
                    </div>
                    
                    <div className="flex items-center justify-between mt-auto pt-4 border-t border-gray-100">
                      <span className="text-sm text-gray-500">โดย: {article.author || 'Admin'}</span>
                      <a 
                        href={`/knowledge/${article.slug || article.article_id}`}
                        className="px-3 py-1.5 bg-blue-50 hover:bg-blue-100 text-blue-600 hover:text-blue-800 rounded-md text-sm font-medium transition-colors duration-200"
                      >
                        อ่านเพิ่มเติม
                      </a>
                    </div>
                  </div>
                </div>
                 );
              })}

            </div>
          )}
        </>
      )}
      
      {/* Pagination - Modern Elegant Style */}
      {totalPages > 1 && (
        <div className="flex justify-center mt-10 mb-6">
          <div className="flex items-center gap-2 bg-gradient-to-r from-gray-50 to-gray-100 p-2 rounded-xl shadow-md">
            {/* Previous Page Button */}
            <button 
              onClick={prevPage} 
              disabled={currentPage === 1}
              className={`flex items-center justify-center w-10 h-10 rounded-full transition-all duration-300
                ${currentPage === 1 
                  ? 'text-gray-400 cursor-not-allowed' 
                  : 'text-blue-600 hover:bg-blue-50 hover:shadow-sm'}
              `}
              aria-label="Previous page"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
            </button>
            
            {/* Page Numbers - for showing more than just current page */}
            <div className="flex items-center gap-1">
              {/* First Page */}
              {currentPage > 2 && (
                <button
                  onClick={() => setCurrentPage(1)}
                  className="w-8 h-8 rounded-full text-sm font-medium hover:bg-blue-50 transition-all duration-300"
                >
                  1
                </button>
              )}
              
              {/* Ellipsis if needed */}
              {currentPage > 3 && (
                <span className="px-1 text-gray-500">...</span>
              )}
              
              {/* Previous Page Number */}
              {currentPage > 1 && (
                <button
                  onClick={() => setCurrentPage(currentPage - 1)}
                  className="w-8 h-8 rounded-full text-sm font-medium hover:bg-blue-50 transition-all duration-300"
                >
                  {currentPage - 1}
                </button>
              )}
              
              {/* Current Page */}
              <button
                className="w-10 h-10 bg-blue-600 text-white rounded-full text-sm font-medium shadow-sm hover:shadow-md hover:bg-blue-700 transition-all duration-300"
              >
                {currentPage}
              </button>
              
              {/* Next Page Number */}
              {currentPage < totalPages && (
                <button
                  onClick={() => setCurrentPage(currentPage + 1)}
                  className="w-8 h-8 rounded-full text-sm font-medium hover:bg-blue-50 transition-all duration-300"
                >
                  {currentPage + 1}
                </button>
              )}
              
              {/* Ellipsis if needed */}
              {currentPage < totalPages - 2 && (
                <span className="px-1 text-gray-500">...</span>
              )}
              
              {/* Last Page */}
              {currentPage < totalPages - 1 && (
                <button
                  onClick={() => setCurrentPage(totalPages)}
                  className="w-8 h-8 rounded-full text-sm font-medium hover:bg-blue-50 transition-all duration-300"
                >
                  {totalPages}
                </button>
              )}
            </div>
            
            {/* Next Page Button */}
            <button 
              onClick={nextPage} 
              disabled={currentPage === totalPages}
              className={`flex items-center justify-center w-10 h-10 rounded-full transition-all duration-300
                ${currentPage === totalPages 
                  ? 'text-gray-400 cursor-not-allowed' 
                  : 'text-blue-600 hover:bg-blue-50 hover:shadow-sm'}
              `}
              aria-label="Next page"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
              </svg>
            </button>
            
            {/* Page Info */}
            <div className="hidden md:block pl-4 ml-2 border-l border-gray-300">
              <span className="text-sm font-medium text-gray-700">
                หน้า {currentPage} จาก {totalPages}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Knowledge;
