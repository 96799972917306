import React, { useState } from "react";
import axios from '../utils/axiosConfig';
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// เพิ่ม import สำหรับไอคอน
import { FiCalendar, FiAlertCircle, FiFileText, FiSend } from 'react-icons/fi';

const MySwal = withReactContent(Swal);

const Report = () => {
  const [formData, setFormData] = useState({
    date: new Date(),
    subject: '',
    details: ''
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleDateChange = (date) => {
    setFormData(prevState => ({
      ...prevState,
      date: date
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    // ดึง token จาก localStorage
    const token = localStorage.getItem("token");
    
    try {
      // ตรวจสอบและแปลงวันที่ให้ถูกต้อง
      let formattedDate;
      if (formData.date) {
        const dateObj = new Date(formData.date);
        formattedDate = format(dateObj, 'yyyy-MM-dd');
      } else {
        formattedDate = format(new Date(), 'yyyy-MM-dd');
      }

      // สร้าง data สำหรับส่ง API
      const submissionData = { 
        date: formattedDate,
        title: formData.subject,    // subject จาก form จะเป็น title ใน API
        description: formData.details || ''    // details จาก form จะเป็น description ใน API
      };

      console.log('Submitting data:', submissionData);

      // ใช้ API report_by_users ที่คุณกำหนด
      const response = await axios({
        method: 'POST',
        url: '/report_by_users',
        data: submissionData,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200 || response.status === 201) {
        MySwal.fire({
          title: 'สำเร็จ!',
          text: 'ส่งรายงานปัญหาเรียบร้อยแล้ว ผู้ดูแลจะตรวจสอบและดำเนินการต่อไป',
          icon: 'success',
          confirmButtonText: 'ตกลง',
          confirmButtonColor: '#3085d6'
        });
        
        setFormData({
          date: new Date(),
          subject: '',
          details: ''
        });
      } else {
        // แสดงข้อความผิดพลาดกรณีส่งไม่สำเร็จ
        MySwal.fire({
          title: 'เกิดข้อผิดพลาด!',
          text: 'ไม่สามารถส่งรายงานปัญหาได้',
          icon: 'error',
          confirmButtonText: 'ตกลง',
          confirmButtonColor: '#3085d6'
        });
      }
    } catch (error) {
      console.error('Error submitting report:', error);
      
      // แสดงข้อความผิดพลาดที่ละเอียดขึ้น
      const errorMessage = error.response?.data?.message || 'ไม่สามารถส่งรายงานปัญหาได้';
      MySwal.fire({
        title: 'เกิดข้อผิดพลาด!',
        text: errorMessage,
        icon: 'error',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#3085d6'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center py-10 p-8 bg-gradient-to-br bg-gray-100 min-h-screen">
      <div className="w-full max-w-xl rounded-xl shadow-xl p-10 mt-10 bg-white text-left border border-gray-100">
        <div className="flex items-center justify-center mb-5">
          <div className="bg-blue-10 p-2 rounded-full">
            <FiAlertCircle className="text-blue-600 text-3xl" />
          </div>
          <h2 className="text-3xl font-bold text-gray-800 ml-3">แจ้งปัญหา</h2>
        </div>

        <form onSubmit={handleSubmit} className="space-y-1">
          <div className="transition-all duration-300  p-4  ">
            <label className="block text-sm font-medium text-gray-700 mb-2 flex items-center">
              <FiCalendar className="mr-2 text-blue-500" />
              วันที่
            </label>
            <DatePicker
              selected={formData.date}
              onChange={handleDateChange}
              dateFormat="dd/MM/yyyy"
              className="w-[60%] border border-gray-300 rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
              placeholderText="เลือกวันที่..."
              required
            />
          </div>
          
          <div className="transition-all duration-300  p-4 rounded-lg ">
            <label className="block text-sm font-medium text-gray-700 mb-2 flex items-center">
              <FiFileText className="mr-2 text-blue-500" />
              เรื่อง
            </label>
            <input
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              placeholder="ระบุหัวข้อ..."
              className="w-full border border-gray-300 rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
              required
            />
          </div>

          <div className="transition-all duration-300 p-4  ">
            <label className="block text-sm font-medium text-gray-700 mb-2 flex items-center">
              <FiFileText className="mr-2 text-blue-500" />
              รายละเอียด
            </label>
            <textarea
              name="details"
              value={formData.details}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-lg px-4 py-3 h-32 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
              placeholder="รายละเอียดปัญหา..."
              required
            />
          </div>
          
          <div className="flex justify-center mt-6">
            <button
              type="submit"
              disabled={loading}
              className={`w-[90%] bg-gradient-to-r from-blue-500 to-blue-700 text-white px-6 py-3 mt-3 rounded-lg hover:from-blue-600 hover:to-blue-800 transition-all shadow-md flex items-center justify-center gap-2 ${loading ? 'opacity-70 cursor-not-allowed' : 'hover:shadow-lg transform hover:-translate-y-0.5'}`}
            >
              {loading ? (
                <>
                  <svg className="animate-spin -ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  กำลังส่ง...
                </>
              ) : (
                <>
                  <FiSend /> ส่งรายงาน
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Report;
