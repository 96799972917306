import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // ตั้งค่า appElement สำหรับ modal

// แก้ไขค่าคงที่ departments เพื่อเพิ่มสีของแต่ละแผนก
const departments = [
  { id: "acc", label: "Accounting", color: "bg-blue-100 text-blue-800" },
  { id: "fin", label: "Finance", color: "bg-green-100 text-green-800" },
  { id: "wh", label: "Warehouse", color: "bg-yellow-100 text-yellow-800" },
  { id: "hr", label: "Human Resources", color: "bg-purple-100 text-purple-800" },
  { id: "it", label: "Information Technology", color: "bg-red-100 text-red-800" }
];

const CreateFolderModal = ({ isOpen, onClose, onSubmit, folderAccessLevel = 'all' }) => {
  const [folderName, setFolderName] = useState('');
  const [accessLevels, setAccessLevels] = useState([]);
  const [currentUserDept, setCurrentUserDept] = useState('');
  const [errorMessage, setErrorMessage] = useState("");
  const [allowedDepartments, setAllowedDepartments] = useState([]);
  const modalRef = useRef(null);
  
  // เพิ่ม event listener สำหรับการคลิกนอก modal
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target) && isOpen) {
        onClose();
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);
  
  // กำหนดค่าเริ่มต้นเมื่อ modal เปิด
  useEffect(() => {
    if (isOpen) {
      setFolderName('');
      setErrorMessage("");
      
      console.log('Modal open. Incoming folderAccessLevel:', folderAccessLevel);
      
      // ดึงข้อมูล current user จาก localStorage
      const currentUser = JSON.parse(localStorage.getItem('currentUser'));
      const userDept = currentUser?.access_level || '';
      setCurrentUserDept(userDept);
      
      // กำหนดรายการแผนกที่อนุญาต
      if (folderAccessLevel === 'all') {
        // ถ้าโฟลเดอร์แม่เป็น 'all' อนุญาตให้เลือกแผนกได้ทั้งหมด
        setAllowedDepartments(departments.map(d => d.id));
        
        // บังคับให้เลือกแผนกของตัวเอง (ถ้ามีค่า)
        if (userDept && userDept !== 'all') {
          setAccessLevels([userDept]);
        } else {
          setAccessLevels([]); // รีเซ็ตเป็นค่าว่าง (ถ้าไม่มีค่า userDept)
        }
      } else {
        // ถ้าโฟลเดอร์แม่มีการจำกัดสิทธิ์
        const parentDepts = folderAccessLevel.split(',');
        setAllowedDepartments(parentDepts);
        
        // ตั้งค่าให้ accessLevels มีค่าตาม parent departments
        let initialAccessLevels = [...parentDepts];
        
        // ถ้า userDept ไม่อยู่ใน parentDepts ให้เพิ่มเข้าไป
        if (userDept && userDept !== 'all' && !initialAccessLevels.includes(userDept)) {
          initialAccessLevels.push(userDept);
        }
        
        setAccessLevels(initialAccessLevels);
      }
    }
  }, [isOpen, folderAccessLevel]);
  
  const handleAccessLevelChange = (deptId) => {
    // ถ้าเป็นแผนกของผู้ใช้ปัจจุบัน ไม่อนุญาตให้ยกเลิกการเลือก
    if (deptId === currentUserDept) return;
    
    setAccessLevels(prev => {
      if (prev.includes(deptId)) {
        // ลบออก
        return prev.filter(id => id !== deptId);
      } else {
        // เพิ่มเข้าไป
        return [...prev, deptId];
      }
    });
  };
  
  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    
    // ตรวจสอบว่าผู้ใช้กรอกชื่อโฟลเดอร์หรือไม่
    if (!folderName.trim()) {
      setErrorMessage("กรุณากรอกชื่อโฟลเดอร์");
      return;
    }
    
    // ตรวจสอบให้แน่ใจว่าแผนกของผู้ใช้ถูกรวมอยู่ในสิทธิ์เสมอ
    let effectiveAccessLevels = [...accessLevels];
    if (currentUserDept && currentUserDept !== 'all' && !effectiveAccessLevels.includes(currentUserDept)) {
      effectiveAccessLevels.push(currentUserDept);
    }
    
    const effectiveAccessLevel = effectiveAccessLevels.length === 0 || 
      (effectiveAccessLevels.length === departments.length && folderAccessLevel === 'all') 
      ? 'all' 
      : effectiveAccessLevels.join(',');
    
    console.log('Submitting folder:', folderName, 'with access levels:', effectiveAccessLevel);
    onSubmit(folderName.trim(), effectiveAccessLevel);
  };
  
  // ตรวจสอบว่าผู้ใช้เป็น admin หรือไม่
  const isAdmin = () => {
    return !!localStorage.getItem('admin_token');
  };
  
  // ถ้า modal ไม่เปิด ไม่ต้องแสดงอะไร
  if (!isOpen) return null;
  
  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex justify-center items-center">
      <div 
        ref={modalRef}
        className="bg-white rounded-lg shadow-xl p-6 w-full max-w-md mx-4 animate-fadeIn"
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold text-gray-800">สร้างโฟลเดอร์ใหม่</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        
        <div className="mb-4">
          <label htmlFor="folderName" className="block text-gray-700 font-medium mb-2">
            ชื่อโฟลเดอร์
          </label>
          <input
            id="folderName"
            type="text"
            className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
            placeholder="ระบุชื่อโฟลเดอร์..."
            autoFocus
          />
          {errorMessage && <p className="mt-1 text-sm text-red-500">{errorMessage}</p>}
        </div>
        
        <div className="mb-5">
          <label className="block text-gray-700 font-medium mb-2">
            กำหนดสิทธิ์การเข้าถึง
          </label>
          
          {/* เพิ่มข้อความเตือนว่าต้องรวมแผนกของตัวเอง */}
          <div className="mb-2 p-2 bg-blue-50 border border-blue-200 rounded-md">
            <p className="text-sm text-blue-700">
              <span className="font-medium">หมายเหตุ:</span> แผนกของคุณจะถูกรวมในการกำหนดสิทธิ์เสมอเพื่อให้คุณสามารถเข้าถึงโฟลเดอร์ได้
            </p>
          </div>
          
          {folderAccessLevel !== 'all' && (
            <div className="mb-2 p-2 bg-yellow-50 border border-yellow-200 rounded-md">
              <p className="text-sm text-yellow-700">
                <span className="font-medium">หมายเหตุ:</span> คุณสามารถกำหนดสิทธิ์ได้เฉพาะแผนกที่มีสิทธิ์ในโฟลเดอร์หลักเท่านั้น
              </p>
            </div>
          )}
          
          <div className="space-y-2">
            {isAdmin() && folderAccessLevel === 'all' && (
              <div className="flex items-center mb-2 p-1 hover:bg-gray-100 rounded">
                <input
                  type="checkbox"
                  id="access-all"
                  checked={accessLevels.length === 0}
                  onChange={() => setAccessLevels([])}
                  className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <label htmlFor="access-all" className="ml-2 text-gray-700">
                  <span className="px-2 py-0.5 text-xs rounded bg-gray-100 text-gray-800 inline-flex items-center">
                    ทุกแผนก (All)
                  </span>
                </label>
              </div>
            )}
            
            {departments.map(dept => {
              // แสดงเฉพาะแผนกที่อยู่ใน allowedDepartments
              if (!allowedDepartments.includes(dept.id)) return null;
              
              // ตรวจสอบว่าเป็นแผนกของตัวเองหรือไม่
              const isOwnDepartment = dept.id === currentUserDept;
              
              return (
                <div key={dept.id} className="flex items-center">
                  <input
                    type="checkbox"
                    id={`dept-${dept.id}`}
                    checked={accessLevels.includes(dept.id) || isOwnDepartment}
                    onChange={() => handleAccessLevelChange(dept.id)}
                    disabled={isOwnDepartment} // ไม่สามารถยกเลิกแผนกของตัวเองได้
                    className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <label htmlFor={`dept-${dept.id}`} className="ml-2 text-gray-700 flex items-center">
                    <span className={`px-2 py-0.5 text-xs rounded ${dept.color} inline-flex items-center`}>
                      {dept.label}
                    </span>
                    {isOwnDepartment && (
                      <span className="ml-2 text-xs text-blue-600 font-medium">(แผนกของคุณ)</span>
                    )}
                  </label>
                </div>
              );
            })}
          </div>
          
          {/* แสดงตัวอย่างที่เลือก */}
          {accessLevels.length > 0 && (
            <div className="mt-3">
              <p className="text-sm text-gray-600 mb-1">แผนกที่จะมีสิทธิ์เข้าถึง:</p>
              <div className="flex flex-wrap gap-1">
                {accessLevels.map(level => {
                  const dept = departments.find(d => d.id === level);
                  return dept ? (
                    <span key={level} className={`px-2 py-0.5 text-xs rounded ${dept.color}`}>
                      {dept.label}
                    </span>
                  ) : null;
                })}
              </div>
            </div>
          )}
        </div>
        
        <div className="flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-100"
          >
            ยกเลิก
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            สร้างโฟลเดอร์
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateFolderModal;