import React, { useState, useEffect } from 'react';
import axios from '../utils/axiosConfig';
import { ChevronDown, ChevronUp, HelpCircle } from 'lucide-react';

const Faq = () => {
  const [faqs, setFaqs] = useState([]);
  const [expandedId, setExpandedId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // โหลดข้อมูล FAQ จาก API
  useEffect(() => {
    axios.get('/faqs') // เปลี่ยนเป็นใช้ path เท่านั้น ไม่ใส่ URL เต็ม
      .then(response => {
        // เรียงตาม order_position
        const sortedFaqs = [...response.data].sort((a, b) => {
          // กรณีมี order_position เป็น null หรือ undefined
          const orderA = a.order_position !== null && a.order_position !== undefined ? a.order_position : 999;
          const orderB = b.order_position !== null && b.order_position !== undefined ? b.order_position : 999;
          return orderA - orderB;
        });
        setFaqs(sortedFaqs);
        setLoading(false);
      })
      .catch(err => {
        console.error('Error fetching FAQs:', err);
        setError(err);
        setLoading(false);
      });
  }, []);

  // สลับระหว่างแสดง/ซ่อนคำตอบ
  const toggleFaq = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  if (loading) {
    return <div className="animate-pulse flex flex-col space-y-4">
      <div className="h-6 bg-gray-200 rounded w-3/4"></div>
      <div className="h-32 bg-gray-200 rounded"></div>
    </div>;
  }

  if (error) {
    return <div className="text-red-500">เกิดข้อผิดพลาดในการโหลดข้อมูล</div>;
  }

  return (
    <div className="bg-white p-1 rounded-lg">
      <h3 className="text-lg text-stone-800 font-bold flex items-center text-center justify-center">
        <HelpCircle className="h-5 w-5 mr-1 text-blue-500" /> คำถามที่พบบ่อย
      </h3>
      
      <div className="mt-4">
        {faqs.length === 0 ? (
          <p className="text-sm">ยังไม่มีคำถามที่พบบ่อย</p>
        ) : (
          <>
            
            {/* กำหนดความสูงสูงสุดและเพิ่ม scrollbar */}
            <div className="max-h-[300px] overflow-y-auto pr-1 scrollbar-hover-show">
              <div className="flex flex-col space-y-2">
                {faqs.map((faq) => (
                  <div key={faq.id} className="border border-gray-100 rounded-lg overflow-hidden shadow-sm">
                    <button
                      onClick={() => toggleFaq(faq.id)}
                      className="flex justify-between items-center w-full p-2.5 text-left bg-white hover:bg-gray-50 transition-colors"
                    >
                      <span className="text-sm font-medium text-gray-800 line-clamp-1">{faq.question}</span>
                      <span className="ml-2 flex-shrink-0">
                        {expandedId === faq.id ? 
                          <ChevronUp className="h-4 w-4 text-gray-500" /> : 
                          <ChevronDown className="h-4 w-4 text-gray-500" />}
                      </span>
                    </button>
                    
                    {expandedId === faq.id && (
                      <div className="p-2.5 bg-gray-50 border-t border-gray-100 text-sm text-gray-600">
                        {faq.answer}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Faq;