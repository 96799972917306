import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// คอนสแตนท์ departments (แผนกต่างๆ)
const departments = [
  { id: "acc", label: "Accounting", color: "bg-blue-100 text-blue-800" },
  { id: "fin", label: "Finance", color: "bg-green-100 text-green-800" },
  { id: "wh", label: "Warehouse", color: "bg-yellow-100 text-yellow-800" },
  { id: "hr", label: "Human Resources", color: "bg-purple-100 text-purple-800" },
  { id: "it", label: "Information Technology", color: "bg-red-100 text-red-800" }
];

const CreateFolderModal = ({ isOpen, onClose, onSubmit, folderAccessLevel = 'all' }) => {
  const [folderName, setFolderName] = useState('');
  const [accessLevels, setAccessLevels] = useState([]);
  const [currentUserDept, setCurrentUserDept] = useState('');
  const [errorMessage, setErrorMessage] = useState("");
  const [allowedDepartments, setAllowedDepartments] = useState([]);
  const modalRef = useRef(null);

  // เพิ่ม event listener สำหรับการคลิกนอก modal
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target) && isOpen) {
        onClose();
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  // กำหนดค่าเริ่มต้นเมื่อ modal เปิด
  useEffect(() => {
    if (isOpen) {
      setFolderName('');
      setErrorMessage("");
      
      console.log('Modal open. Incoming folderAccessLevel:', folderAccessLevel);
      
      // ดึงข้อมูล current user จาก localStorage
      const currentUser = JSON.parse(localStorage.getItem('currentUser'));
      const userDept = currentUser?.access_level || '';
      setCurrentUserDept(userDept);
      
      // กำหนดรายการแผนกที่อนุญาต
      if (folderAccessLevel === 'all') {
        // ถ้าโฟลเดอร์แม่เป็น 'all' อนุญาตให้เลือกแผนกได้ทั้งหมด
        setAllowedDepartments(departments.map(d => d.id));
        
        // บังคับให้เลือกแผนกของตัวเอง (ถ้ามีค่า)
        if (userDept && userDept !== 'all') {
          setAccessLevels([userDept]);
        } else {
          setAccessLevels([]); // รีเซ็ตเป็นค่าว่าง (ถ้าไม่มีค่า userDept)
        }
      } else {
        // ถ้าโฟลเดอร์แม่มีการจำกัดสิทธิ์
        const parentDepts = folderAccessLevel.split(',');
        setAllowedDepartments(parentDepts);
        
        // ตั้งค่าให้ accessLevels มีค่าตาม parent departments
        let initialAccessLevels = [...parentDepts];
        
        // ถ้า userDept ไม่อยู่ใน parentDepts ให้เพิ่มเข้าไป
        if (userDept && userDept !== 'all' && !initialAccessLevels.includes(userDept)) {
          initialAccessLevels.push(userDept);
        }
        
        setAccessLevels(initialAccessLevels);
      }
    }
  }, [isOpen, folderAccessLevel]);

  const handleAccessLevelChange = (deptId) => {
    // ถ้าเป็นแผนกของผู้ใช้ปัจจุบัน ไม่อนุญาตให้ยกเลิกการเลือก
    if (deptId === currentUserDept) return;
    
    setAccessLevels(prev => {
      if (prev.includes(deptId)) {
        // ลบออก
        return prev.filter(id => id !== deptId);
      } else {
        // เพิ่มเข้าไป
        return [...prev, deptId];
      }
    });
  };

  // ตรวจสอบว่าผู้ใช้เป็น admin หรือไม่
  const isAdmin = () => {
    return !!localStorage.getItem('admin_token');
  };

  const handleSubmit = () => {
    // ตรวจสอบว่าผู้ใช้กรอกชื่อโฟลเดอร์หรือไม่
    if (!folderName.trim()) {
      setErrorMessage("กรุณากรอกชื่อโฟลเดอร์");
      return;
    }
    
    // ตรวจสอบให้แน่ใจว่าแผนกของผู้ใช้ถูกรวมอยู่ในสิทธิ์เสมอ
    let effectiveAccessLevels = [...accessLevels];
    if (currentUserDept && currentUserDept !== 'all' && !effectiveAccessLevels.includes(currentUserDept)) {
      effectiveAccessLevels.push(currentUserDept);
    }
    
    const effectiveAccessLevel = effectiveAccessLevels.length === 0 || 
      (effectiveAccessLevels.length === departments.length && folderAccessLevel === 'all') 
      ? 'all' 
      : effectiveAccessLevels.join(',');
    
    console.log('Submitting folder:', folderName, 'with access levels:', effectiveAccessLevel);
    onSubmit(folderName.trim(), effectiveAccessLevel);
    onClose();
  };
  
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 p-4">
      <div 
        ref={modalRef} 
        className="bg-white rounded-lg shadow-xl w-full max-w-md"
      >
        <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-semibold text-gray-900">สร้างโฟลเดอร์ใหม่</h3>
            <button 
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600"
            >
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          
          <div className="space-y-4">
            {/* ชื่อโฟลเดอร์ */}
            <div>
              <label htmlFor="folder-name" className="block text-sm font-medium text-gray-700 mb-2">
                ชื่อโฟลเดอร์
              </label>
              <input
                id="folder-name"
                type="text"
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="ระบุชื่อโฟลเดอร์..."
                autoFocus
                required
              />
              {errorMessage && <p className="mt-1 text-sm text-red-500">{errorMessage}</p>}
            </div>

            {/* ตั้งค่าการเข้าถึง */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                กำหนดสิทธิ์การเข้าถึง
              </label>
              
              {/* เพิ่มข้อความเตือนว่าต้องรวมแผนกของตัวเอง */}
              <div className="mb-2 p-2 bg-blue-50 border border-blue-200 rounded-md">
                <p className="text-sm text-blue-700">
                  <span className="font-medium">หมายเหตุ:</span> แผนกของคุณจะถูกรวมในการกำหนดสิทธิ์เสมอเพื่อให้คุณสามารถเข้าถึงโฟลเดอร์ได้
                </p>
              </div>
              
              {folderAccessLevel !== 'all' && (
                <div className="mb-2 p-2 bg-yellow-50 border border-yellow-200 rounded-md">
                  <p className="text-sm text-yellow-700">
                    <span className="font-medium">หมายเหตุ:</span> คุณสามารถกำหนดสิทธิ์ได้เฉพาะแผนกที่มีสิทธิ์ในโฟลเดอร์หลักเท่านั้น
                  </p>
                </div>
              )}
              
              <div className="space-y-2">
                {isAdmin() && folderAccessLevel === 'all' && (
                  <div className="flex items-center mb-2 p-1 hover:bg-gray-100 rounded">
                    <input
                      type="checkbox"
                      id="access-all"
                      checked={accessLevels.length === 0}
                      onChange={() => setAccessLevels([])}
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <label htmlFor="access-all" className="ml-2 text-gray-700">
                      <span className="px-2 py-0.5 text-xs rounded bg-gray-100 text-gray-800 inline-flex items-center">
                        ทุกแผนก (All)
                      </span>
                    </label>
                  </div>
                )}
                
                {departments.map(dept => {
                  // แสดงเฉพาะแผนกที่อยู่ใน allowedDepartments
                  if (!allowedDepartments.includes(dept.id)) return null;
                  
                  // ตรวจสอบว่าเป็นแผนกของตัวเองหรือไม่
                  const isOwnDepartment = dept.id === currentUserDept;
                  
                  return (
                    <div key={dept.id} className="flex items-center">
                      <input
                        type="checkbox"
                        id={`dept-${dept.id}`}
                        checked={accessLevels.includes(dept.id) || isOwnDepartment}
                        onChange={() => handleAccessLevelChange(dept.id)}
                        disabled={isOwnDepartment} // ไม่สามารถยกเลิกแผนกของตัวเองได้
                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      />
                      <label htmlFor={`dept-${dept.id}`} className="ml-2 text-gray-700 flex items-center">
                        <span className={`px-2 py-0.5 text-xs rounded ${dept.color} inline-flex items-center`}>
                          {dept.label}
                        </span>
                        {isOwnDepartment && (
                          <span className="ml-2 text-xs text-blue-600 font-medium">(แผนกของคุณ)</span>
                        )}
                      </label>
                    </div>
                  );
                })}
              </div>
              
              {/* แสดงตัวอย่างที่เลือก */}
              {accessLevels.length > 0 && (
                <div className="mt-3">
                  <p className="text-sm text-gray-600 mb-1">แผนกที่จะมีสิทธิ์เข้าถึง:</p>
                  <div className="flex flex-wrap gap-1">
                    {accessLevels.map(level => {
                      const dept = departments.find(d => d.id === level);
                      return dept ? (
                        <span key={level} className={`px-2 py-0.5 text-xs rounded ${dept.color}`}>
                          {dept.label}
                        </span>
                      ) : null;
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
          
          {/* ปุ่มตัวเลือก */}
          <div className="flex justify-end space-x-3 mt-6">
            <button
              onClick={onClose}
              type="button"
              className="px-4 py-2 text-gray-600 border border-gray-300 rounded-md hover:bg-gray-100"
            >
              ยกเลิก
            </button>
            <button
              onClick={handleSubmit}
              type="button"
              disabled={!folderName.trim()}
              className={`px-4 py-2 text-white rounded-md ${
                !folderName.trim()
                  ? "bg-blue-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600 cursor-pointer"
              } transition`}
            >
              สร้าง
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

CreateFolderModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  folderAccessLevel: PropTypes.string
};

export default CreateFolderModal;