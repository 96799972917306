import React, { useState, useEffect, useCallback } from 'react';
import axios from '../../utils/axiosConfig'; 
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Card } from "../ui/card";
import { API_URL } from '../../utils/apiConfig';

const ApiTest = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedApi, setSelectedApi] = useState('faqs');
  const [baseUrl, setBaseUrl] = useState(API_URL.endsWith('/api') ? API_URL.slice(0, -4) : API_URL);

  const apiEndpoints = [
    { id: 'faqs', name: 'คำถามที่พบบ่อย (FAQs)', endpoint: '/faqs' },
    { id: 'articles', name: 'บทความ (Articles)', endpoint: '/articles' },
    { id: 'status', name: 'สถานะระบบ (Status)', endpoint: '/status' },
    { id: 'handbooks', name: 'คู่มือ (Handbooks)', endpoint: '/handbooks' }
  ];

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    
    const selectedEndpoint = apiEndpoints.find(api => api.id === selectedApi)?.endpoint || '/faqs';
    
    try {
      const response = await axios.get(`${baseUrl}${selectedEndpoint}`);
      setData(response.data);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [selectedApi, baseUrl]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="p-8 bg-gray-50 min-h-screen">
      <div className="flex justify-end items-center">
        <button
          onClick={() => navigate('/admin/dashboard')}
          className="flex gap-2 bg-none text-gray-600 px-4 py-2 mb-5 rounded-md hover:text-gray-800 transition-colors"
        >
          <ArrowLeft className="h-5 w-5" />
          ย้อนกลับ
        </button>
      </div>

      <h1 className="text-3xl font-bold text-center mb-8">ทดสอบเชื่อมต่อ API</h1>

      <div className="max-w-4xl mx-auto">
        <Card className="p-6 mb-6">
          <div className="mb-4">
            <label htmlFor="baseUrl" className="block text-sm font-medium text-gray-700 mb-2">
              URL ของ API
            </label>
            <input
              id="baseUrl"
              type="text"
              className="w-full p-2 border rounded-md"
              value={baseUrl}
              onChange={(e) => setBaseUrl(e.target.value)}
            />
            <p className="text-sm text-gray-500 mt-1">
              เปลี่ยนเป็น https://www.tjplogistics.com/api สำหรับเซิร์ฟเวอร์จริง
            </p>
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              เลือก API ที่ต้องการทดสอบ
            </label>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
              {apiEndpoints.map(api => (
                <button
                  key={api.id}
                  className={`p-3 rounded-md text-left ${
                    selectedApi === api.id
                      ? 'bg-blue-600 text-white'
                      : 'bg-gray-100 hover:bg-gray-200 text-gray-800'
                  }`}
                  onClick={() => setSelectedApi(api.id)}
                >
                  <div className="font-medium">{api.name}</div>
                  <div className="text-sm opacity-80">{api.endpoint}</div>
                </button>
              ))}
            </div>
          </div>

          <button
            className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
            onClick={fetchData}
          >
            ทดสอบเชื่อมต่อ
          </button>
        </Card>

        <Card className="p-6">
          <h2 className="text-xl font-semibold mb-4">ผลลัพธ์</h2>
          
          {loading ? (
            <div className="flex justify-center py-12">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
            </div>
          ) : error ? (
            <div className="bg-red-50 border border-red-200 rounded-md p-4">
              <h3 className="text-lg font-medium text-red-800 mb-2">เกิดข้อผิดพลาด</h3>
              <p className="text-red-700">{error.message}</p>
              {error.response && (
                <div className="mt-2">
                  <p>Status: {error.response.status}</p>
                  <p>Data: {JSON.stringify(error.response.data)}</p>
                </div>
              )}
            </div>
          ) : (
            <div className="bg-gray-50 p-4 rounded-md">
              <div className="flex justify-between mb-4">
                <h3 className="text-lg font-medium text-green-800">การเชื่อมต่อสำเร็จ</h3>
                <span className="px-2 py-1 bg-green-100 text-green-800 text-xs rounded-full">
                  {Array.isArray(data) ? `${data.length} รายการ` : 'ข้อมูลถูกต้อง'}
                </span>
              </div>
              <div className="overflow-auto max-h-96 bg-gray-100 p-3 rounded-md">
                <pre className="text-sm">{JSON.stringify(data, null, 2)}</pre>
              </div>
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};

export default ApiTest;