import React, { useState, useEffect } from "react";
import { Card } from "../ui/card.tsx";
import { User, Lock, LogIn, Shield, AlertCircle, Eye, EyeOff } from "lucide-react";
import axios from "../../utils/axiosConfig"; // แก้ไขเป็นใช้ axios จาก axiosConfig
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { motion } from "framer-motion";
import activityLogger from '../../utils/activityLogger';

const LoginAdmin = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  // เช็ค token เมื่อโหลดหน้า
  useEffect(() => {
    const admin_token = localStorage.getItem("admin_token");
    if (admin_token) {
      try {
        const decodedToken = jwtDecode(admin_token);
        console.log("ระดับสิทธิของแอดมิน:", decodedToken.access_level);
        navigate('/admin/dashboard');
      } catch (error) {
        localStorage.removeItem("admin_token");
        localStorage.removeItem("admin_name");
        localStorage.removeItem("adminTokenExpirationDisplay");
      }
    }
  }, [navigate]);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(""); // รีเซ็ตข้อความ error
    setLoading(true);

    try {
      const response = await axios.post("/adminlogin", formData);
      const { token, admin_name } = response.data;

      // เก็บข้อมูลใน localStorage
      localStorage.setItem("admin_token", token);
      localStorage.setItem("admin_name", admin_name);

      // ตั้งค่า token expiration
      const expirationDate = new Date();
      expirationDate.setMinutes(expirationDate.getMinutes() + 15); // หรือตั้งค่าตามที่ต้องการ
      localStorage.setItem("adminTokenExpiration", expirationDate.toISOString());

      // เก็บวันหมดอายุในรูปแบบที่อ่านง่ายแยกต่างหาก
      localStorage.setItem("adminTokenExpirationDisplay", expirationDate.toLocaleString('th-TH', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }));

      // Decode token และแสดง access_level
      const decodedToken = jwtDecode(token);
      console.log("ระดับสิทธิของแอดมิน:", decodedToken.access_level);
      localStorage.setItem("admin_access_level", decodedToken.access_level);

      // เพิ่มการบันทึก log เมื่อล็อกอินสำเร็จ
      activityLogger.login(admin_name || formData.username, true);

      navigate("/admin/dashboard");
    } catch (error) {
      setError(error.response?.data?.message || "เกิดข้อผิดพลาดในการเข้าสู่ระบบ");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-100 to-gray-200 dark:from-gray-900 dark:to-gray-800 flex items-center justify-center p-4">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-md w-full"
      >
        <Card className="p-8 shadow-xl bg-white dark:bg-gray-800 border-0 rounded-xl overflow-hidden">
          <motion.div 
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.1, duration: 0.4 }}
            className="text-center mb-8"
          >
            <div className="flex justify-center mb-4">
              <motion.div 
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                transition={{ 
                  type: "spring", 
                  stiffness: 260, 
                  damping: 20,
                  delay: 0.2 
                }}
                className="h-16 w-16 rounded-full bg-gradient-to-r from-blue-500 to-indigo-600 flex items-center justify-center shadow-lg"
              >
                <Shield className="h-8 w-8 text-white" />
              </motion.div>
            </div>
            <h1 className="text-3xl font-bold text-gray-800 dark:text-white mb-2">ลงชื่อเข้าใช้</h1>
            <p className="text-gray-600 dark:text-gray-400">สำหรับผู้ดูแลระบบ</p>
          </motion.div>

          {error && (
            <motion.div 
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              className="mb-6 p-3 bg-red-50 dark:bg-red-900/30 border-l-4 border-red-500 text-red-700 dark:text-red-300 rounded-r-lg flex items-center gap-2"
            >
              <AlertCircle className="h-5 w-5 flex-shrink-0" />
              <span>{error}</span>
            </motion.div>
          )}

          <motion.form 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.4 }}
            onSubmit={handleSubmit} 
            className="space-y-6"
          >
            <div className="space-y-2">
              <label className="text-sm font-medium text-gray-700 dark:text-gray-300">ชื่อผู้ใช้</label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <User className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  className="block w-full pl-10 pr-3 py-2.5 bg-gray-50 dark:bg-gray-700 rounded-lg 
                            ring-1 ring-gray-300 dark:ring-gray-600
                            focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400
                            dark:text-white text-gray-900 shadow-sm transition-all duration-200
                            border-0"
                  placeholder="กรุณากรอกชื่อผู้ใช้"
                  required
                  autoFocus
                />
              </div>
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium text-gray-700 dark:text-gray-300">รหัสผ่าน</label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Lock className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type={passwordVisible ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className="block w-full pl-10 pr-10 py-2.5 bg-gray-50 dark:bg-gray-700 rounded-lg
                            ring-1 ring-gray-300 dark:ring-gray-600
                            focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400
                            dark:text-white text-gray-900 shadow-sm transition-all duration-200
                            border-0"
                  placeholder="กรุณากรอกรหัสผ่าน"
                  required
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 transition-colors"
                  onClick={() => setPasswordVisible(!passwordVisible)}
                  aria-label={passwordVisible ? "ซ่อนรหัสผ่าน" : "แสดงรหัสผ่าน"}
                >
                  {passwordVisible ? <EyeOff className="h-5 w-5" /> : <Eye className="h-5 w-5" />}
                </button>
              </div>
            </div>

            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              type="submit"
              disabled={loading}
              className="w-full flex items-center justify-center gap-2 bg-gradient-to-r from-blue-600 to-indigo-600 text-white py-2.5 px-4 rounded-lg 
                        hover:from-blue-700 hover:to-indigo-700 
                        focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 
                        transition-all duration-200 disabled:opacity-70 disabled:cursor-not-allowed font-medium
                        border-0"
            >
              {loading ? (
                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              ) : (
                <>
                  <LogIn className="h-5 w-5" />
                  เข้าสู่ระบบ
                </>
              )}
            </motion.button>
          </motion.form>

          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.4 }}
            className="mt-6 text-center"
          >
            <p className="text-sm text-gray-500 dark:text-gray-400">
              พื้นที่สำหรับผู้ดูแลระบบเท่านั้น
            </p>
            <p className="text-xs text-gray-400 dark:text-gray-500 mt-2">
              © {new Date().getFullYear()} โต เจริญพร - Admin Portal
            </p>
          </motion.div>
        </Card>
      </motion.div>
    </div>
  );
};

export default LoginAdmin;
