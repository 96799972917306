import React, { useState, useEffect, useCallback } from 'react';
import { Card } from "../ui/card";
import { Plus, Search, Edit2, Trash2, ArrowLeft, Upload, Download, FileText, FileSpreadsheet, File, Image, Calculator, DollarSign, Warehouse, Users, Laptop, GripVertical, Filter } from 'lucide-react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const HandbookManagement = () => {
  const [selectedDepartment, setSelectedDepartment] = useState('all');
  const [orderChanged, setOrderChanged] = useState(false);
  const [handbooks, setHandbooks] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [editingHandbook, setEditingHandbook] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    file: null,
    description: '',
    department: 'acc' // ค่าเริ่มต้น
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [originalOrder, setOriginalOrder] = useState([]);
  const [sortedHandbooks, setSortedHandbooks] = useState([]);
  const [isOrderForcedChanged, setIsOrderForcedChanged] = useState(false);

  // กำหนดที่แสดงผลในการเลือกแผนก
  const departmentOptions = [
    { value: 'acc', label: 'แผนกบัญชี', icon: <Calculator size={16} className="text-blue-500" /> },
    { value: 'fin', label: 'แผนกการเงิน', icon: <DollarSign size={16} className="text-green-500" /> },
    { value: 'wh', label: 'แผนกคลังสินค้า', icon: <Warehouse size={16} className="text-amber-600" /> },
    { value: 'hr', label: 'แผนกบุคคล-ธุรการ', icon: <Users size={16} className="text-purple-500" /> },
    { value: 'it', label: 'แผนกไอที', icon: <Laptop size={16} className="text-indigo-500" /> }
  ];

  // กำหนดตัวแปร prefixMap ที่ใช้ในหลายฟังก์ชัน
  const prefixMap = {
    'acc': 'a-',
    'fin': 'f-',
    'wh': 'w-',
    'hr': 'h-',
    'it': 'i-'
  };

  // Fetch handbooks from API
  const fetchHandbooks = useCallback(async () => {
    try {
      const token = localStorage.getItem('admin_token');
      if (!token) {
        navigate('/adminlogin');
        return;
      }

      // สมมติ API endpoint สำหรับดึงข้อมูลคู่มือ
      const response = await axios.get('/handbooks', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        setHandbooks(response.data);
      }
    } catch (error) {
      console.error('Error fetching handbooks:', error);
      // กรณียังไม่มี API จริง ใช้ข้อมูลตัวอย่าง
      setHandbooks([
        { 
          id: 1, 
          name: 'คู่มือแผนกบัญชี', 
          file_url: '/guide_book/accounting.pdf',
          description: 'คู่มือการปฏิบัติงานแผนกบัญชี ระเบียบการบันทึกบัญชีและขั้นตอนการทำงานที่สำคัญ',
          department: 'acc',
          department_name: 'แผนกบัญชี'
        },
        { 
          id: 2, 
          name: 'คู่มือแผนกการเงิน', 
          file_url: '/guide_book/finance.pdf',
          description: 'ข้อปฏิบัติและแนวทางการดำเนินงานด้านการเงิน การรับจ่ายเงินและระบบควบคุมภายใน',
          department: 'fin',
          department_name: 'แผนกการเงิน'
        },
        { 
          id: 3, 
          name: 'คู่มือแผนกคลังสินค้า', 
          file_url: '/guide_book/warehouse.pdf',
          description: 'คู่มือการบริหารจัดการคลังสินค้า การจัดเก็บ การเบิกจ่าย และการตรวจนับสินค้า',
          department: 'wh',
          department_name: 'แผนกคลังสินค้า'
        },
        { 
          id: 4, 
          name: 'คู่มือแผนกบุคคล-ธุรการ', 
          file_url: '/guide_book/hr-admin.pdf',
          description: 'ระเบียบงานบุคคล สวัสดิการพนักงาน ระเบียบการลา และขั้นตอนงานธุรการต่างๆ',
          department: 'hr',
          department_name: 'แผนกบุคคล-ธุรการ'
        },
        { 
          id: 5, 
          name: 'คู่มือแผนกไอที', 
          file_url: '/guide_book/it.pdf',
          description: 'คู่มือนโยบายด้านเทคโนโลยีสารสนเทศ และแนวปฏิบัติด้านความปลอดภัยทางไซเบอร์',
          department: 'it',
          department_name: 'แผนกไอที'
        }
      ]);
    }
  }, [navigate]);

  useEffect(() => {
    fetchHandbooks();
  }, [fetchHandbooks]);

  // เพิ่ม useEffect เพื่อรีเซ็ต orderChanged เมื่อเปลี่ยนแผนก
  useEffect(() => {
    setOrderChanged(false);
    // เก็บลำดับเดิมเมื่อเลือกแผนก
    if (selectedDepartment !== 'all') {
      const currentItems = handbooks
        .filter(item => item.department === selectedDepartment)
        .map(item => ({ id: item.id, display_order: item.display_order }));
      setOriginalOrder(currentItems);
    }
  }, [selectedDepartment, handbooks]);

  // แก้ไข useEffect ที่ตรวจสอบการเปลี่ยนแปลงลำดับ
useEffect(() => {
  console.log("Effect triggered - isOrderForcedChanged:", isOrderForcedChanged, "orderChanged:", orderChanged);
  
  // ถ้าเลือกตัวกรองเป็น 'all' หรือถูกบังคับให้เปลี่ยนแล้ว ไม่ต้องตรวจสอบ
  if (selectedDepartment === 'all' || isOrderForcedChanged) {
    console.log("Skipping order check because", 
      selectedDepartment === 'all' ? "selectedDepartment is 'all'" : "isOrderForcedChanged is true");
    return;
  }
  
  // ดึงรายการปัจจุบันของแผนกที่เลือก
  const currentItems = handbooks
    .filter(item => item.department === selectedDepartment)
    .map(item => ({ id: item.id, display_order: item.display_order }));
  
  // ตรวจสอบว่ามีการเปลี่ยนแปลงลำดับหรือไม่
  if (originalOrder.length > 0) { // เพิ่มการตรวจสอบว่า originalOrder มีข้อมูลหรือไม่
    const hasOrderChanged = originalOrder.some((origItem) => {
      const currentItem = currentItems.find(item => item.id === origItem.id);
      return currentItem && currentItem.display_order !== origItem.display_order;
    });
    
    // ต้องใช้ลำดับที่กำหนดใน handleDragEnd เป็นหลัก
    // ถ้า orderChanged เป็น true อยู่แล้ว ให้คงค่าไว้
    if (!orderChanged) {
      setOrderChanged(hasOrderChanged);
    }
  }
}, [handbooks, selectedDepartment, originalOrder, orderChanged, isOrderForcedChanged]); // เพิ่ม orderChanged ใน dependencies

  const resetForm = () => {
    setEditingHandbook(null);
    setFormData({
      name: '',
      file: null,
      description: '',
      department: 'acc'
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data = new FormData();
      data.append('name', formData.name);
      data.append('description', formData.description);
      data.append('department', formData.department);
      
      // ถ้ามีการเลือกไฟล์ใหม่
      if (formData.file) {
        data.append('file', formData.file);
      }

      let response;
      if (editingHandbook) {
        // อัพเดตคู่มือที่มีอยู่
        response = await axios.put(`/handbooks/${editingHandbook.id}`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem('admin_token')}`
          }
        });
      } else {
        // เพิ่มคู่มือใหม่
        response = await axios.post('/handbooks', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem('admin_token')}`
          }
        });
      }

      if (response.status === 200 || response.status === 201) {
        fetchHandbooks();
        setIsFormVisible(false);
        resetForm();
        MySwal.fire({
          title: 'สำเร็จ!',
          text: 'บันทึกคู่มือเรียบร้อยแล้ว.',
          icon: 'success',
          confirmButtonText: 'ตกลง',
          confirmButtonColor: '#3085d6'
        });
      } else {
        throw new Error('บันทึกข้อมูลไม่สำเร็จ');
      }
    } catch (error) {
      console.error('Error saving handbook:', error);
      MySwal.fire('เกิดข้อผิดพลาด!', error.message || 'ไม่สามารถบันทึกคู่มือได้', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    MySwal.fire({
      title: 'คุณแน่ใจหรือไม่?',
      text: "การลบนี้จะเป็นการลบถาวร ไม่สามารถย้อนกลับได้!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'ใช่, ลบถาวร!',
      cancelButtonText: 'ยกเลิก'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.delete(`/handbooks/${id}`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('admin_token')}`
            }
          });
          if (response.status === 200) {
            // อัพเดทรายการคู่มือหลังลบ
            setHandbooks(handbooks.filter(item => item.id !== id));
            MySwal.fire({
              title: 'ลบถาวรแล้ว!',
              text: 'คู่มือถูกลบถาวรออกจากระบบเรียบร้อยแล้ว',
              icon: 'success',
              confirmButtonText: 'ตกลง',
              confirmButtonColor: '#3085d6'
            });
          }
        } catch (error) {
          console.error('Error deleting handbook:', error);
          MySwal.fire('เกิดข้อผิดพลาด!', 'ไม่สามารถลบคู่มือได้', 'error');
        }
      }
    });
  };

  const handleEdit = (handbook) => {
    setEditingHandbook(handbook);
    const fileName = handbook.file_url.split('/').pop(); // ดึงชื่อไฟล์จาก URL
    
    setFormData({
      name: handbook.name,
      file: null,
      description: handbook.description,
      department: handbook.department,
      fileName: fileName, // เก็บชื่อไฟล์เพื่อแสดงในฟอร์ม
    });
    setIsFormVisible(true);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFormData({ ...formData, file: selectedFile });
    }
  };

  const handleDownload = (fileUrl, fileName) => {
    // ตรวจสอบว่าไฟล์มีอยู่จริง
    axios.head(fileUrl)
      .then(() => {
        // เปิดไฟล์ในแท็บใหม่ (วิธีนี้ใช้ได้ดีกับทุกประเภทไฟล์)
        window.open(fileUrl, '_blank');
      })
      .catch((error) => {
        console.error("Error checking file:", error);
        MySwal.fire({
          title: 'ไม่พบไฟล์',
          text: 'ไม่สามารถเข้าถึงไฟล์ได้ กรุณาอัพโหลดไฟล์ใหม่',
          icon: 'error',
          confirmButtonText: 'ตกลง',
          confirmButtonColor: '#3085d6'
        });
      });
  };

  // Replace the getFileIcon function with:
const getFileIcon = (fileUrl) => {
  if (!fileUrl) return <File className="h-5 w-5 text-gray-500" />;
  
  const ext = fileUrl.split('.').pop().toLowerCase();
  
  // เช็คประเภทไฟล์จากนามสกุล
  switch (ext) {
    case 'pdf':
      return <File className="h-5 w-5 text-red-500" />;
    case 'doc':
    case 'docx':
      return <FileText className="h-5 w-5 text-blue-600" />;
    case 'xls':
    case 'xlsx':
      return <FileSpreadsheet className="h-5 w-5 text-green-600" />;
    case 'ppt':
    case 'pptx':
      return <File className="h-5 w-5 text-orange-500" />;
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'bmp':
    case 'webp':
    case 'svg':
      return <Image className="h-5 w-5 text-purple-500" />;
    default:
      return <File className="h-5 w-5 text-gray-500" />;
  }
};

  // ฟังก์ชันสำหรับจัดการ drag end
const handleDragEnd = (result) => {
  if (!result.destination) return;
  
  const allItems = Array.from(sortedHandbooks);
  const sourceIndex = result.source.index;
  const destIndex = result.destination.index;
  
  if (sourceIndex === destIndex) return;
  
  const [removed] = allItems.splice(sourceIndex, 1);
  allItems.splice(destIndex, 0, removed);
  
  // อัพเดต sortedHandbooks ทันที
  setSortedHandbooks(allItems);
  
  const departmentItems = allItems
    .filter(item => item.department === selectedDepartment);
  
  const updatedDeptItems = departmentItems.map((item, idx) => {
    const prefix = prefixMap[item.department] || 'o-';
    const newOrder = `${prefix}${idx + 1}`; 
    return { ...item, display_order: newOrder };
  });
  
  const newItems = [
    ...handbooks.filter(item => item.department !== selectedDepartment), 
    ...updatedDeptItems
  ];
  
  // เก็บค่า newItems ไว้ในตัวแปรชั่วคราว
  const updatedHandbooks = newItems;
  
  // สำคัญ: ตั้งค่า isOrderForcedChanged ก่อน เพื่อให้ useEffect รู้ว่าเราเปลี่ยนแปลงลำดับแล้ว
  setIsOrderForcedChanged(true);
  
  // สำคัญ: ใช้วิธีอัพเดต state พร้อมกัน
  setHandbooks(updatedHandbooks);
  // สำคัญมาก: ล็อกค่า orderChanged ให้เป็น true
  setOrderChanged(true);
  
  // สร้าง localStorage เพื่อจำค่า orderChanged
  localStorage.setItem('handbook_order_changed', 'true');
  
  console.log("Drag completed. New order:", updatedDeptItems.map(i => `${i.id}: ${i.display_order}`));
};

  useEffect(() => {
    // ย้ายฟังก์ชันเข้ามาใน useEffect
    const getFilteredHandbooks = () => {
      return [...handbooks] // ใช้ shallow copy
        .filter((handbook) => {
          // กรองตามคำค้นหา
          const matchSearch = handbook.name.toLowerCase().includes(searchTerm.toLowerCase()) || 
                            handbook.department_name?.toLowerCase().includes(searchTerm.toLowerCase());
          
          // กรองตามแผนก
          const matchDept = selectedDepartment === 'all' || handbook.department === selectedDepartment;
          
          return matchSearch && matchDept;
        })
        .sort((a, b) => {
          // เรียงลำดับตามแผนกก่อน
          if (a.department !== b.department) {
            return a.department.localeCompare(b.department);
          }
          
          // แปลงรูปแบบ display_order (เช่น f-1) เป็นตัวเลข เพื่อเรียงลำดับ
          const aOrderStr = a.display_order ? a.display_order.split('-')[1] : '0';
          const bOrderStr = b.display_order ? b.display_order.split('-')[1] : '0';
          const aOrder = parseInt(aOrderStr) || 0;
          const bOrder = parseInt(bOrderStr) || 0;
          
          return aOrder - bOrder; // เรียงจากน้อยไปมาก (เลขน้อยอยู่บน)
        });
    };

    const filtered = getFilteredHandbooks();
    setSortedHandbooks(filtered);
  }, [handbooks, searchTerm, selectedDepartment]);

  // ฟังก์ชันบันทึกการเรียงลำดับ
  const saveDisplayOrder = async () => {
    try {
      setIsLoading(true);
      // เมื่อบันทึกสำเร็จ ลบค่าใน localStorage
      localStorage.removeItem('handbook_order_changed');
      
      // สำคัญ: ใช้ sortedHandbooks แทน handbooks ในการคำนวณ
      const departmentItems = sortedHandbooks
        .filter(item => item.department === selectedDepartment);

      // เตรียมข้อมูลที่จะส่งไป API - กำหนดลำดับใหม่ก่อนส่ง
      const itemsToUpdate = departmentItems.map((item, index) => {
        const prefix = prefixMap[item.department] || 'o-';
        
        // สร้าง order ใหม่แทนที่จะใช้ค่าที่มีอยู่
        const newOrder = `${prefix}${index + 1}`;
        
        return {
          id: item.id,
          department: item.department,
          order: newOrder
        };
      });

      console.log("Data sending to API:", itemsToUpdate);
        
      try {
        const response = await axios.put('/handbooks/reorder', {
          items: itemsToUpdate
        }, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('admin_token')}`
          }
        });
        
        console.log("API response:", response);
        
        if (response.status === 200) {
          // สำคัญ: อัพเดตข้อมูล handbooks ให้สอดคล้องกับการจัดเรียงใหม่
          const updatedHandbooks = handbooks.map(handbook => {
            const updated = itemsToUpdate.find(item => item.id === handbook.id);
            if (updated) {
              return {
                ...handbook,
                display_order: updated.order
              };
            }
            return handbook;
          });
          
          // บันทึกค่าลำดับใหม่
          setOriginalOrder(itemsToUpdate.map(item => ({
            id: item.id,
            display_order: item.order
          })));
          
          // อัพเดตทั้ง handbooks และ sortedHandbooks
          setHandbooks(updatedHandbooks);
          // ไม่ต้อง setSortedHandbooks ตรงนี้ เพราะ useEffect จะทำให้อัตโนมัติ
          
          // รีเซ็ต orderChanged
          setOrderChanged(false);
          // รีเซ็ต isOrderForcedChanged ด้วย
          setIsOrderForcedChanged(false);
          
          MySwal.fire({
            title: 'สำเร็จ!',
            text: 'บันทึกลำดับการแสดงผลเรียบร้อยแล้ว',
            icon: 'success',
            confirmButtonText: 'ตกลง',
            confirmButtonColor: '#3085d6'
          });
        }
      } catch (apiError) {
        console.error('API Error:', apiError);
        
        // Mock สำหรับทดสอบเมื่อไม่มี API
        console.log("Using mock success response");
        
        // สำคัญ: ทำเหมือนมีการส่งข้อมูลไปที่ backend จริงๆ
        // อัพเดตข้อมูลในหน้าเว็บให้ตรงกับที่ควรจะส่งไป API
        const updatedHandbooks = handbooks.map(handbook => {
          const updated = itemsToUpdate.find(item => item.id === handbook.id);
          if (updated) {
            return {
              ...handbook,
              display_order: updated.order
            };
          }
          return handbook;
        });
        
        // บันทึกค่าลำดับใหม่เป็น originalOrder
        setOriginalOrder(itemsToUpdate.map(item => ({
          id: item.id,
          display_order: item.order
        })));
        
        // อัพเดต state
        setHandbooks(updatedHandbooks);
        setOrderChanged(false);
        
        MySwal.fire({
          title: 'สำเร็จ!',
          text: 'บันทึกลำดับการแสดงผลเรียบร้อยแล้ว (mock)',
          icon: 'success',
          confirmButtonText: 'ตกลง',
          confirmButtonColor: '#3085d6'
        });
      }
    } catch (error) {
      console.error('Error saving display order:', error);
      MySwal.fire('เกิดข้อผิดพลาด!', 'ไม่สามารถบันทึกลำดับการแสดงผลได้', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  // กรองรายการคู่มือตามคำค้นหาและแผนก
  const filteredHandbooks = handbooks.filter((handbook) => {
    // กรองตามคำค้นหา
    const matchSearch = handbook.name.toLowerCase().includes(searchTerm.toLowerCase()) || 
                      handbook.department_name?.toLowerCase().includes(searchTerm.toLowerCase());
    
    // กรองตามแผนก
    const matchDept = selectedDepartment === 'all' || handbook.department === selectedDepartment;
    
    return matchSearch && matchDept;
  }).sort((a, b) => {
    // เรียงลำดับตามแผนกก่อน
    if (a.department !== b.department) {
      return a.department.localeCompare(b.department);
    }
    
    // แปลงรูปแบบ display_order (เช่น f-1) เป็นตัวเลข เพื่อเรียงลำดับ
    // โดยเลขมากจะแสดงอยู่ล่างสุดของแผนก
    const aOrderStr = a.display_order ? a.display_order.split('-')[1] : '0';
    const bOrderStr = b.display_order ? b.display_order.split('-')[1] : '0';
    const aOrder = parseInt(aOrderStr) || 0;
    const bOrder = parseInt(bOrderStr) || 0;
    
    return aOrder - bOrder; // เรียงจากน้อยไปมาก (เลขน้อยอยู่บน)
  });

  useEffect(() => {
    console.log("Current orderChanged state:", orderChanged);
  }, [orderChanged]);

  // เพิ่ม useEffect เพื่อ log การเปลี่ยนแปลงค่า handbooks
useEffect(() => {
  console.log("Handbooks updated:", 
    handbooks.filter(h => h.department === selectedDepartment)
      .map(h => `${h.id}: ${h.display_order}`)
  );
}, [handbooks, selectedDepartment]);

useEffect(() => {
  console.log("isOrderForcedChanged updated:", isOrderForcedChanged);
}, [isOrderForcedChanged]);

useEffect(() => {
  console.log("Current orderChanged state:", orderChanged);
}, [orderChanged]);

  // แก้ไขเฉพาะส่วน return JSX โดยไม่กระทบโครงสร้างเดิม
return (
  <div className="p-4 sm:p-8 bg-gray-50 min-h-screen">
    {/* ส่วนหัวหน้าและปุ่มย้อนกลับ - ปรับสำหรับมือถือ */}
    <div className="flex justify-end items-center">
      <button
        onClick={() => navigate('/admin/dashboard/data_management')}
        className="flex gap-1 sm:gap-2 bg-none text-gray-600 px-3 py-2 sm:px-4 sm:py-2 mb-3 sm:mb-5 rounded-md hover:text-gray-800 transition-colors"
      >
        <ArrowLeft className="h-4 w-4 sm:h-5 sm:w-5" />
        <span className="text-sm sm:text-base">ย้อนกลับ</span>
      </button>
    </div>

    {/* หัวข้อหลัก - ปรับให้เหมาะกับมือถือ */}
    <div className="mb-6 sm:mb-8 flex justify-center items-center">
      <div className="text-center">
        <h1 className="text-2xl sm:text-3xl font-bold text-gray-800">จัดการคู่มือพนักงาน</h1>
        <p className="text-sm sm:text-base text-gray-600 mt-2">เพิ่ม ลบ และแก้ไขคู่มือพนักงานของแต่ละแผนก</p>
      </div>
    </div>

    {/* ลบ Card แรกที่ซ้ำซ้อนออกและคงเหลือไว้ Card เดียว */}
    <Card className="p-4 sm:p-6 mb-4 sm:mb-6">
      {/* แถบค้นหาและเลือกแผนก - ปรับสำหรับมือถือ */}
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 gap-3 sm:gap-4">
        <div className="flex flex-col sm:flex-row items-start sm:items-center gap-3 sm:gap-4 w-full">
          <div className="relative w-full sm:w-64">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4 sm:h-5 sm:w-5" />
            <input
              type="text"
              placeholder="ค้นหาคู่มือ..."
              className="pl-10 pr-4 py-2 w-full border rounded-md text-sm sm:text-base focus:ring-blue-500 focus:border-blue-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          
          {/* ตัวเลือกแผนก - ปรับให้เหมาะกับมือถือ */}
          <div className="flex items-center gap-2 w-full sm:w-auto">
            <Filter size={16} className="text-gray-500 flex-shrink-0" />
            <select
              className="border rounded-md px-3 py-2 text-sm w-full bg-white"
              value={selectedDepartment}
              onChange={(e) => setSelectedDepartment(e.target.value)}
            >
              <option value="all">ทุกแผนก</option>
              {departmentOptions.map(dept => (
                <option key={dept.value} value={dept.value}>
                  {dept.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        
        {/* ปุ่มบันทึกลำดับและเพิ่มคู่มือ - ปรับให้ใหญ่ขึ้นบนมือถือ */}
        <div className="flex items-center gap-2 w-full sm:w-auto mt-3 sm:mt-0">
          {selectedDepartment !== 'all' && (
            <button
              onClick={saveDisplayOrder}
              disabled={isLoading || !(localStorage.getItem('handbook_order_changed') === 'true')}
              className={`flex items-center justify-center gap-1 bg-green-600 text-white px-3 py-2 sm:px-4 sm:py-2 rounded-md hover:bg-green-700 transition-colors text-xs sm:text-sm flex-1 sm:flex-initial ${
                isLoading || !(localStorage.getItem('handbook_order_changed') === 'true') 
                  ? 'opacity-50 cursor-not-allowed' 
                  : ''
              }`}
            >
              {isLoading ? (
                <>
                  <div className="animate-spin rounded-full h-3 w-3 border-2 border-white border-t-transparent mr-1"></div>
                  <span className="hidden sm:inline">กำลังบันทึก...</span>
                  <span className="sm:hidden">บันทึก...</span>
                </>
              ) : (
                <>
                  <span className="hidden sm:inline">บันทึกการเรียงลำดับ</span>
                  <span className="sm:hidden">บันทึกลำดับ</span>
                </>
              )}
            </button>
          )}
          
          <button
            onClick={() => {
              resetForm();
              setIsFormVisible(true);
            }}
            className="flex items-center justify-center gap-1 bg-blue-600 text-white px-3 py-2 sm:px-4 sm:py-2 rounded-md hover:bg-blue-700 transition-colors text-xs sm:text-sm flex-1 sm:flex-initial"
          >
            <Plus className="h-4 w-4 sm:h-5 sm:w-5" />
            <span className="hidden sm:inline">เพิ่ม</span>
            <span className="sm:hidden">เพิ่ม</span>
          </button>
        </div>
      </div>

      {/* ฟอร์มเพิ่ม/แก้ไขคู่มือ - ปรับปรุงฟอร์มให้ใช้งานง่ายบนมือถือ */}
      {isFormVisible && (
        <form onSubmit={handleSubmit} className="mb-4 border p-3 sm:p-4 rounded-md text-start">
          {/* หัวข้อฟอร์ม */}
          <div className="text-sm sm:text-base font-medium text-gray-700 mb-3 pb-2 border-b">
            {editingHandbook ? 'แก้ไขคู่มือ' : 'เพิ่มคู่มือใหม่'}
          </div>
          
          <div className="mb-3 sm:mb-4">
            <label className="block text-xs sm:text-sm font-medium text-gray-700 mb-1">ชื่อคู่มือ</label>
            <input
              type="text"
              className="w-full border rounded-md px-3 py-2 text-sm"
              placeholder="ระบุชื่อคู่มือ..."
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              required
            />
          </div>
          
          <div className="mb-3 sm:mb-4">
            <label className="block text-xs sm:text-sm font-medium text-gray-700 mb-1">แผนก</label>
            <select
              className="w-full border rounded-md px-3 py-2 text-sm mb-2"
              value={formData.department}
              onChange={(e) => setFormData({ ...formData, department: e.target.value })}
              required
            >
              {departmentOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <div className="mt-2 flex flex-wrap gap-2">
              {departmentOptions.map(dept => (
                <div
                  key={dept.value}
                  onClick={() => setFormData({ ...formData, department: dept.value })}
                  className={`flex items-center gap-1 cursor-pointer px-2 py-1 sm:px-3 sm:py-1.5 rounded-full text-xs sm:text-sm
                    ${formData.department === dept.value 
                      ? 'bg-blue-100 text-blue-800 border-blue-200' 
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200 border-gray-200'} 
                    border transition-colors`}
                >
                  {dept.icon}
                  <span>{dept.label}</span>
                </div>
              ))}
            </div>
          </div>
          
          <div className="mb-3 sm:mb-4">
            <label className="block text-xs sm:text-sm font-medium text-gray-700 mb-1">คำอธิบาย</label>
            <textarea
              className="w-full border rounded-md px-3 py-2 text-sm"
              placeholder="รายละเอียดคู่มือ..."
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              required
              rows={3}
            />
          </div>
          
          <div className="mb-3 sm:mb-4">
            <label className="block text-xs sm:text-sm font-medium text-gray-700 mb-1">
              ไฟล์คู่มือ <span className="text-xs text-gray-500">(PDF, Word, Excel, PowerPoint, รูปภาพ)</span>
            </label>
            <div className="border rounded-md px-3 py-2">
              <input
                type="file"
                id="file-upload"
                accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.jpg,.jpeg,.png,.gif,.bmp,.webp,.svg"
                className="hidden"
                onChange={handleFileChange}
                required={!editingHandbook}
              />
              <label htmlFor="file-upload" className="flex items-center gap-2 cursor-pointer">
                <Upload className="h-4 w-4 sm:h-5 sm:w-5 text-gray-600" />
                <span className="text-xs sm:text-sm text-gray-600 truncate">
                  {formData.file ? formData.file.name : (
                    editingHandbook ? (
                      <span>
                        อัพโหลดไฟล์ใหม่ (ไม่บังคับ) - <span className="text-blue-600 max-w-[150px] sm:max-w-none truncate inline-block align-bottom">{formData.fileName || 'ไฟล์เดิม'}</span>
                      </span>
                    ) : (
                      "อัพโหลดไฟล์เอกสาร..."
                    )
                  )}
                </span>
              </label>
            </div>
          </div>
          
          <div className="flex gap-3 mt-4">
            <button
              type="submit"
              disabled={isLoading}
              className={`bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700 transition-colors flex items-center justify-center flex-1 text-sm ${isLoading ? 'opacity-70' : ''}`}
            >
              {isLoading ? (
                <>
                  <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent mr-2"></div>
                  <span>กำลังบันทึก...</span>
                </>
              ) : (
                'บันทึก'
              )}
            </button>
            <button
              type="button"
              onClick={() => {
                setIsFormVisible(false);
                resetForm();
              }}
              className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-400 transition-colors flex-1 text-sm"
            >
              ยกเลิก
            </button>
          </div>
        </form>
      )}
    </Card>

    {/* แสดงรายการคู่มือ - ปรับปรุงการแสดงผลบนมือถือ */}
    <Card className="p-3 sm:p-6">
      {/* เพิ่มแท็บเลือกแผนกบนมือถือ */}
      <div className="flex flex-wrap items-center justify-between gap-2 mb-4">
        <p className="text-xs sm:text-sm text-gray-500">
          <span className="font-medium">คำแนะนำ:</span> 
          {selectedDepartment !== 'all' ? (
            <>
              <span className="hidden sm:inline"> สามารถลากแถวสลับขึ้น-ลง เพื่อเรียงลำดับได้</span>
              <span className="sm:hidden"> ลากเพื่อเรียงลำดับได้</span>
            </>
          ) : (
            " กดที่แผนกเพื่อดูเฉพาะคู่มือในแผนกนั้น"
          )}
        </p>

        {/* แสดงแท็บแผนกบนมือถือเพื่อสะดวกในการเลือก */}
        <div className="flex overflow-x-auto gap-1 py-1 w-full sm:hidden">
          <button
            onClick={() => setSelectedDepartment('all')}
            className={`whitespace-nowrap px-3 py-1 rounded-full text-xs ${
              selectedDepartment === 'all' 
                ? 'bg-gray-800 text-white' 
                : 'bg-gray-100 text-gray-700'
            }`}
          >
            ทั้งหมด
          </button>
          {departmentOptions.map(dept => (
            <button
              key={`tab-${dept.value}`}
              onClick={() => setSelectedDepartment(dept.value)}
              className={`flex items-center gap-1 whitespace-nowrap px-3 py-1 rounded-full text-xs ${
                selectedDepartment === dept.value 
                  ? 'bg-blue-100 text-blue-800' 
                  : 'bg-gray-100 text-gray-700'
              }`}
            >
              {dept.icon}
              <span>{dept.label}</span>
            </button>
          ))}
        </div>
      </div>
      
      {isLoading && !filteredHandbooks.length ? (
        <div className="px-6 py-12 sm:py-16 text-center">
          <div className="inline-block animate-spin rounded-full h-7 w-7 sm:h-8 sm:w-8 border-4 border-t-blue-500 border-gray-200 mb-3 sm:mb-4"></div>
          <p className="text-sm sm:text-base text-gray-500">กำลังโหลดข้อมูล...</p>
        </div>
      ) : filteredHandbooks.length === 0 ? (
        <div className="px-6 py-12 text-center">
          <div className="inline-flex justify-center items-center rounded-full bg-gray-100 p-4 mb-4">
            <File className="h-6 w-6 text-gray-400" />
          </div>
          <p className="text-sm sm:text-base text-gray-500">ไม่มีคู่มือ</p>
          <button
            onClick={() => {
              resetForm();
              setIsFormVisible(true);
            }}
            className="mt-4 inline-flex items-center justify-center gap-1 bg-blue-600 text-white px-3 py-1.5 rounded-md hover:bg-blue-700 transition-colors text-xs sm:text-sm"
          >
            <Plus className="h-3 w-3 sm:h-4 sm:w-4" />
            <span>เพิ่มคู่มือใหม่</span>
          </button>
        </div>
      ) : (
        <>
          {/* Desktop View - คงเดิมตามโค้ดเดิม */}
          <div className="hidden sm:block overflow-x-auto">
            <div className="min-w-full inline-block align-middle">
              <div className="overflow-hidden">
                {/* ส่วนหัวตาราง */}
                <div className="flex bg-gray-50 border-b">
                  {selectedDepartment !== 'all' && (
                    <div className="w-[10%] px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      ลำดับ
                    </div>
                  )}
                  <div className={`${selectedDepartment !== 'all' ? 'w-[35%]' : 'w-[40%]'} px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`}>
                    ชื่อคู่มือ
                  </div>
                  <div className="w-[20%] px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    แผนก
                  </div>
                  <div className={`${selectedDepartment !== 'all' ? 'w-[35%]' : 'w-[40%]'} px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider`}>
                    จัดการ
                  </div>
                </div>
                
                {/* เนื้อหาตาราง - ใช้โค้ดเดิม */}
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="handbooks" type="HANDBOOK">
                    {(provided) => (
                      <div 
                        className="bg-white"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {sortedHandbooks.map((handbook, index) => (
                          <Draggable 
                            key={handbook.id}
                            draggableId={handbook.id.toString()}
                            index={index}
                            isDragDisabled={selectedDepartment === 'all'}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps} 
                                className={`flex items-center border-b py-3 hover:bg-gray-50 ${
                                  snapshot.isDragging ? 'bg-blue-50' : ''
                                } ${selectedDepartment === 'all' ? '' : 'cursor-grab active:cursor-grabbing'}`}
                              >
                                {selectedDepartment !== 'all' && (
                                  <div className="w-[10%] px-3 py-4 flex items-center justify-center">
                                    <div className="p-1 hover:bg-gray-100 rounded">
                                      <GripVertical className="h-5 w-5 text-gray-400" />
                                    </div>
                                    <span className="ml-2 text-gray-500 font-medium text-sm">
                                      {handbook.display_order || `${prefixMap[handbook.department] || 'o-'}${index + 1}`}
                                    </span>
                                  </div>
                                )}
                                
                                <div className={`${selectedDepartment !== 'all' ? 'w-[35%]' : 'w-[40%]'} px-6 py-4`}>
                                  <div className="flex items-center">
                                    {getFileIcon(handbook.file_url)}
                                    <div className="ml-2">
                                      <div className="text-sm text-left font-medium text-gray-800">{handbook.name}</div>
                                      <p className="text-xs text-gray-500 mt-1 line-clamp-2">{handbook.description}</p>
                                    </div>
                                  </div>
                                </div>
                                
                                <div className="w-[20%] px-6 py-4 whitespace-nowrap text-center">
                                  <span className="px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                                    {handbook.department_name}
                                  </span>
                                </div>
                                
                                <div className={`${selectedDepartment !== 'all' ? 'w-[35%]' : 'w-[40%]'} px-6 py-4 whitespace-nowrap flex justify-center space-x-4`}>
                                  <button
                                    onClick={() => handleDownload(handbook.file_url, `${handbook.name}.pdf`)}
                                    className="text-blue-600 hover:text-blue-900 p-1 rounded-full hover:bg-blue-50"
                                    title="ดาวน์โหลด"
                                  >
                                    <Download className="h-5 w-5" />
                                  </button>
                                  
                                  <button
                                    onClick={() => handleEdit(handbook)}
                                    className="text-blue-600 hover:text-blue-900 p-1 rounded-full hover:bg-blue-50"
                                    title="แก้ไข"
                                  >
                                    <Edit2 className="h-5 w-5" />
                                  </button>
                                  
                                  <button
                                    onClick={() => handleDelete(handbook.id)}
                                    className="text-red-600 hover:text-red-900 p-1 rounded-full hover:bg-red-50"
                                    title="ลบ"
                                  >
                                    <Trash2 className="h-5 w-5" />
                                  </button>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>

          {/* Mobile View - ปรับปรุงใหม่ให้ใช้งานได้สะดวกบนมือถือ */}
          <div className="sm:hidden">
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="mobile-handbooks" type="MOBILE_HANDBOOK">
                {(provided) => (
                  <div 
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="space-y-3"
                  >
                    {sortedHandbooks.map((handbook, index) => (
                      <Draggable 
                        key={`mobile-${handbook.id}`}
                        draggableId={`mobile-${handbook.id}`}
                        index={index}
                        isDragDisabled={selectedDepartment === 'all'}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className={`border rounded-lg overflow-hidden shadow-sm ${
                              snapshot.isDragging ? 'bg-gray-50 shadow-md' : 'bg-white'
                            }`}
                          >
                            {/* ปรับปรุงแถบส่วนหัวการ์ดให้ดูดีขึ้น */}
                            <div className="p-3 bg-gray-50 border-b flex justify-between items-center">
                              <div className="flex items-center gap-2">
                                {departmentOptions.find(dept => dept.value === handbook.department)?.icon || 
                                  <File className="h-4 w-4 text-gray-500" />}
                                <span className="px-2 py-0.5 text-xs font-medium rounded-full bg-blue-100 text-blue-800">
                                  {handbook.department_name}
                                </span>
                                {selectedDepartment !== 'all' && (
                                  <span className="text-xs text-gray-500 ml-1">
                                    ลำดับที่ {handbook.display_order?.split('-')[1] || index + 1}
                                  </span>
                                )}
                              </div>
                              
                              {/* แสดงปุ่มลากเฉพาะเมื่อเลือกแผนก */}
                              {selectedDepartment !== 'all' && (
                                <div 
                                  {...provided.dragHandleProps} 
                                  className="cursor-grab active:cursor-grabbing p-1 rounded-md hover:bg-gray-200"
                                  title="ลากเพื่อเรียงลำดับ"
                                >
                                  <GripVertical className="h-4 w-4 text-gray-500" />
                                </div>
                              )}
                            </div>
                            
                            {/* เนื้อหาการ์ด - ปรับปรุงให้มีข้อมูลครบถ้วนและชัดเจน */}
                            <div className="p-3">
                              <div className="flex items-start">
                                <div className="w-8 h-8 bg-gray-100 rounded overflow-hidden flex-shrink-0 mr-3 flex items-center justify-center">
                                  {getFileIcon(handbook.file_url)}
                                </div>
                                <div className="flex-1">
                                  <h3 className="font-medium text-gray-800 text-sm leading-tight mb-1">
                                    {handbook.name}
                                  </h3>
                                  <p className="text-xs text-gray-600 line-clamp-2 leading-relaxed">
                                    {handbook.description}
                                  </p>
                                </div>
                              </div>
                            </div>
                            
                            {/* แถบปุ่มดำเนินการ - ทำให้ปุ่มใหญ่ขึ้นเพื่อง่ายต่อการใช้งาน */}
                            <div className="p-2 bg-gray-50 border-t grid grid-cols-3 gap-2">
                              <button
                                onClick={() => handleDownload(handbook.file_url, `${handbook.name}.pdf`)}
                                className="flex items-center justify-center gap-1 bg-blue-50 text-blue-600 hover:bg-blue-100 px-3 py-1.5 rounded-md text-xs"
                              >
                                <Download className="h-3 w-3" />
                                <span>ดาวน์โหลด</span>
                              </button>
                              <button
                                onClick={() => handleEdit(handbook)}
                                className="flex items-center justify-center gap-1 bg-blue-50 text-blue-600 hover:bg-blue-100 px-3 py-1.5 rounded-md text-xs"
                              >
                                <Edit2 className="h-3 w-3" />
                                <span>แก้ไข</span>
                              </button>
                              <button
                                onClick={() => handleDelete(handbook.id)}
                                className="flex items-center justify-center gap-1 bg-red-50 text-red-600 hover:bg-red-100 px-3 py-1.5 rounded-md text-xs"
                              >
                                <Trash2 className="h-3 w-3" />
                                <span>ลบ</span>
                              </button>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </>
      )}
    </Card>
  </div>
);
};

export default HandbookManagement;