import React, { useState, useEffect, useCallback } from "react";
import { Card } from "../ui/card";
import {
  Plus,
  Search,
  Edit2,
  Trash2,
  ArrowLeft,
  MoveVertical,
  HelpCircle,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

const MySwal = withReactContent(Swal);

const FaqManagement = () => {
  const [faqs, setFaqs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [editingFaq, setEditingFaq] = useState(null);
  const [formData, setFormData] = useState({
    question: "",
    answer: "",
    active: true,  // เปลี่ยนจาก is_active เป็น active
  });
  const [isOrderChanged, setIsOrderChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Fetch FAQs from API
  const fetchFaqs = useCallback(async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('admin_token');
      
      if (!token) {
        throw new Error('ไม่พบข้อมูล Admin Token');
      }
      
      const headers = { Authorization: `Bearer ${token}` };
      
      const response = await axios.get('/admin/faqs', { headers });
      // เรียงลำดับจากมากไปน้อย (เปลี่ยนจาก a - b เป็น b - a)
      const sortedFaqs = [...response.data].sort((a, b) => b.order_position - a.order_position);
      
      setFaqs(sortedFaqs);
    } catch (error) {
      console.error('Error fetching FAQs:', error);
      
      if (error.response?.status === 401 || error.response?.status === 403 || 
          error.message === 'ไม่พบข้อมูล Admin Token') {
        MySwal.fire({
          title: 'กรุณาเข้าสู่ระบบ',
          text: 'คุณไม่ได้เข้าสู่ระบบหรือ session หมดอายุ',
          icon: 'warning',
          confirmButtonText: 'เข้าสู่ระบบ',
          confirmButtonColor: '#3085d6'
        }).then(() => {
          navigate('/admin/login');
        });
      } else {
        MySwal.fire({
          title: 'เกิดข้อผิดพลาด!',
          text: 'ไม่สามารถโหลดข้อมูล FAQ ได้',
          icon: 'error',
          confirmButtonText: 'ตกลง',
          confirmButtonColor: '#3085d6'
        });
      }
    } finally {
      setLoading(false);
    }
  }, [navigate]);

  // โหลดข้อมูลเมื่อคอมโพเนนต์ถูกโหลด
  useEffect(() => {
    const adminToken = localStorage.getItem('admin_token');
    if (!adminToken) {
      MySwal.fire({
        title: 'ไม่มีสิทธิ์เข้าถึง!',
        text: 'กรุณาเข้าสู่ระบบผู้ดูแลระบบก่อนใช้งานส่วนนี้',
        icon: 'error',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#3085d6'
      }).then(() => {
        navigate('/admin/login');
      });
      return;
    }
    
    fetchFaqs();
  }, [fetchFaqs, navigate]);

  // ฟังก์ชันรีเซ็ตฟอร์ม
  const resetForm = () => {
    setFormData({
      question: "",
      answer: "",
      active: true,  // เปลี่ยนจาก is_active เป็น active
    });
    setEditingFaq(null);
  };

  // ฟังก์ชันสำหรับการส่งฟอร์ม (เพิ่มหรือแก้ไข FAQ)
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      if (!formData.question.trim() || !formData.answer.trim()) {
        throw new Error('กรุณากรอกคำถามและคำตอบ');
      }

      const token = localStorage.getItem('admin_token');
      if (!token) {
        MySwal.fire({
          title: 'Session หมดอายุ',
          text: 'กรุณาเข้าสู่ระบบใหม่',
          icon: 'warning',
          confirmButtonText: 'เข้าสู่ระบบ',
          confirmButtonColor: '#3085d6'
        }).then(() => {
          navigate('/admin/login');
        });
        return;
      }

      const headers = { Authorization: `Bearer ${token}` };
      let response;

      if (editingFaq) {
        // แก้ไข FAQ
        response = await axios.put(
          `/admin/faqs/${editingFaq.id}`,
          formData,
          { headers }
        );
      } else {
        // เพิ่ม FAQ ใหม่
        response = await axios.post('/admin/faqs', formData, { headers });
      }

      if (response.status === 200 || response.status === 201) {
        await fetchFaqs();
        setIsFormVisible(false);
        resetForm();

        MySwal.fire({
          title: 'สำเร็จ!',
          text: editingFaq ? 'อัปเดต FAQ เรียบร้อยแล้ว' : 'เพิ่ม FAQ เรียบร้อยแล้ว',
          icon: 'success',
          confirmButtonText: 'ตกลง',
          confirmButtonColor: '#3085d6'
        });
      }
    } catch (error) {
      console.error('Error saving FAQ:', error);
      
      let errorMessage = 'เกิดข้อผิดพลาดในการบันทึกข้อมูล';
      if (error.response?.data?.error) {
        errorMessage = error.response.data.error;
      } else if (error.message) {
        errorMessage = error.message;
      }

      MySwal.fire({
        title: 'เกิดข้อผิดพลาด!',
        text: errorMessage,
        icon: 'error',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#3085d6'
      });
    } finally {
      setLoading(false);
    }
  };

  // ฟังก์ชันสำหรับเปิดฟอร์มแก้ไข
  const handleEdit = (faq) => {
    setEditingFaq(faq);
    setFormData({
      question: faq.question,
      answer: faq.answer,
      active: faq.active || faq.is_active === 1, // รองรับทั้งชื่อ active และ is_active
    });
    setIsFormVisible(true);
    window.scrollTo(0, 0);
  };

  // ฟังก์ชันสำหรับลบ FAQ
const handleDelete = async (id) => {
  MySwal.fire({
    title: 'คุณแน่ใจหรือไม่?',
    text: 'คุณจะไม่สามารถย้อนกลับได้หลังจากลบ!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'ใช่, ลบเลย!',
    cancelButtonText: 'ยกเลิก'
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        setLoading(true);
        const token = localStorage.getItem('admin_token');
        if (!token) {
          throw new Error('ไม่พบข้อมูล Admin Token');
        }
        
        // ใช้ DELETE เพื่อลบข้อมูลออกจากฐานข้อมูลจริงๆ
        const response = await axios.delete(
          `/admin/faqs/${id}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        
        if (response.status === 200) {
          // เรียก fetchFaqs แบบ await เพื่อให้แน่ใจว่าข้อมูลถูกโหลดใหม่
          await fetchFaqs();
          MySwal.fire({
            title: 'ลบแล้ว!',
            text: 'FAQ ถูกลบเรียบร้อยแล้ว',
            icon: 'success',
            confirmButtonText: 'ตกลง',
            confirmButtonColor: '#3085d6'
          });
        }
      } catch (error) {
        console.error('Error deleting FAQ:', error);
        
        if (error.response?.status === 401 || error.response?.status === 403) {
          MySwal.fire({
            title: 'Session หมดอายุ',
            text: 'กรุณาเข้าสู่ระบบใหม่',
            icon: 'warning',
            confirmButtonText: 'เข้าสู่ระบบ',
            confirmButtonColor: '#3085d6'
          }).then(() => {
            navigate('/admin/login');
          });
        } else {
          MySwal.fire({
            title: 'เกิดข้อผิดพลาด!', 
            text: `ไม่สามารถลบ FAQ ได้: ${error.response?.data?.error || error.message}`,
            icon: 'error',
            confirmButtonText: 'ตกลง',
            confirmButtonColor: '#3085d6'
          });
        }
      } finally {
        setLoading(false);
      }
    }
  });
};

  // ฟังก์ชันสำหรับจัดการเมื่อลากและวาง (drag & drop)
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(faqs);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // อัปเดตลำดับ order_position ตามลำดับใหม่ (จากมากไปน้อย)
    const updatedItems = items.map((item, index) => ({
      ...item,
      order_position: items.length - index - 1 // สร้างลำดับจากมากไปน้อย
    }));

    setFaqs(updatedItems);
    setIsOrderChanged(true);
  };

  // แก้ไขฟังก์ชัน handleSaveOrder

const handleSaveOrder = async () => {
  try {
    setLoading(true);
    const token = localStorage.getItem('admin_token');
    if (!token) {
      throw new Error('ไม่พบข้อมูล Admin Token');
    }
    
    // ส่งข้อมูลลำดับใหม่ไปยัง API (จากมากไปน้อย)
    const orderData = faqs.map((item, index) => ({
      id: item.id,
      order_position: faqs.length - index - 1 // สร้างลำดับจากมากไปน้อย
    }));

    await axios.post('/admin/faqs/reorder', 
      { faqs: orderData },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    setIsOrderChanged(false);
    
    MySwal.fire({
      title: 'สำเร็จ!',
      text: 'บันทึกลำดับเรียบร้อยแล้ว',
      icon: 'success',
      confirmButtonText: 'ตกลง',
      confirmButtonColor: '#3085d6'
    });
  } catch (error) {
    // เหมือนเดิม...
  } finally {
    setLoading(false);
  }
};

  // การกรองข้อมูลตามคำค้นหา
  const filteredFaqs = faqs.filter(faq => 
    faq.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
    faq.answer.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="p-8 bg-gray-50 min-h-screen">
      <div className="flex justify-end items-center">
        <button
          onClick={() => navigate("/admin/dashboard/data_management")}
          className="flex gap-2 bg-none text-gray-600 px-4 py-2 mb-5 rounded-md hover:text-gray-800 transition-colors"
        >
          <ArrowLeft className="h-5 w-5" />
          ย้อนกลับ
        </button>
      </div>

      <div className="mb-8 flex justify-center items-center">
        <div className="text-center">
          <h1 className="text-3xl font-bold text-gray-800">
            จัดการคำถามที่พบบ่อย
          </h1>
          <p className="text-xl text-gray-600 mt-2">
            เพิ่ม ลบ และแก้ไขคำถามที่พบบ่อย
          </p>
        </div>
      </div>

      {/* ปรับปรุงปุ่มบันทึกลำดับและปุ่มเพิ่มคำถาม */}
<Card className="p-3 sm:p-6 mb-6">
  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 gap-3 sm:gap-0">
    <div className="relative w-full sm:w-64">
      <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
      <input
        type="text"
        placeholder="ค้นหาคำถาม..."
        className="pl-10 pr-4 py-2 w-full border rounded-md focus:ring-gray-500"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
    </div>
    <div className="flex gap-2 w-full sm:w-auto">
      {isOrderChanged && (
        <button
          onClick={handleSaveOrder}
          disabled={loading}
          className={`flex items-center justify-center gap-1 bg-green-600 text-white px-2 py-2 sm:px-4 sm:py-2 rounded-md hover:bg-green-700 transition-colors text-xs sm:text-base flex-grow sm:flex-grow-0 ${
            loading ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          <span className="hidden sm:inline">บันทึกลำดับ</span>
          <span className="sm:hidden">บันทึก</span>
        </button>
      )}
      <button
        onClick={() => {
          setIsFormVisible(true);
          resetForm();
        }}
        disabled={loading}
        className={`flex items-center justify-center gap-1 sm:gap-2 bg-blue-600 text-white px-3 py-2 sm:px-4 sm:py-2 rounded-md hover:bg-blue-700 transition-colors text-xs sm:text-base flex-grow sm:flex-grow-0 ${
          loading ? "opacity-50 cursor-not-allowed" : ""
        }`}
      >
        <Plus className="h-4 w-4 sm:h-5 sm:w-5" />
        <span className="hidden sm:inline">เพิ่มคำถาม</span>
        <span className="sm:hidden">เพิ่ม</span>
      </button>
    </div>
  </div>

  {/* แบบฟอร์ม - เก็บไว้เหมือนเดิม */}
  {isFormVisible && (
    <form
      onSubmit={handleSubmit}
      className="mb-6 border p-3 sm:p-4 rounded-md text-start"
    >
      <div className="mb-4">
        <label className="block text-base font-medium text-gray-700 mb-2">
          คำถาม
        </label>
        <input
          type="text"
          className="w-full border rounded-md px-3 py-2 text-base"
          placeholder="ระบุคำถาม..."
          value={formData.question}
          onChange={(e) =>
            setFormData({ ...formData, question: e.target.value })
          }
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-base font-medium text-gray-700 mb-2">
          คำตอบ
        </label>
        <textarea
          className="w-full border rounded-md px-3 py-2 text-base min-h-[150px]"
          placeholder="ระบุคำตอบ..."
          value={formData.answer}
          onChange={(e) =>
            setFormData({ ...formData, answer: e.target.value })
          }
          required
        />
      </div>

      {editingFaq && (
        <div className="mb-4">
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-blue-600"
              checked={formData.active}
              onChange={(e) =>
                setFormData({ ...formData, active: e.target.checked })
              }
            />
            <span className="ml-2 text-gray-700">เผยแพร่</span>
          </label>
        </div>
      )}

      <div className="flex gap-4">
        <button
          type="submit"
          disabled={loading}
          className={`bg-green-600 text-white px-6 py-2.5 text-base font-medium rounded-md hover:bg-green-700 transition-colors ${
            loading ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          {loading ? "กำลังบันทึก..." : "บันทึก"}
        </button>
        <button
          type="button"
          disabled={loading}
          onClick={() => {
            setIsFormVisible(false);
            resetForm();
          }}
          className={`bg-gray-300 text-gray-800 px-6 py-2.5 text-base font-medium rounded-md hover:bg-gray-400 transition-colors ${
            loading ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          ยกเลิก
        </button>
      </div>
    </form>
  )}
</Card>

{/* ตารางและการ์ด FAQ */}
<Card className="p-3 sm:p-6">
  {loading && faqs.length === 0 ? (
    <div className="px-6 py-16 text-center">
      <div className="inline-block animate-spin rounded-full h-8 w-8 border-4 border-t-blue-500 border-gray-200 mb-4"></div>
      <p className="text-gray-500">กำลังโหลดข้อมูล...</p>
    </div>
  ) : filteredFaqs.length === 0 ? (
    <div className="px-6 py-4 text-center">ไม่พบคำถามที่ค้นหา</div>
  ) : (
    <>
      {/* Desktop View - แสดงเฉพาะบนจอใหญ่ */}
      <div className="hidden sm:block overflow-x-auto">
        <div className="min-w-full">
          <div className="flex bg-gray-50 border-b">
            <div className="w-[8%] px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
              ลำดับ
            </div>
            <div className="w-[40%] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              คำถาม
            </div>
            <div className="w-[40%] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              คำตอบ
            </div>
            <div className="w-[12%] px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
              จัดการ
            </div>
          </div>

          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="faqs-list" type="FAQ">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="bg-white"
                >
                  {filteredFaqs.map((faq, index) => (
                    <Draggable
                      key={faq.id.toString()}
                      draggableId={faq.id.toString()}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`flex items-start border-b py-3 hover:bg-gray-50 ${
                            snapshot.isDragging ? "bg-gray-100" : ""
                          } ${!(faq.active || faq.is_active === 1) ? "opacity-50" : ""}`}
                        >
                          <div className="w-[8%] px-3 py-4 whitespace-nowrap text-center flex items-center justify-center h-full">
                            <div className="flex justify-center items-center cursor-move">
                              <MoveVertical className="h-5 w-5 text-gray-500" />
                            </div>
                          </div>
                          <div className="w-[40%] px-6 py-2">
                            <div className="flex items-start">
                              <HelpCircle className="h-5 w-5 text-blue-500 mr-2 flex-shrink-0 mt-0.5" />
                              <div className="flex-1">
                                <div className="flex flex-wrap items-center gap-2">
                                  <span className="text-base text-gray-800 mr-1">{faq.question}</span>
                                  {!(faq.active || faq.is_active === 1) && (
                                    <span className="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium px-2 py-0.5 rounded-full">
                                      ไม่เผยแพร่
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="w-[40%] px-6 py-2">
                            <p className="text-sm text-gray-600 max-h-24 overflow-y-auto">
                              {faq.answer.length > 150
                                ? `${faq.answer.substring(0, 150)}...`
                                : faq.answer}
                            </p>
                          </div>
                          <div className="w-[12%] px-3 py-2 text-center text-sm text-gray-500 flex justify-center">
                            <button
                              onClick={() => handleEdit(faq)}
                              className="text-blue-600 hover:text-blue-900 mr-3"
                              title="แก้ไข"
                            >
                              <Edit2 className="h-5 w-5" />
                            </button>
                            <button
                              onClick={() => handleDelete(faq.id)}
                              className="text-red-600 hover:text-red-900"
                              title="ลบ"
                            >
                              <Trash2 className="h-5 w-5" />
                            </button>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>

      {/* Mobile View - แสดงเฉพาะบนมือถือ */}
      <div className="sm:hidden">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="mobile-faqs-list" type="MOBILE_FAQ">
            {(provided) => (
              <div 
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="space-y-3"
              >
                {filteredFaqs.map((faq, index) => (
                  <Draggable 
                    key={`mobile-${faq.id.toString()}`}
                    draggableId={`mobile-${faq.id.toString()}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={`border rounded-lg overflow-hidden ${snapshot.isDragging ? 'bg-gray-100' : 'bg-white'} ${
                          !(faq.active || faq.is_active === 1) ? "opacity-70" : ""
                        }`}
                      >
                        <div className="p-3 bg-gray-50 border-b flex justify-between items-center">
                          <div className="flex items-center">
                            <HelpCircle className="h-4 w-4 text-blue-500 mr-2" />
                            <div className="text-xs font-medium text-gray-700 line-clamp-1">
                              คำถาม {index + 1}
                            </div>
                          </div>
                          <div className="flex items-center gap-2">
                            {!(faq.active || faq.is_active === 1) && (
                              <span className="px-2 py-0.5 text-xs bg-red-100 text-red-800 rounded-full">
                                ไม่เผยแพร่
                              </span>
                            )}
                            <div {...provided.dragHandleProps} className="cursor-move">
                              <MoveVertical className="h-4 w-4 text-gray-500" />
                            </div>
                          </div>
                        </div>
                        
                        <div className="p-3">
                          <div className="mb-3">
                            <div className="text-sm font-medium text-gray-800 mb-1">คำถาม:</div>
                            <p className="text-sm text-gray-700">{faq.question}</p>
                          </div>
                          
                          <div>
                            <div className="text-sm font-medium text-gray-800 mb-1">คำตอบ:</div>
                            <p className="text-xs text-gray-600 line-clamp-3">
                              {faq.answer}
                            </p>
                            {faq.answer.length > 100 && (
                              <button 
                                onClick={() => {
                                  MySwal.fire({
                                    title: faq.question,
                                    html: `<div class="text-left">${faq.answer}</div>`,
                                    confirmButtonText: 'ปิด',
                                    confirmButtonColor: '#3085d6'
                                  });
                                }}
                                className="text-xs text-blue-600 mt-1"
                              >
                                อ่านเพิ่มเติม
                              </button>
                            )}
                          </div>
                        </div>
                        
                        <div className="p-3 bg-gray-50 border-t flex justify-end space-x-2">
                          <button
                            onClick={() => handleEdit(faq)}
                            className="flex items-center gap-1 bg-blue-50 text-blue-600 hover:bg-blue-100 px-3 py-1 rounded-md text-xs"
                          >
                            <Edit2 className="h-3 w-3" />
                            แก้ไข
                          </button>
                          <button
                            onClick={() => handleDelete(faq.id)}
                            className="flex items-center gap-1 bg-red-50 text-red-600 hover:bg-red-100 px-3 py-1 rounded-md text-xs"
                          >
                            <Trash2 className="h-3 w-3" />
                            ลบ
                          </button>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  )}
</Card>
    </div>
  );
};

export default FaqManagement;