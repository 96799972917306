import React, { useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import UserActivityTimelineView from './UserActivityTimelineView';
import { ArrowLeft } from 'lucide-react';

const UserActivityTimelinePage = () => {
  const { memberId } = useParams();
  const navigate = useNavigate();
  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: ''
  });

  // เพิ่มฟังก์ชัน handleSearch
  const handleSearch = () => {
    // ไม่ต้องทำอะไรพิเศษ เนื่องจาก UserActivityTimelineView 
    // จะ refetch ข้อมูลเมื่อ props เปลี่ยน
    console.log("Searching with date range:", dateRange);
  };

  return (
    <div className="p-4 sm:p-6 lg:p-8 bg-gray-50 min-h-screen">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6">
        <h1 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-4 sm:mb-0">
          รายงานกิจกรรมผู้ใช้ #{memberId}
        </h1>
        <button
          onClick={() => navigate("/admin/dashboard/activity-logs")}
          className="flex items-center gap-2 text-gray-600 hover:text-gray-800 transition-colors"
        >
          <ArrowLeft className="h-5 w-5" />
          <span>ย้อนกลับ</span>
        </button>
      </div>

      {/* ตัวกรองวันที่ */}
      <div className="bg-white p-4 rounded-lg shadow mb-6">
        <div className="grid grid-cols-1 sm:grid-cols-7 gap-4">
          <div className="sm:col-span-3">
            <label className="block text-sm font-medium text-gray-700 mb-1">วันที่เริ่มต้น</label>
            <input
              type="date"
              value={dateRange.startDate}
              onChange={(e) => setDateRange({...dateRange, startDate: e.target.value})}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="sm:col-span-3">
            <label className="block text-sm font-medium text-gray-700 mb-1">วันที่สิ้นสุด</label>
            <input
              type="date"
              value={dateRange.endDate}
              onChange={(e) => setDateRange({...dateRange, endDate: e.target.value})}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="sm:col-span-1 flex items-end">
            <button 
              onClick={handleSearch}
              className="w-full p-2 bg-indigo-600 text-white rounded hover:bg-indigo-700"
            >
              ค้นหา
            </button>
          </div>
        </div>
      </div>

      {/* UserActivityTimelineView component */}
      <UserActivityTimelineView 
        memberId={memberId}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
      />
    </div>
  );
};

export default UserActivityTimelinePage;