import React, { useState, useEffect } from "react";
import { UserIcon } from "@heroicons/react/24/outline";
import { useAuth } from '../context/AuthContext'; // นำเข้า useAuth Hook
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from '../utils/axiosConfig'; // เพิ่มการนำเข้า axios ที่กำหนดค่าไว้แล้ว


function Navbar() {
  const navigate = useNavigate();
  useAuth(); // เรียกใช้ useAuth Hook
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const storedName = localStorage.getItem("name");
    if (storedName) {
      setUserName(storedName);
    }

    // เช็ค token และ tokenExpiration
    const token = localStorage.getItem("token");
    const tokenExpiration = localStorage.getItem("tokenExpiration");

    // เพิ่มเงื่อนไขเช็คว่าไม่ได้อยู่ที่หน้า login
    if (token && tokenExpiration && new Date(tokenExpiration) < new Date() && 
        !window.location.pathname.includes('/login')) {
      // แสดง SweetAlert แจ้งเตือนก่อนทำการ redirect
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        title: 'เซสชันหมดอายุ',
        text: 'กรุณาเข้าสู่ระบบใหม่อีกครั้ง',
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'ตกลง'
      }).then(() => {
        // ล้างข้อมูลและนำทางไปยังหน้าล็อกอิน
        localStorage.removeItem("name");
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiration");
        localStorage.removeItem("tokenExpirationDisplay"); 
        localStorage.removeItem("currentUser");
        navigate('/login'); // ใช้ navigate แทน window.location.href
      });
    }
  }, [navigate]);

  // ✅ ฟังก์ชันออกจากระบบ
  const handleLogout = () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) return;
      
      // แก้ไขการใช้ fetch เป็น axios ที่มีการตั้งค่า baseURL ไว้แล้ว
      axios.post('/activity/log', {
        event_type: 'logout',
        page_url: window.location.pathname,
        additional_info: {
          user_type: 'user',
          method: 'manual_logout'
        }
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).catch(error => {
        console.log("ไม่สามารถบันทึกการออกจากระบบได้:", error);
      });
      
      // ล้างข้อมูลที่เก็บไว้ใน localStorage
      localStorage.removeItem("name");
      localStorage.removeItem("token");
      localStorage.removeItem("tokenExpiration");
      localStorage.removeItem("tokenExpirationDisplay");
      localStorage.removeItem("currentUser");
      
      // แสดง Toast message แทน Modal
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        toast: true,
        position: 'top-end',
        icon: 'success',
        title: 'ออกจากระบบสำเร็จ',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        },
        willClose: () => {
          // นำทางไปยังหน้าล็อกอินหลังจาก toast ปิด
          navigate('/login');
        }
      });
    } catch (error) {
      console.error("Error during logout:", error);
      
      // กรณีมีข้อผิดพลาดยังคงลบ token
      localStorage.removeItem("name");
      localStorage.removeItem("token");
      localStorage.removeItem("tokenExpiration");
      localStorage.removeItem("tokenExpirationDisplay"); 
      localStorage.removeItem("currentUser");
      
      // แสดง Toast error message
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        title: 'เกิดข้อผิดพลาดในการออกจากระบบ',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        },
        willClose: () => {
          // นำทางไปยังหน้าล็อกอินหลังจาก toast ปิด
          navigate('/login');
        }
      });
    }
  };

  return (
    <div className="navbar bg-gray-800 text-yellow-400">
      <div className="navbar-start">
        <div className="dropdown">
          <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" />
            </svg>
          </div>

          {/* ส่วนเมนูสำหรับ Mobile */}
          <ul className="menu menu-sm dropdown-content rounded-box z-[1] mt-3 w-52 p-2 shadow bg-gray-800">
            <li className="text-lg"><a href="/home">หน้าหลัก</a></li>
            <li>
              <details>
                <summary className="text-sm">เอกสารและคู่มือ</summary>
                <ul className="p-2">
                  <li className="text-lg"><a href="/file_manager">จัดการเอกสาร</a></li>
                  <li className="text-lg"><a href="/handbook">คู่มือพนักงาน</a></li>
                </ul>
              </details>
            </li>
            <li className="text-lg"><a href="/knowledge">คลังความรู้</a></li>
            <li className="text-2xl"><a href="/report">แจ้งปัญหา</a></li>
          </ul>
        </div>

        <a href="/home" className="btn btn-ghost text-xl text-yellow-400 -ml-3 lg:ml-5">
        <img src="/New-Logo.png" alt="Logo" className="h-10 w-30 mr-2" />
        </a>
      </div>

      {/* ✅ ส่วนเมนูตรงกลาง */}
      <div className="navbar-center hidden lg:flex">
        <ul className="menu menu-horizontal px-1 text-base font-semibold">
          <li><a href="/home">หน้าหลัก</a></li>
          <li>
            <details>
              <summary>เอกสารและคู่มือ</summary>
              <ul className="p-2 bg-gray-800 z-50">
                <li><a href="/file_manager">จัดการเอกสาร</a></li>
                <li><a href="/handbook">คู่มือพนักงาน</a></li>
              </ul>
            </details>
          </li>
          <li><a href="/knowledge">คลังความรู้</a></li>
          <li><a href="/report">แจ้งปัญหา</a></li>
        </ul>
      </div>

      {/* ✅ Dropdown สำหรับ User */}
      <div className="navbar-end mr-1">
        {userName ? (
          <div className="dropdown dropdown-end">
            <div tabIndex={0} role="button" className="btn btn-ghost flex items-center">
              <UserIcon className="h-7 w-8 text-yellow-400 " />
              <span>{userName}</span>
            </div>
            <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-gray-800 rounded-box w-40">
              <li className="flex items-center font-semibold">
                <button onClick={handleLogout} className="text-yellow-400  hover:text-red-500  ">
                  ออกจากระบบ
                </button>
              </li>
            </ul>
          </div>
        ) : (
          <a href="/login">
            <button className=" bg-gray-800 border-none  hover:text-white mr-5">
              <UserIcon className="h-8 w-8 text-yellow-400" />
            </button>
          </a>
        )}
      </div>
    </div>
  );
}

export default Navbar;
