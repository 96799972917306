import React from 'react';

// ฟังก์ชันตรวจสอบว่าเนื้อหาน่าจะเป็น Markdown หรือไม่
export const isMarkdownContent = (content) => {
  if (!content) return false;
  
  // ตรวจสอบรูปแบบเบื้องต้นของ Markdown
  const markdownPatterns = [
    /^#+ /m,                     // Headers
    /\*\*.+?\*\*/,               // Bold
    /\*.+?\*/,                   // Italic
    /^\s*-\s+/m,                 // List items
    /^\s*\d+\.\s+/m,             // Ordered list
    /\[.+?\]\(.+?\)/,            // Links
    /!\[.+?\]\(.+?\)/,           // Images
    /^>\s+/m,                    // Blockquotes
    /```[\s\S]*?```/,            // Code blocks
    /^\s*[*-]\s+/m,              // Unordered lists
    /\|\s*.+?\s*\|/,             // Tables
    /<div align="(center|right|left)">/,   // HTML alignment tags
    /\n\n/                       // Line breaks
  ];
  
  return markdownPatterns.some(pattern => pattern.test(content));
};

// Custom components สำหรับ ReactMarkdown
export const markdownComponents = {
  h1: ({node, children, ...props}) => {
    const align = node.properties?.align || 
                 (node.properties?.style && node.properties.style.includes('text-align')) ? 
                 node.properties.style.match(/text-align:\s*(\w+)/)?.[1] : 'left';
    return <h1 style={{textAlign: align}} className="heading-h1" {...props}>{children}</h1>;
  },
  h2: ({node, children, ...props}) => {
    const align = node.properties?.align || 
                 (node.properties?.style && node.properties.style.includes('text-align')) ? 
                 node.properties.style.match(/text-align:\s*(\w+)/)?.[1] : 'left';
    return <h2 style={{textAlign: align}} className="heading-h2" {...props}>{children}</h2>;
  },
  h3: ({node, children, ...props}) => {
    const align = node.properties?.align || 
                 (node.properties?.style && node.properties.style.includes('text-align')) ? 
                 node.properties.style.match(/text-align:\s*(\w+)/)?.[1] : 'left';
    return <h3 style={{textAlign: align}} className="heading-h3" {...props}>{children}</h3>;
  },
  p: ({node, children, ...props}) => {
    const align = node.properties?.align || 
                 (node.properties?.style && node.properties.style.includes('text-align')) ? 
                 node.properties.style.match(/text-align:\s*(\w+)/)?.[1] : 'left';
    
    // ใช้ white-space: pre-wrap เพื่อให้การขึ้นบรรทัดใหม่มีผล
    return <p style={{textAlign: align, whiteSpace: 'pre-wrap'}} className="paragraph" {...props}>{children}</p>;
  },
  ul: ({node, children, ...props}) => {
    const align = node.properties?.align || 
                 (node.properties?.style && node.properties.style.includes('text-align')) ? 
                 node.properties.style.match(/text-align:\s*(\w+)/)?.[1] : 'left';
    return <ul style={{textAlign: align, paddingLeft: '2em'}} {...props}>{children}</ul>;
  },
  ol: ({node, children, ...props}) => {
    const align = node.properties?.align || 
                 (node.properties?.style && node.properties.style.includes('text-align')) ? 
                 node.properties.style.match(/text-align:\s*(\w+)/)?.[1] : 'left';
    return <ol style={{textAlign: align, paddingLeft: '2em'}} {...props}>{children}</ol>;
  },
  table: ({node, children, ...props}) => {
    const align = node.properties?.align || 
                 (node.properties?.style && node.properties.style.includes('text-align')) ? 
                 node.properties.style.match(/text-align:\s*(\w+)/)?.[1] : 'left';
    return <table style={{textAlign: align, width: '100%', borderCollapse: 'collapse'}} {...props}>{children}</table>;
  },
  blockquote: ({node, children, ...props}) => {
    const align = node.properties?.align || 
                 (node.properties?.style && node.properties.style.includes('text-align')) ? 
                 node.properties.style.match(/text-align:\s*(\w+)/)?.[1] : 'left';
    return (
      <blockquote 
        style={{
          borderLeft: '0.25em solid #ddd', 
          paddingLeft: '1em', 
          color: '#6b7280',
          backgroundColor: '#f9fafb',
          margin: '0 0 16px',
          textAlign: align
        }} 
        {...props}
      >
        {children}
      </blockquote>
    );
  },
  div: ({node, children, ...props}) => {
    const align = node.properties?.align || 'left';
    return <div style={{textAlign: align}} {...props}>{children}</div>;
  },
  // เพิ่มการจัดการ <br> เพื่อแสดงการขึ้นบรรทัดใหม่
  br: ({node, ...props}) => <br className="md-line-break" {...props} />
};

// กำหนดกลุ่มปุ่มสำหรับการจัดการ Markdown
const markdownButtons = [
  { label: "หัวข้อใหญ่", prefix: "# ", group: "heading" },
  { label: "หัวข้อรอง", prefix: "## ", group: "heading" },
  { label: "ตัวหนา", prefix: "**", suffix: "**", group: "format" },
  { label: "ตัวเอียง", prefix: "*", suffix: "*", group: "format" },
  { label: "รายการ", prefix: "- ", group: "structure" },
  { label: "ลิงก์", prefix: "[", middle: "](", suffix: ")", group: "structure" },
  { label: "ข้อความอ้างอิง", prefix: "> ", group: "structure" },
  { label: "ชิดซ้าย", prefix: "<div align=\"left\">", suffix: "</div>", group: "alignment" },
  { label: "อยู่กลาง", prefix: "<div align=\"center\">", suffix: "</div>", group: "alignment" },
  { label: "ชิดขวา", prefix: "<div align=\"right\">", suffix: "</div>", group: "alignment" },
  { label: "หัวข้อใหญ่(อยู่กลาง)", prefix: "<div align=\"center\">\n\n# ", suffix: "\n\n</div>", group: "centered" },
  { label: "หัวข้อรอง(อยู่กลาง)", prefix: "<div align=\"center\">\n\n## ", suffix: "\n\n</div>", group: "centered" }
];

// เพิ่มฟังก์ชันสำหรับกลุ่มปุ่มตามประเภท
export const getButtonGroups = () => {
  const groups = {
    heading: markdownButtons.filter(ex => ex.group === "heading"),
    format: markdownButtons.filter(ex => ex.group === "format"),
    structure: markdownButtons.filter(ex => ex.group === "structure"),
    alignment: markdownButtons.filter(ex => ex.group === "alignment"),
    centered: markdownButtons.filter(ex => ex.group === "centered")
    // ลบ spacing ออกเนื่องจากไม่มีปุ่มในกลุ่มนี้แล้ว
  };
  
  return groups;
};

// Export markdownExamples เพื่อความเข้ากันได้กับโค้ดเก่า
export const markdownExamples = markdownButtons;

// เพิ่มฟังก์ชันเฉพาะสำหรับการจัดการบทความ

// เพิ่มฟังก์ชันสำหรับสร้าง slug จากชื่อบทความ
export const generateSlug = (title) => {
  if (!title) return '';
  
  // แปลง Thai เป็น Latin และแทนที่อักขระพิเศษด้วย '-'
  const slug = title
    .toLowerCase()
    .replace(/[^a-z0-9\u0E00-\u0E7F]+/g, '-') // แทนที่อักขระพิเศษด้วย '-'
    .replace(/^-+|-+$/g, '') // ตัด '-' ที่อยู่ต้นหรือท้าย
    .substring(0, 80); // จำกัดความยาว
    
  return slug;
};

// เพิ่มฟังก์ชันเพื่อสร้าง summary สำหรับบทความ
export const generateSummary = (content, maxLength = 200) => {
  if (!content) return '';
  
  // ตัด HTML tags และทำความสะอาดเนื้อหา
  const plainText = content
    .replace(/<[^>]*>/g, '') // ลบ HTML tags
    .replace(/\s+/g, ' ') // แทนที่ whitespace ต่อเนื่องด้วย space เดียว
    .trim(); // ตัด whitespace ที่หัวและท้าย
  
  // ตัดความยาวถ้าเกินที่กำหนด
  if (plainText.length <= maxLength) {
    return plainText;
  }
  
  // ตัดที่ประโยคที่เหมาะสม (จบด้วย . , ? !)
  let shortenedText = plainText.substring(0, maxLength);
  const lastPunctuation = Math.max(
    shortenedText.lastIndexOf('.'), 
    shortenedText.lastIndexOf('!'),
    shortenedText.lastIndexOf('?'),
    shortenedText.lastIndexOf(',')
  );
  
  if (lastPunctuation > maxLength * 0.7) { // ถ้าเครื่องหมายวรรคตอนอยู่ในตำแหน่งที่เหมาะสม
    shortenedText = shortenedText.substring(0, lastPunctuation + 1);
  } else {
    // ตัดที่คำสุดท้าย
    const lastSpace = shortenedText.lastIndexOf(' ');
    if (lastSpace > maxLength * 0.7) {
      shortenedText = shortenedText.substring(0, lastSpace);
    }
    shortenedText += '...';
  }
  
  return shortenedText;
};