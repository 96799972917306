import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from './axiosConfig';
import { configValues } from './configBridge';

const RouteTracker = () => {
  const location = useLocation();
  
  useEffect(() => {
    // บันทึก path ปัจจุบัน
    localStorage.setItem('currentPath', location.pathname);
    
    // ตรวจสอบว่าเป็น admin path หรือไม่
    const isAdminPath = location.pathname.includes('/admin');
    const tokenType = isAdminPath ? 'admin_token' : 'token';
    const expirationKey = isAdminPath ? 'adminTokenExpiration' : 'tokenExpiration';
    
    // ตรวจสอบว่ามี token และ ยังไม่หมดอายุหรือไม่
    const token = localStorage.getItem(tokenType);
    const expiration = localStorage.getItem(expirationKey);
    
    if (token && expiration) {
      const expirationTime = new Date(expiration).getTime();
      const currentTime = new Date().getTime();
      const timeLeft = expirationTime - currentTime;
      
      // ถ้าเหลือเวลาน้อยกว่า 3 นาที และมากกว่า 0 (ยังไม่หมดอายุ) ให้ refresh token
      const threeMinutesInMs = 3 * 60 * 1000;
      if (timeLeft < threeMinutesInMs && timeLeft > 0) {
        console.log("USER_ACTIVITY: เหลือเวลา token น้อยกว่า 3 นาที กำลังต่ออายุ...");
        
        // เลือก endpoint ตามประเภทของ path
        const refreshEndpoint = isAdminPath ? 
          configValues.endpoints.refreshAdminToken : 
          configValues.endpoints.refreshUserToken;
        
        // เรียก refresh token API
        axios.post(refreshEndpoint, {}, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then(response => {
          console.log("USER_ACTIVITY: ต่ออายุ token สำเร็จ");
          // ตัวอย่างการจัดการหลังจาก refresh token สำเร็จ...
        })
        .catch(error => {
          console.error("USER_ACTIVITY: ไม่สามารถต่ออายุ token ได้", error);
        });
      }
    }
  }, [location]);
  
  return null;
};

export default RouteTracker;