import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from '../utils/axiosConfig';
import { Image, FileText, Folder, LayoutGrid, List, Download, Trash2, ArrowLeft, Users, EllipsisVertical, Edit2 } from 'lucide-react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import '../input.css';
import CreateFolderModal from './modals/CreateFolderModal';
import EditAccessLevelModal from './modals/EditAccessLevelModal';


// ส่วนค่าคงที่
const currentUser = JSON.parse(localStorage.getItem('currentUser'));
const departments = [
  { id: "acc", label: "Accounting", color: "bg-blue-100 text-blue-800" },
  { id: "fin", label: "Finance", color: "bg-green-100 text-green-800" },
  { id: "wh", label: "Warehouse", color: "bg-yellow-100 text-yellow-800" },
  { id: "hr", label: "Human Resources", color: "bg-purple-100 text-purple-800" },
  { id: "it", label: "Information Technology", color: "bg-red-100 text-red-800" }
];

// ส่วนฟังก์ชันนอกคอมโพเนนต์
const renderAccessLevelBadges = (accessLevel) => {
  if (!accessLevel || accessLevel === 'all') {
    return <span className="px-2 py-0.5 text-xs rounded bg-gray-100 text-gray-800 inline-flex items-center h-5">
      <span className="h-2 w-2 bg-gray-400 rounded-full mr-1"></span>
      ทุกแผนก
    </span>;
  }
  
  // แยกแผนกที่มีสิทธิ์
  const accessLevels = accessLevel.split(',');
  
  // แก้ไขเพิ่ม className="justify-start w-full" เพื่อให้ชิดซ้าย
  return (
    <div className="flex flex-wrap gap-1 justify-start w-full">
      {accessLevels.map(level => {
        const dept = departments.find(d => d.id === level);
        return dept ? (
          <span key={level} className={`px-2 py-0.5 text-xs rounded ${dept.color} inline-flex items-center h-5`}>
            <span className={`h-2 w-2 ${dept.color.includes('blue') ? 'bg-blue-500' : 
                             dept.color.includes('green') ? 'bg-green-500' : 
                             dept.color.includes('yellow') ? 'bg-yellow-500' : 
                             dept.color.includes('purple') ? 'bg-purple-500' : 
                             'bg-red-500'} rounded-full mr-1`}></span>
            {dept.label}
          </span>
        ) : null;
      })}
    </div>
  );
};

const ICON_SIZES = {
  small: "w-4 h-4",
  default: "w-5 h-5",
  medium: "w-6 h-6",
  large: "w-7 h-7"
};

const FilesManager = () => {
  const [files, setFiles] = useState([]);
  const [folders, setFolders] = useState([]);
  const [currentFolderId, setCurrentFolderId] = useState(null);
  const [folderPath, setFolderPath] = useState([]);
  const [viewMode, setViewMode] = useState('list');
  const [foldersViewMode, setFoldersViewMode] = useState('list');
  const navigate = useNavigate();
  const hiddenFileInput = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditAccessModalOpen, setIsEditAccessModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [folderToRename, setFolderToRename] = useState(null);
  const [newFolderName, setNewFolderName] = useState('');
  
  // 1. ย้าย showAlert ขึ้นมาก่อนเพื่อให้ฟังก์ชันอื่นสามารถใช้งานได้
  const showAlert = (icon, title, text, type = 'toast') => {
    if (type === 'modal') {
      return Swal.fire({
        icon, title, text,
        confirmButtonText: 'เข้าใจแล้ว',
        confirmButtonColor: '#3085d6',
      });
    }
    
    return Swal.fire({
      icon, title, text,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };
  
  // 2. เริ่มจากฟังก์ชันที่ไม่ขึ้นกับฟังก์ชันอื่น
  const formatDate = (dateString) => {
    if (!dateString) return '-';
    return dateString; // ใช้ค่าที่ได้จาก API โดยตรง เพราะ format ถูกต้องแล้ว
  };
  
  // 3. ตามด้วยฟังก์ชัน fetchFiles และ fetchFolders 
  const fetchFiles = useCallback(async (folderId) => {
    try {
      const url = `/folders/${folderId}/files`;
      const response = await axios.get(url);
      
      setFiles(response.data.files || response.data);
    } catch (err) {
      showAlert('error', 'เกิดข้อผิดพลาด', 'ไม่สามารถโหลดรายการไฟล์ได้');
      
      if (err.response?.status === 401) {
        navigate('/login');
      }
    }
  }, [navigate]);

  const fetchFolders = useCallback(async (parentId = null) => {
    try {
      const currentUserData = JSON.parse(localStorage.getItem('currentUser'));
      console.log('Current user access level:', currentUserData?.access_level);
      
      const url = parentId 
        ? `/folders/${parentId}/subfolders`
        : '/folders';

      console.log('กำลังเรียก API:', url);
      const response = await axios.get(url);
      
      console.log('API Response status:', response.status);
      console.log('API Response data:', response.data);
      
      setFolders(response.data.folders || []);
    } catch (error) {
      console.error('Error in fetchFolders:', error);
      showAlert('error', 'เกิดข้อผิดพลาด', `ไม่สามารถโหลดข้อมูลโฟลเดอร์`);
    }
  }, []);
  
  // 4. ประกาศ initializeRootFolder ก่อน useEffect ที่จะใช้มัน
  const initializeRootFolder = useCallback(async () => {
    try {
      const response = await axios.get('/root-folder');
      
      if (response.data?.folder) {
        const rootFolder = response.data.folder;
        setCurrentFolderId(rootFolder.id);
        setFolderPath([]);
        fetchFolders(rootFolder.id);
        fetchFiles(rootFolder.id);
      } else {
        fetchFolders(null);
      }
    } catch (error) {
      console.error('Error initializing root folder:', error);
      fetchFolders(null);
    }
  }, [fetchFolders, fetchFiles]);
  
  // 5. ตามด้วย useEffect ที่ใช้ initializeRootFolder
  useEffect(() => {
    const isAdmin = !!localStorage.getItem('admin_token');
    
    if (!isAdmin) {
      initializeRootFolder();
    } else {
      fetchFolders(null);
    }
  }, [fetchFolders, initializeRootFolder]);
  
  // 6. ตามด้วยฟังก์ชันอื่นๆ ที่ใช้ initializeRootFolder
  const handleFolderClick = async (folder) => {
    setCurrentFolderId(folder.id);
    
    const isRootFolder = folder.name === 'Root' && !folder.parent_id;
    
    if (isRootFolder) {
      setFolderPath([]);
    } else {
      setFolderPath(prev => [...prev, folder]);
    }
    
    await Promise.all([
      fetchFolders(folder.id),
      fetchFiles(folder.id)
    ]);
  };
  
  const handleBack = () => {
    const isAdmin = !!localStorage.getItem('admin_token');
    
    if (folderPath.length > 0) {
      const newPath = folderPath.slice(0, folderPath.length - 1);
      setFolderPath(newPath);
      
      if (newPath.length > 0) {
        const previousFolder = newPath[newPath.length - 1];
        setCurrentFolderId(previousFolder.id);
        fetchFolders(previousFolder.id);
        fetchFiles(previousFolder.id);
      } else {
        if (isAdmin) {
          setCurrentFolderId(null);
          fetchFolders(null);
          setFiles([]);
        } else {
          initializeRootFolder();
        }
      }
    } else {
      if (isAdmin) {
        setCurrentFolderId(null);
        fetchFolders(null);
        setFiles([]);
      } else {
        initializeRootFolder();
      }
    }
  };

  // 7. ฟังก์ชันอื่นๆ
  const confirmAction = (title, text, confirmText = 'ใช่, ดำเนินการ', cancelText = 'ยกเลิก') => {
    return Swal.fire({
      title, text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: confirmText,
      cancelButtonText: cancelText
    });
  };

  const showLoading = (title = 'กำลังดำเนินการ...', text = 'โปรดรอสักครู่') => {
    return Swal.fire({
      title, text,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => Swal.showLoading(),
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: acceptedFiles => {
      handleUpload(acceptedFiles);
    },
    multiple: true,
    noClick: true,
    noKeyboard: true
  });

  // แก้ไขฟังก์ชัน handleUpload
const handleUpload = async (acceptedFiles) => {
  try {
    if (!acceptedFiles || acceptedFiles.length === 0) {
      // ใช้ toast สำหรับข้อผิดพลาดเล็กน้อย - ไม่ได้เลือกไฟล์
      showAlert('error', 'เกิดข้อผิดพลาด', 'ไม่ได้เลือกไฟล์');
      return;
    }

    // ใช้ loading สำหรับกระบวนการที่ใช้เวลานาน
    showLoading('กำลังอัพโหลด...', 'โปรดรอสักครู่');

    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);
    formData.append('folderId', currentFolderId || '');

    let token = localStorage.getItem('token');
    if (isAdmin() && localStorage.getItem('admin_token')) {
      token = localStorage.getItem('admin_token');
      console.log('Using admin token for upload');
    }

    // เปลี่ยนจาก fetch เป็น axios เพื่อให้จัดการ error ได้ดีขึ้น
    // และแก้ไข URL ให้ถูกต้องโดยเพิ่ม /api ข้างหน้า
    const response = await axios.post('/upload', formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    });

    Swal.close(); // ปิด loading

    // ตรวจสอบ response
    if (response.status === 200 || response.status === 201) {
      // ใช้ toast สำหรับยืนยันการดำเนินการสำเร็จ
      showAlert('success', 'สำเร็จ', 'อัพโหลดไฟล์เรียบร้อยแล้ว');
      fetchFiles(currentFolderId);
    } else {
      throw new Error(response.data.message || 'การอัพโหลดไฟล์ล้มเหลว');
    }
  } catch (err) {
    Swal.close(); // ปิด loading

    console.error('Error uploading file:', err);

    // ปรับข้อความ error ให้เข้าใจง่าย
    let errorMessage = 'ไม่สามารถอัพโหลดไฟล์ได้';
    
    if (err.response) {
      // กรณีมี response จาก server (HTTP error)
      errorMessage += `: ${err.response.data?.message || err.response.statusText}`;
    } else if (err.request) {
      // กรณีไม่มี response จาก server (Network error)
      errorMessage += ': ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้';
    } else {
      // กรณีอื่นๆ
      errorMessage += err.message ? `: ${err.message}` : '';
    }

    // ใช้ modal สำหรับข้อผิดพลาดในการอัพโหลด (อาจมีรายละเอียดที่สำคัญ)
    showAlert('error', 'เกิดข้อผิดพลาดในการอัพโหลด', errorMessage, 'modal');
  }
};

  const handleDownload = async (fileId, fileName) => {
    try {
      // แสดงสถานะการโหลด
      showLoading('กำลังเตรียมการดาวน์โหลด...', 'โปรดรอสักครู่');
      
      const token = localStorage.getItem('token');
      const response = await fetch(`/download/${fileId}`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      
      if (!response.ok) throw new Error('ดาวน์โหลดไฟล์ไม่สำเร็จ');
  
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      
      Swal.close(); // ปิดสถานะการโหลด
      // ใช้ toast สำหรับแจ้งความสำเร็จ
      showAlert('success', 'สำเร็จ', 'เริ่มต้นดาวน์โหลดไฟล์แล้ว');
    } catch (err) {
      Swal.close(); // ปิดสถานะการโหลด
      // ใช้ modal สำหรับข้อผิดพลาดในการดาวน์โหลด
      showAlert('error', 'เกิดข้อผิดพลาดในการดาวน์โหลด', err.message, 'modal');
    }
  };

  const handleDelete = async (fileId) => {
    try {
      // ใช้ confirmAction สำหรับการยืนยันการลบ (การกระทำที่ไม่สามารถย้อนกลับได้)
      const confirmation = await confirmAction(
        'คุณแน่ใจหรือไม่?',
        'คุณต้องการลบไฟล์นี้หรือไม่? การกระทำนี้ไม่สามารถเรียกคืนได้',
        'ใช่, ลบเลย',
        'ยกเลิก'
      );
      
      if (!confirmation.isConfirmed) return;
  
      // แสดงสถานะการโหลด
      showLoading('กำลังลบไฟล์...', 'โปรดรอสักครู่');
      
      const token = localStorage.getItem('token');
      const response = await fetch(`/files/${fileId}`, {
        method: 'DELETE',
        headers: { 'Authorization': `Bearer ${token}` },
      });
      
      if (!response.ok) throw new Error('ลบไฟล์ไม่สำเร็จ');
      
      Swal.close(); // ปิดสถานะการโหลด
      // ใช้ toast สำหรับยืนยันการดำเนินการสำเร็จ
      showAlert('success', 'สำเร็จ', 'ไฟล์ถูกลบสำเร็จ');
      await fetchFiles(currentFolderId);
    } catch (err) {
      Swal.close(); // ปิดสถานะการโหลด
      // ใช้ modal สำหรับข้อผิดพลาดสำคัญ
      showAlert('error', 'เกิดข้อผิดพลาดในการลบไฟล์', err.message, 'modal');
    }
  };

  // แก้ไขฟังก์ชัน handleCreateFolder เพื่อให้แสดง debug info
const handleCreateFolder = async (folderName, accessLevel) => {
  if (!folderName) {
    // ใช้ toast สำหรับข้อผิดพลาดในการกรอกข้อมูล
    showAlert('error', 'กรุณากรอกชื่อโฟลเดอร์', '');
    return;
  }
  
  try {
    // แสดงสถานะการโหลด
    showLoading('กำลังสร้างโฟลเดอร์...', 'โปรดรอสักครู่');
    
    console.log(`กำลังสร้างโฟลเดอร์ '${folderName}' ใน parent_id:`, currentFolderId);
    console.log('access_level:', accessLevel);
    
    const response = await axios.post('/folders', {
      name: folderName,
      parent_id: currentFolderId,
      access_level: accessLevel
    });
    
    console.log('Create folder response status:', response.status);
    console.log('Response data:', response.data);
    
    Swal.close(); // ปิดสถานะการโหลด
    // ใช้ toast สำหรับแจ้งความสำเร็จ
    showAlert('success', 'สำเร็จ', 'สร้างโฟลเดอร์เรียบร้อยแล้ว');
    fetchFolders(currentFolderId);
  } catch (error) {
    Swal.close(); // ปิดสถานะการโหลด
    console.error('Error creating folder:', error);
    // ใช้ modal สำหรับข้อผิดพลาดสำคัญ
    showAlert('error', 'เกิดข้อผิดพลาดในการสร้างโฟลเดอร์', error.message, 'modal');
  } finally {
    handleCloseModal();
  }
};

  const handleDeleteFolder = async (folderId) => {
    try {
      // ใช้ confirmAction สำหรับการยืนยันการลบโฟลเดอร์ที่อาจมีไฟล์ข้างใน
      const confirmation = await confirmAction(
        'คุณแน่ใจหรือไม่?',
        'การลบโฟลเดอร์จะลบไฟล์และโฟลเดอร์ย่อยทั้งหมด และไม่สามารถเรียกคืนได้',
        'ใช่, ลบเลย',
        'ยกเลิก'
      );
      
      if (!confirmation.isConfirmed) return;
  
      // แสดงสถานะการโหลด
      showLoading('กำลังลบโฟลเดอร์...', 'โปรดรอสักครู่');
      
      const token = localStorage.getItem('token');
      const response = await fetch(`/folders/${folderId}`, {
        method: 'DELETE',
        headers: { 'Authorization': `Bearer ${token}` },
      });
      
      if (!response.ok) throw new Error('ลบโฟลเดอร์ไม่สำเร็จ');
      
      Swal.close(); // ปิดสถานะการโหลด
      // ใช้ toast สำหรับแจ้งความสำเร็จ
      showAlert('success', 'สำเร็จ', 'ลบโฟลเดอร์เรียบร้อยแล้ว');
      fetchFolders(currentFolderId);
    } catch (err) {
      Swal.close(); // ปิดสถานะการโหลด
      // ใช้ modal สำหรับข้อผิดพลาดสำคัญ
      showAlert('error', 'เกิดข้อผิดพลาดในการลบโฟลเดอร์', err.message, 'modal');
    }
  };

  const handleShowCreateFolderModal = () => {
    console.log("Opening create folder modal with parentFolderAccessLevel:", parentFolderAccessLevel);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleEditAccess = (item, isFolder = true) => {
    setSelectedItem({ ...item, isFolder });
    setIsEditAccessModalOpen(true);
  };

  const handleUpdateAccess = async (itemId, accessLevel, isFolder) => {
    try {
      // แสดงสถานะการโหลด
      showLoading('กำลังอัปเดตสิทธิ์...', 'โปรดรอสักครู่');
      
      const endpoint = isFolder 
        ? `/folders/${itemId}/access`
        : `/files/${itemId}/access`;
  
        // eslint-disable-next-line
      const response = await axios.put(endpoint, { access_level: accessLevel });
  
      Swal.close(); // ปิดสถานะการโหลด
      // ใช้ toast สำหรับแจ้งความสำเร็จ
      showAlert('success', 'สำเร็จ', 'อัปเดตสิทธิ์การเข้าถึงเรียบร้อยแล้ว');
  
      // รีโหลดข้อมูล
      if (isFolder) {
        fetchFolders(currentFolderId);
      } else {
        fetchFiles(currentFolderId);
      }
    } catch (error) {
      Swal.close(); // ปิดสถานะการโหลด
      // ใช้ modal สำหรับข้อผิดพลาดเกี่ยวกับสิทธิ์ (เรื่องสำคัญ)
      showAlert('error', 'เกิดข้อผิดพลาดในการอัปเดตสิทธิ์', error.message, 'modal');
    } finally {
      setIsEditAccessModalOpen(false);
    }
  };

  // แก้ไขฟังก์ชัน isAdmin ให้ชัดเจน
const isAdmin = () => {
  const adminToken = localStorage.getItem('admin_token');
  return !!adminToken; // แปลงเป็น boolean ชัดเจน
};

// เพิ่มฟังก์ชันสำหรับเปิดโมดัลแก้ไขชื่อ
const handleShowRenameModal = (folder) => {
  setFolderToRename(folder);
  setNewFolderName(folder.name);
  setIsRenameModalOpen(true);
};

// เพิ่มฟังก์ชันสำหรับปิดโมดัลแก้ไขชื่อ
const handleCloseRenameModal = () => {
  setIsRenameModalOpen(false);
  setFolderToRename(null);
  setNewFolderName('');
};

// เพิ่มฟังก์ชันสำหรับอัปเดตชื่อโฟลเดอร์
const handleRenameFolder = async () => {
  if (!folderToRename || !newFolderName.trim()) {
    // ใช้ toast สำหรับข้อผิดพลาดในการกรอกข้อมูล
    showAlert('error', 'กรุณากรอกข้อมูล', 'กรุณากรอกชื่อโฟลเดอร์');
    return;
  }

  try {
    // แสดงสถานะการโหลด
    showLoading('กำลังเปลี่ยนชื่อโฟลเดอร์...', 'โปรดรอสักครู่');
    
    // eslint-disable-next-line
    const response = await axios.put(`/folders/${folderToRename.id}/rename`, { name: newFolderName });

    Swal.close(); // ปิดสถานะการโหลด
    // ใช้ toast สำหรับแจ้งความสำเร็จ
    showAlert('success', 'สำเร็จ', 'เปลี่ยนชื่อโฟลเดอร์เรียบร้อยแล้ว');

    // อัปเดตชื่อโฟลเดอร์ใน folderPath ถ้ามีการกดเข้าไปในโฟลเดอร์ที่กำลังเปลี่ยนชื่อ
    if (folderPath.some(folder => folder.id === folderToRename.id)) {
      setFolderPath(folderPath.map(folder => 
        folder.id === folderToRename.id 
          ? { ...folder, name: newFolderName } 
          : folder
      ));
    }

    // รีโหลดข้อมูลโฟลเดอร์
    fetchFolders(currentFolderId);
  } catch (error) {
    Swal.close(); // ปิดสถานะการโหลด
    // ใช้ modal สำหรับข้อผิดพลาดในระบบ
    showAlert('error', 'เกิดข้อผิดพลาด', error.message, 'modal');
  } finally {
    handleCloseRenameModal();
  }
};

  // กำหนด parentFolderAccessLevel จาก folderPath หากมีข้อมูล
  const parentFolderAccessLevel = folderPath.length > 0 
    ? folderPath[folderPath.length - 1].access_level 
    : currentUser?.access_level || 'all';

  // เพิ่ม console.log เพื่อ debug
  useEffect(() => {
    console.log("Current parentFolderAccessLevel:", parentFolderAccessLevel);
  }, [parentFolderAccessLevel]);

  // ฟังก์ชันสำหรับตรวจสอบว่าอยู่ในโฟลเดอร์ Root หรือไม่
  const isRootFolder = () => {
    return currentFolderId && folderPath.length === 0;
  };

  // แก้ไขส่วนหัวข้อและเพิ่มเนื้อหาในหน้าแรก

return (
  <div className="max-w-6xl mx-auto p-6 min-h-screen bg-gray-50">
    {/* ปรับปรุงส่วนหัวข้อให้สวยงามและมีเนื้อหามากขึ้น */}
    <div className="mb-10 mt-6">
      <div className="text-center mb-8">
        <h1 className="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-blue-400 mb-2">
          File Manager
        </h1>
        <p className="text-gray-500 text-lg max-w-2xl mx-auto">
          จัดเก็บ แบ่งปัน และจัดการเอกสารของคุณอย่างมีประสิทธิภาพ
        </p>
      </div>
      
      {/* เพิ่มส่วนนี้เมื่อไม่ได้อยู่ในโฟลเดอร์ใด (หน้าแรก) */}
      {!currentFolderId && (
        <div className="bg-white rounded-xl shadow-md p-6 border border-blue-100">
          <div className="flex flex-col md:flex-row items-center">
            <div className="bg-blue-100 p-4 rounded-full mb-4 md:mb-0 md:mr-6">
              <Folder className="w-12 h-12 text-blue-600" />
            </div>
            <div className="text-center md:text-left">
              <h2 className="text-2xl font-bold text-gray-800 mb-2">ยินดีต้อนรับสู่ระบบจัดการไฟล์</h2>
              <p className="text-gray-600 mb-4">
                กรุณาเลือกโฟลเดอร์ที่ต้องการเข้าถึง เพื่อจัดการไฟล์และเอกสารของคุณ
              </p>
            </div>
          </div>
          
          {/* คำแนะนำการใช้งาน */}
          <div className="mt-6 grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="bg-blue-50 p-4 rounded-lg">
              <div className="flex items-center mb-2">
                <Folder className="w-5 h-5 text-blue-500 mr-2" />
                <h3 className="font-semibold text-gray-800">เข้าถึงโฟลเดอร์</h3>
              </div>
              <p className="text-sm text-gray-600">คลิกที่โฟลเดอร์เพื่อดูไฟล์และโฟลเดอร์ย่อยภายใน</p>
            </div>
            <div className="bg-blue-50 p-4 rounded-lg">
              <div className="flex items-center mb-2">
                <Download className="w-5 h-5 text-blue-500 mr-2" />
                <h3 className="font-semibold text-gray-800">อัพโหลดไฟล์</h3>
              </div>
              <p className="text-sm text-gray-600">เข้าไปในโฟลเดอร์ก่อน แล้วกดปุ่มอัพโหลด หรือลากไฟล์ลงในหน้าเว็บ</p>
            </div>
            <div className="bg-blue-50 p-4 rounded-lg">
              <div className="flex items-center mb-2">
                <Users className="w-5 h-5 text-blue-500 mr-2" />
                <h3 className="font-semibold text-gray-800">แชร์และจัดการสิทธิ์</h3>
              </div>
              <p className="text-sm text-gray-600">กำหนดสิทธิ์การเข้าถึงไฟล์และโฟลเดอร์ตามแผนกที่ต้องการ</p>
            </div>
          </div>
        </div>
      )}
    </div>

    {/* Current Folder Header - แสดงเมื่ออยู่ในโฟลเดอร์ */}
    {currentFolderId && !isRootFolder() && (
      <div className="mb-8 px-5 py-6 bg-blue-50 rounded-xl shadow-md border border-blue-200 transition-all">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
          <div className="flex items-center mb-3 md:mb-0">
            <div className="bg-blue-500 p-3 rounded-lg shadow-md mr-6">
              <Folder className={`${ICON_SIZES.medium} text-white`} />
            </div>
            <div>
              <h3 className="text-2xl font-bold text-gray-800 flex items-center">
                <span className="truncate max-w-md">
                  {folderPath.length > 0 
                    ? folderPath[folderPath.length - 1].name 
                    : (currentFolderId ? 'Root' : 'หน้าหลัก')}
                </span>
              </h3>
              {folderPath.length > 0 && (
                <div className="mt-1">
                  {renderAccessLevelBadges(folderPath[folderPath.length - 1].access_level)}
                </div>
              )}
              {folderPath.length > 0 && folderPath[folderPath.length - 1].access_level !== 'all' && (
                <div className="mt-1 text-xs text-blue-700">
                  หมายเหตุ: โฟลเดอร์ที่สร้างภายในนี้จะมีสิทธิ์การเข้าถึงไม่เกินกว่าโฟลเดอร์นี้
                </div>
              )}
            </div>
          </div>
          <button
            onClick={handleBack}
            className="bg-white text-blue-600 hover:text-blue-800 py-2 px-4 rounded-lg border border-blue-200 shadow-sm hover:bg-blue-50 transition flex items-center"
          >
            <ArrowLeft className={ICON_SIZES.default} />
            <span className="ml-1 font-medium">ย้อนกลับ</span>
          </button>
        </div>

        {/* Path indicator - แสดงเพียงครั้งเดียว */}
        <div className="mt-4 flex flex-wrap items-center">
          <span 
            className="bg-white px-3 py-1 rounded-md shadow-sm border border-blue-200 text-sm text-blue-800 font-medium mr-2 mb-2 flex items-center cursor-pointer"
            onClick={() => {
              // ตรวจสอบว่าผู้ใช้เป็น admin หรือไม่
              const isAdmin = !!localStorage.getItem('admin_token');
              
              if (isAdmin) {
                // Admin กลับไปหน้าแสดงโฟลเดอร์ทั้งหมด
                setCurrentFolderId(null);
                setFolderPath([]);
                fetchFolders(null);
                setFiles([]);
              } else {
                // ผู้ใช้ทั่วไปกลับไปที่โฟลเดอร์ Root
                initializeRootFolder();
              }
            }}
          >
            <Folder className="w-4 h-4 mr-1" />
            หน้าหลัก
          </span>
          {folderPath.map((folder, index) => (
            <div key={`breadcrumb-${folder.id}`} className="flex items-center mr-2 mb-2">
              <span className="text-gray-500 mx-1">/&nbsp;</span>
              <span 
                className={`px-3 py-1 rounded-md shadow-sm text-sm font-medium flex items-center cursor-pointer
                  ${index === folderPath.length - 1 
                    ? 'bg-blue-500 text-white' 
                    : 'bg-white text-blue-800 border border-blue-200'}`}
                onClick={() => {
                  const newPath = folderPath.slice(0, index + 1);
                  setFolderPath(newPath);
                  setCurrentFolderId(folder.id);
                  fetchFolders(folder.id);
                  fetchFiles(folder.id);
                }}
              >
                {index === folderPath.length - 1 && <Folder className="w-4 h-4 mr-1" />}
                {folder.name}
              </span>
            </div>
          ))}
        </div>
      </div>
    )}

    {/* แก้ไขเงื่อนไขการแสดง Combined Control & Folder View Toggle */}
    <div className="flex justify-between items-center mb-8 px-4">
      {/* Left-side: ปุ่มสร้างโฟลเดอร์ และ อัพโหลดไฟล์ (เป็นตัวหนังสือ) */}
      <div className="flex space-x-4">
        {/* ซ่อนปุ่มสร้างโฟลเดอร์เมื่ออยู่ในโฟลเดอร์ Root */}
        {!isRootFolder() && (
          <button
            onClick={handleShowCreateFolderModal}
            className="bg-white text-blue-600 py-2 px-4 rounded-lg border border-blue-400 shadow-sm hover:bg-blue-50 transition flex items-center space-x-2"
          >
            <Folder className={ICON_SIZES.default} />
            <span className="font-medium">สร้างโฟลเดอร์</span>
          </button>
        )}
        {/* ซ่อนปุ่มอัพโหลดไฟล์เมื่ออยู่ในโฟลเดอร์ Root */}
        {currentFolderId && !isRootFolder() && (
          <button
            type="button"
            onClick={() => hiddenFileInput.current.click()}
            className="bg-white text-blue-600 py-2 px-4 rounded-lg border border-blue-400 shadow-sm hover:bg-blue-50 transition flex items-center space-x-2"
          >
            <Download className={ICON_SIZES.default} />
            <span className="font-medium">อัพโหลดไฟล์</span>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={(e) => handleUpload(Array.from(e.target.files))}
              className="hidden"
              multiple
            />
          </button>
        )}
      </div>
      {/* Right-side: ปุ่มเปลี่ยนมุมมองโฟลเดอร์ (ซ่อนไว้เมื่อ folders ว่าง) */}
      {folders.length > 0 && (
        <div className="flex space-x-2">
          <button
            className={`p-2 rounded flex items-center justify-center ${foldersViewMode === 'grid' ? 'bg-blue-500 text-white' : 'bg-gray-100 text-gray-600'}`}
            onClick={() => setFoldersViewMode('grid')}
            title="มุมมองตาราง"
          >
            <LayoutGrid className={ICON_SIZES.default} />
          </button>
          <button
            className={`p-2 rounded flex items-center justify-center ${foldersViewMode === 'list' ? 'bg-blue-500 text-white' : 'bg-gray-100 text-gray-600'}`}
            onClick={() => setFoldersViewMode('list')}
            title="มุมมองรายการ"
          >
            <List className={ICON_SIZES.default} />
          </button>
        </div>
      )}
    </div>

    {/* Folder Section */}
    {folders.length > 0 && (
      <>
        {foldersViewMode === 'grid' ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-6 px-4">
            {folders.map((folder) => (
              <div
                key={folder.id}
                className="border p-4 bg-white rounded-lg shadow hover:bg-gray-100 transition"
              >
                <div className="flex justify-between items-start">
                  <div 
                    className="flex-grow cursor-pointer" 
                    onClick={() => handleFolderClick(folder)}
                  >
                    <div className="flex items-center mb-2">
                      <Folder className={`${ICON_SIZES.medium} text-blue-500 mr-2`} />
                      <h3 className="text-xl font-medium text-gray-800 line-clamp-1">{folder.name}</h3>
                    </div>
                    <div className="mt-2 min-h-[20px] flex justify-start w-full">
                      {renderAccessLevelBadges(folder.access_level)}
                    </div>
                  </div>
                  
                  {/* Dropdown menu with ellipsis button */}
                  <div className="dropdown dropdown-end">
                    <label tabIndex={0} className="btn btn-ghost btn-sm m-1">
                      <EllipsisVertical className="h-5 w-5 text-gray-500 hover:text-gray-700" />
                    </label>
                    <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-white rounded-md w-40">
                      <li>
                        <button 
                          onClick={() => handleShowRenameModal(folder)}
                          className="flex items-center px-3 py-2 hover:bg-gray-100"
                        >
                          <Edit2 className="h-4 w-4 mr-2 text-blue-500" />
                          <span>เปลี่ยนชื่อ</span>
                        </button>
                      </li>
                      {isAdmin() && (
                        <li>
                          <button 
                            onClick={() => handleEditAccess(folder)}
                            className="flex items-center px-3 py-2 hover:bg-gray-100"
                          >
                            <Users className="h-4 w-4 mr-2 text-blue-500" />
                            <span>แก้ไขสิทธิ์</span>
                          </button>
                        </li>
                      )}
                      <li>
                        <button 
                          onClick={() => handleDeleteFolder(folder.id)} 
                          className="flex items-center px-3 py-2 hover:bg-gray-100"
                        >
                          <Trash2 className="h-4 w-4 mr-2 text-red-500" />
                          <span>ลบโฟลเดอร์</span>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="container mx-auto px-4">
            <div className="bg-white rounded-lg shadow divide-y divide-gray-200">
              {/* ส่วนหัว */}
              <div className="flex items-center bg-gray-100 py-3 rounded-t-lg">
                <div className="w-2/3 px-4 text-left">
                  <span className="text-sm font-medium text-gray-700">ชื่อโฟลเดอร์</span>
                </div>
                <div className="w-1/3 px-4 flex justify-center">
                  <span className="text-sm font-medium text-gray-700">จัดการ</span>
                </div>
              </div>
              
              {/* ส่วนของข้อมูล */}
              <div className="divide-y divide-gray-200">
                {folders.map((folder) => (
                  <div
                    key={folder.id}
                    className="flex hover:bg-gray-50 transition"
                  >
                    {/* ชื่อโฟลเดอร์ */}
                    <div 
                      className="w-2/3 px-4 py-3 cursor-pointer"
                      onClick={() => handleFolderClick(folder)}
                    >
                      <div className="flex items-center min-h-[40px]">
                        <div className="flex items-center justify-center w-6 h-6 mr-2">
                          <Folder className={`${ICON_SIZES.default} text-blue-500`} />
                        </div>
                        <span className="font-medium text-gray-700">{folder.name}</span>
                      </div>
                      <div className="mt-1 min-h-[20px] flex justify-start w-full">
                        {renderAccessLevelBadges(folder.access_level)}
                      </div>
                    </div>
                    
                    {/* จัดการ */}
                    <div className="w-1/3 px-4 py-3 flex items-center justify-center">
                      <div className="dropdown dropdown-end">
                        <label tabIndex={0} className="btn btn-ghost btn-sm m-1">
                          <EllipsisVertical className="h-5 w-5 text-gray-500 hover:text-gray-700" />
                        </label>
                        <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-white rounded-md w-40">
                          <li>
                            <button 
                              onClick={() => handleShowRenameModal(folder)}
                              className="flex items-center px-3 py-2 hover:bg-gray-100   "
                            >
                              <Edit2 className="h-4 w-4 mr-2 text-blue-500" />
                              <span>เปลี่ยนชื่อ</span>
                            </button>
                          </li>
                          {isAdmin() && (
                            <li>
                              <button 
                                onClick={() => handleEditAccess(folder)}
                                className="flex items-center px-3 py-2 hover:bg-gray-100"
                              >
                                <Users className="h-4 w-4 mr-2 text-blue-500" />
                                <span>แก้ไขสิทธิ์</span>
                              </button>
                            </li>
                          )}
                          <li>
                            <button 
                              onClick={() => handleDeleteFolder(folder.id)} 
                              className="flex items-center px-3 py-2 hover:bg-gray-100"
                            >
                              <Trash2 className="h-4 w-4 mr-2 text-red-500" />
                              <span>ลบโฟลเดอร์</span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </>
    )}

   
    {/* แสดงข้อความเมื่อไม่มีข้อมูลในโฟลเดอร์ */}
    {currentFolderId && folders.length === 0 && files.length === 0 && (
      <div className="flex flex-col items-center justify-center p-36 bg-white rounded-lg shadow-sm mt-6 mx-4">
        <div className="flex items-center justify-center w-16 h-16 rounded-full bg-gray-100 mb-4">
          <Folder className="w-8 h-8 text-gray-400" />
        </div>
        <h4 className="text-xl font-semibold text-gray-700 mb-2">ไม่มีข้อมูลในโฟลเดอร์นี้</h4>
        <p className="text-gray-500 text-center max-w-md">
          คุณสามารถสร้างโฟลเดอร์ใหม่หรืออัพโหลดไฟล์ในโฟลเดอร์นี้ได้
        </p>

      </div>
    )}

   
    {/* แสดงข้อความเมื่อไม่มีไฟล์ในโฟลเดอร์ แต่มีโฟลเดอร์ย่อย - ไม่แสดงในหน้าแรก */}
    {currentFolderId && !isRootFolder() && folders.length > 0 && files.length === 0 && (
      <div className="flex flex-col items-center justify-center p-8 bg-white rounded-lg shadow-sm mt-16 mx-4">
        <div className="flex items-center justify-center w-12 h-12 rounded-full bg-gray-100 mb-3">
          <FileText className="w-6 h-6 text-gray-400" />
        </div>
        <h4 className="text-lg font-semibold text-gray-700 mb-2">ไม่มีไฟล์ในโฟลเดอร์นี้</h4>
        <p className="text-gray-500 text-center">
          คุณสามารถอัพโหลดไฟล์ในโฟลเดอร์นี้ได้
        </p>
        <div className="mt-4">
          <button 
            onClick={() => hiddenFileInput.current.click()}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
          >
            คลิกเพื่อเลือกไฟล์
          </button>
        </div>
      </div>
    )}

    {/* Dropzone แบบไม่แสดงรายละเอียด แต่ยังคงทำงานตามปกติ เมื่อลากไฟล์เข้ามาจึงจะแสดงข้อความ */}
    <div {...getRootProps()} className={`fixed inset-0 ${isDragActive ? 'pointer-events-auto' : 'pointer-events-none'}`}>
      <input {...getInputProps()} multiple />
    </div>

    {/* แสดง Overlay เมื่อมีการลากไฟล์เข้ามา */}
    {isDragActive && (
      <div className="fixed inset-0 bg-blue-100 bg-opacity-80 z-50 flex items-center justify-center">
        <div className="bg-white p-10 rounded-xl shadow-2xl text-center animate-fadeIn">
          <svg 
            className="w-16 h-16 text-blue-500 mx-auto mb-6" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24" 
            xmlns="http://www.w3.org/2000/svg"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth="2" 
              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
            />
          </svg>
          <h3 className="text-2xl font-bold text-gray-800 mb-2">ลากและวางไฟล์ที่นี่</h3>
          <p className="text-gray-600 mb-4">ปล่อยไฟล์เพื่ออัพโหลดไปยังโฟลเดอร์ {folderPath.length > 0 ? folderPath[folderPath.length - 1].name : 'ปัจจุบัน'}</p>
        </div>
      </div>
    )}

    {/* Files Section */}
    {currentFolderId && files.length > 0 && (
      <>
        <div className="flex justify-between items-center mb-4 mt-16 px-4">
          <h3 className="text-2xl font-bold text-gray-800 flex items-center">
            <FileText className={`${ICON_SIZES.medium} text-blue-500 mr-2`} />
            <span>ไฟล์ในโฟลเดอร์</span>
          </h3>
          <div className="flex space-x-2">
            <button
              className={`p-2 rounded flex items-center justify-center ${viewMode === 'grid' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
              onClick={() => setViewMode('grid')}
              title="มุมมองตาราง"
            >
              <LayoutGrid className={ICON_SIZES.default} />
            </button>
            <button
              className={`p-2 rounded flex items-center justify-center ${viewMode === 'list' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
              onClick={() => setViewMode('list')}
              title="มุมมองรายการ"
            >
              <List className={ICON_SIZES.default} />
            </button>
          </div>
        </div>
        {viewMode === 'list' ? (
          <div className="w-full px-4 overflow-x-auto overflow-y-visible min-h-[600px]">
            <div className="bg-white rounded-lg shadow divide-y divide-gray-200 min-w-[768px]">
              {/* ส่วนหัว */}
              <div className="flex items-center bg-gray-100 py-3 rounded-t-lg">
                <div className="w-1/3 px-4 text-left">
                  <span className="text-sm font-medium text-gray-700">ชื่อไฟล์</span>
                </div>
                <div className="w-1/3 px-4 text-left">
                  <span className="text-sm font-medium text-gray-700">วันที่อัพโหลด</span>
                </div>
                <div className="w-1/3 px-4 flex justify-center">
                  <span className="text-sm font-medium text-gray-700">จัดการ</span>
                </div>
              </div>
              
              {/* ส่วนของข้อมูล */}
              <div className="divide-y divide-gray-200">
                {files.map((file) => (
                  <div
                    key={file.id}
                    className="flex items-center hover:bg-gray-50 transition"
                  >
                    {/* ชื่อไฟล์ */}
                    <div className="w-1/3 px-4 py-3 min-h-[40px] flex items-center">
                      <div className="flex items-center space-x-2 max-w-full">
                        <div className="flex items-center justify-center w-6 h-6 flex-shrink-0">
                          {file.file_type.startsWith('image/')
                            ? <Image className={`${ICON_SIZES.default} text-blue-500`} />
                            : <FileText className={`${ICON_SIZES.default} text-blue-500`} />}
                        </div>
                        <span className="text-sm text-gray-700 truncate">{file.name}</span>
                      </div>
                    </div>
                    
                    {/* วันที่อัพโหลด */}
                    <div className="w-1/3 px-4 py-3 min-h-[60px] flex items-center">
                      <span className="text-sm text-gray-500">{formatDate(file.upload_date)}</span>
                    </div>
                    
                    {/* จัดการ */}
                    <div className="w-1/3 px-4 py-3 min-h-[60px] flex items-center justify-center">
                      <div className="dropdown dropdown-end">
                        <label tabIndex={0} className="btn btn-ghost btn-sm m-1">
                          <EllipsisVertical className="h-5 w-5 text-gray-500 hover:text-gray-700" />
                        </label>
                        <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-white rounded-md w-40">
                          <li>
                            <button 
                              onClick={() => handleDownload(file.id, file.name)}
                              className="flex items-center px-3 py-2 hover:bg-gray-100"
                            >
                              <Download className="h-4 w-4 mr-2 text-blue-500" />
                              <span>ดาวน์โหลด</span>
                            </button>
                          </li>
                          {isAdmin() && (
                            <li>
                              <button 
                                onClick={() => handleEditAccess(file, false)}
                                className="flex items-center px-3 py-2 hover:bg-gray-100"
                              >
                                <Users className="h-4 w-4 mr-2 text-blue-500" />
                                <span>แก้ไขสิทธิ์</span>
                              </button>
                            </li>
                          )}
                          <li>
                            <button 
                              onClick={() => handleDelete(file.id)}
                              className="flex items-center px-3 py-2 hover:bg-gray-100"
                            >
                              <Trash2 className="h-4 w-4 mr-2 text-red-500" />
                              <span>ลบไฟล์</span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 px-4">
            {files.map((file) => (
              <div key={file.id} className="border p-4 rounded-lg shadow bg-white transition hover:shadow-lg">
                <div className="flex items-center justify-between mb-3">
                  <div className="flex items-center space-x-2 overflow-hidden">
                    {file.file_type.startsWith('image/')
                      ? <Image className={`${ICON_SIZES.medium} text-blue-500 flex-shrink-0`} />
                      : <FileText className={`${ICON_SIZES.medium} text-blue-500 flex-shrink-0`} />}
                    <h4 className="font-medium text-gray-800 truncate">{file.name}</h4>
                  </div>
                  
                  {/* Dropdown menu for file actions */}
                  <div className="dropdown dropdown-end">
                    <label tabIndex={0} className="btn btn-ghost btn-sm m-1">
                      <EllipsisVertical className="h-5 w-5 text-gray-500 hover:text-gray-700" />
                    </label>
                    <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-white rounded-md w-40">
                      <li>
                        <button 
                          onClick={() => handleDownload(file.id, file.name)}
                          className="flex items-center px-3 py-2 hover:bg-gray-100"
                        >
                          <Download className="h-4 w-4 mr-2 text-blue-500" />
                          <span>ดาวน์โหลด</span>
                        </button>
                      </li>
                      {isAdmin() && (
                        <li>
                          <button 
                            onClick={() => handleEditAccess(file, false)}
                            className="flex items-center px-3 py-2 hover:bg-gray-100"
                          >
                            <Users className="h-4 w-4 mr-2 text-blue-500" />
                            <span>แก้ไขสิทธิ์</span>
                          </button>
                        </li>
                      )}
                      <li>
                        <button 
                          onClick={() => handleDelete(file.id)}
                          className="flex items-center px-3 py-2 hover:bg-gray-100"
                        >
                          <Trash2 className="h-4 w-4 mr-2 text-red-500" />
                          <span>ลบไฟล์</span>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <p className="text-sm text-gray-500">{formatDate(file.upload_date)}</p>
              </div>
            ))}
          </div>
        )}
      </>
    )}

    {/* ส่วน Modal */}
    <CreateFolderModal 
      isOpen={isModalOpen}
      onClose={handleCloseModal}
      onSubmit={handleCreateFolder}
      folderAccessLevel={parentFolderAccessLevel}
    />
    <EditAccessLevelModal
      isOpen={isEditAccessModalOpen}
      onClose={() => setIsEditAccessModalOpen(false)}
      onSubmit={handleUpdateAccess}
      item={selectedItem}
      isAdmin={isAdmin()}
    />
    {isRenameModalOpen && (
      <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-white rounded-lg shadow-xl p-6 w-full max-w-md mx-4 animate-fadeIn">
          <h2 className="text-2xl font-bold mb-4">เปลี่ยนชื่อโฟลเดอร์</h2>
          <div className="mb-4">
            <label htmlFor="folderName" className="block text-gray-700 mb-2">
              ชื่อโฟลเดอร์
            </label>
            <input
              id="folderName"
              type="text"
              className="w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="ระบุชื่อโฟลเดอร์..."
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              autoFocus
            />
          </div>
          <div className="flex justify-end space-x-2">
            <button
              onClick={handleCloseRenameModal}
              className="px-4 py-2 border rounded-md hover:bg-gray-100"
            >
              ยกเลิก
            </button>
            <button
              onClick={handleRenameFolder}
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            >
              บันทึก
            </button>
          </div>
        </div>
      </div>
    )}
  </div>
);
};

export default FilesManager;