import React from "react";
import { Card } from "../ui/card";
import { 
  Megaphone, AlertTriangle, Clipboard, Calendar, BookOpen, 
  ArrowLeft, CircleHelp, FolderOpen, BookText 
} from "lucide-react";
import { useNavigate } from "react-router-dom";


const DataManagement = () => {
  const navigate = useNavigate();

  const categories = [
    {
      title: "จัดการประกาศ",
      description: "ดูและจัดการประกาศต่างๆ",
      icon: <Megaphone className="h-8 w-8 text-indigo-500 mb-4" />,
      onClick: () => navigate("/admin/dashboard/announcement")
    },
    {
      title: "จัดการสถานะแจ้งปัญหา",
      description: "ดูและจัดการสถานะแจ้งปัญหา",
      icon: <AlertTriangle className="h-8 w-8 text-red-500 mb-4" />,
      onClick: () => navigate("/admin/dashboard/problem-status")
    },
    {
      title: "จัดการแบบฟอร์มของบริษัท",
      description: "ดูและจัดการแบบฟอร์มบริษัท",
      icon: <Clipboard className="h-8 w-8 text-green-500 mb-4" />,
      onClick: () => navigate("/admin/dashboard/company_forms_management")
    },
    {
      title: "จัดการกิจกรรม",
      description: "ดูและจัดการกิจกรรม",
      icon: <Calendar className="h-8 w-8 text-orange-500 mb-4" />,
      onClick: () => navigate("/admin/dashboard/event_management")
    },
    {
      title: "จัดการคลังความรู้",
      description: "ดูและจัดการคลังความรู้",
      icon: <BookOpen className="h-8 w-8 text-blue-500 mb-4" />,
      onClick: () => navigate("/admin/dashboard/knowledge_management")
    },
    {
      title: "จัดการคำถามที่พบบ่อย",
      description: "ดูแลจัดการคำถามที่พบบ่อย",
      icon: <CircleHelp className="h-8 w-8 text-yellow-600 mb-4" />,
      onClick: () => navigate("/admin/dashboard/faq_management")
    },
    // เพิ่มปุ่มใหม่ - จัดการคู่มือพนักงาน
    {
      title: "จัดการคู่มือพนักงาน",
      description: "ดูและจัดการคู่มือพนักงานของแต่ละแผนก",
      icon: <BookText className="h-8 w-8 text-purple-500 mb-4" />,
      onClick: () => navigate("/admin/dashboard/handbook_management")
    },
    // เพิ่มปุ่มใหม่ - จัดการไฟล์และโฟลเดอร์
    {
      title: "จัดการไฟล์และโฟลเดอร์",
      description: "จัดการระบบไฟล์และโฟลเดอร์",
      icon: <FolderOpen className="h-8 w-8 text-teal-500 mb-4" />,
      onClick: () => navigate("/admin/dashboard/files_management")
    }
  ];

  return (
    <div className="p-8 bg-gray-50 min-h-screen">
      <div className='flex justify-end items-center'>
        <button
          onClick={() => navigate('/admin/dashboard')}
          className="flex gap-2 bg-none text-gray-600 px-4 py-2 mb-5 rounded-md hover:text-gray-800 transition-colors"
        >
          <ArrowLeft className="h-5 w-5" />
          ย้อนกลับ
        </button>
      </div>
      <h1 className="text-3xl font-bold text-gray-800 mb-8">จัดการข้อมูล</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {categories.map((cat, idx) => (
          <Card
            key={idx}
            className="p-6 hover:bg-gray-50 transition-colors cursor-pointer"
            onClick={cat.onClick}
          >
            <div className="flex flex-col items-center text-center">
              {cat.icon}
              <h3 className="font-semibold text-gray-900">{cat.title}</h3>
              <p className="text-sm text-gray-600 mt-2">{cat.description}</p>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default DataManagement;