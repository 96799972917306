import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [hasNavigated, setHasNavigated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const tokenExpiration = localStorage.getItem("tokenExpiration");


    // สร้าง array ของ paths ที่อนุญาตให้เข้าถึงได้โดยไม่ต้อง authenticate
    const publicPaths = ['/login', '/adminlogin'];
    const isPublicPath = publicPaths.includes(location.pathname);

    if (!token) {
      setIsAuthenticated(false);
      if (!isPublicPath && !hasNavigated) {
        setHasNavigated(true);
        navigate('/');
      }
    } else {
      if (tokenExpiration) {
        try {
          // แปลงวันที่จากรูปแบบไทยกลับเป็น Date object
          const expDate = new Date(tokenExpiration);
          if (isNaN(expDate.getTime()) || expDate < new Date()) {
            // เมื่อ token หมดอายุให้ลบข้อมูลออกจาก localStorage
            localStorage.removeItem("token");
            localStorage.removeItem("tokenExpiration");
            localStorage.removeItem("tokenExpirationDisplay"); // เพิ่มบรรทัดนี้
            localStorage.removeItem("currentUser"); // ลบข้อมูล user ด้วย
            
            setIsAuthenticated(false);
            if (!isPublicPath && !hasNavigated) {
              setHasNavigated(true);
              navigate('/');
            }
          } else {
            setIsAuthenticated(true);
          }
        } catch (e) {
          console.error("Error parsing date:", e);
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          localStorage.removeItem("tokenExpirationDisplay"); // เพิ่มบรรทัดนี้
          localStorage.removeItem("currentUser"); // ลบข้อมูล user ด้วย
          setIsAuthenticated(false);
        }
      } else {
        // ไม่มี tokenExpiration
        setIsAuthenticated(!!localStorage.getItem("token"));
      }
    }
  }, [navigate, location, hasNavigated]);

  return (
    <AuthContext.Provider value={{ isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);