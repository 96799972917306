import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { format, isValid } from 'date-fns'; // ใช้ date-fns สำหรับจัดรูปแบบวันที่

const ReportStatus = () => {
  const [reportstatus, setReportstatus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios
      .get('/problem_status')
      .then((response) => {
        // เรียงข้อมูลตาม order_position จากมากไปน้อย หรือ id ถ้าไม่มี order_position
        const sortedData = [...response.data]
          .sort((a, b) => {
            // ถ้าทั้งคู่มี order_position ให้เรียงตาม order_position จากมากไปน้อย
            if (a.order_position !== undefined && b.order_position !== undefined) {
              return b.order_position - a.order_position;
            }
            // ถ้าไม่มี order_position ให้เรียงตาม id แทน (ใหม่สุดก่อน)
            return b.id - a.id;
          })
          .slice(0, 20);  // เลือกเฉพาะ 20 รายการแรก
        
        setReportstatus(sortedData); // ตั้งค่าข้อมูลที่เรียงแล้ว
        setLoading(false); // เปลี่ยนสถานะการโหลด
      })
      .catch((error) => {
        setError(error); // จัดการข้อผิดพลาด
        setLoading(false); // เปลี่ยนสถานะการโหลด
      });
  }, []); // empty array เพื่อให้ทำงานครั้งเดียวเมื่อคอมโพเนนต์โหลด

  if (loading) {
    return (
      <div className="bg-white p-1 rounded-lg">
        <h3 className="text-lg text-stone-800 font-bold">🚨 สถานะแจ้งปัญหา</h3>
        <div className="mt-0">
          <div className="flex flex-col gap-1">
            {/* ส่วนหัวข้อ */}
            <div className="flex justify-start p-1 my-4 mb-2">
              <div className="w-[15%]">
                <p className="text-sm font-semibold">วันที่</p>
              </div>
              <div className="w-[65%] mr-10">
                <p className="text-sm font-semibold">เรื่อง</p>
              </div>
              <div className="w-[20%] flex justify-end">
                <p className="text-sm font-semibold">สถานะ</p>
              </div>
            </div>
            
            {/* ส่วน Skeleton Loading */}
            <div className="max-h-[160px] overflow-y-auto pr-2 scrollbar-hover-show">
              <div className="flex flex-col gap-1">
                {[...Array(5)].map((_, index) => (
                  <div 
                    key={index} 
                    className="flex justify-start p-2 rounded-lg border-b animate-pulse"
                  >
                    <div className="w-[15%] mr-3">
                      <div className="h-4 w-12 bg-gray-200 rounded"></div>
                    </div>
                    <div className="w-[65%]">
                      <div className="h-4 w-full bg-gray-200 rounded"></div>
                    </div>
                    <div className="w-[20%] flex justify-end">
                      <div className="h-4 w-16 bg-gray-200 rounded"></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return <div>เกิดข้อผิดพลาด: {error.message}</div>;
  }

  return (
    <div className="bg-white p-1 rounded-lg">
      <h3 className="text-lg text-stone-800 font-bold">🚨 สถานะแจ้งปัญหา</h3>
      <div className="mt-0">
        {reportstatus.length === 0 ? (
          <p className="text-sm">ไม่มีข้อมูลรายงานสถานะ</p>
        ) : (
          <div className="flex flex-col gap-1">
            {/* ส่วนหัวข้อ */}
            <div className="flex justify-start p-1 my-4 mb-2">
              <div className="w-[15%]">
                <p className="text-sm font-semibold">วันที่</p>
              </div>
              <div className="w-[65%] mr-10">
                <p className="text-sm font-semibold">เรื่อง</p>
              </div>
              <div className="w-[20%] flex justify-end">
                <p className="text-sm font-semibold">สถานะ</p>
              </div>
            </div>
            
            {/* ส่วนเนื้อหา - จำกัด 4 รายการและมี scroll */}
            <div className="max-h-[160px] overflow-y-auto pr-2 scrollbar-hover-show">
              <div className="flex flex-col gap-1">
                {reportstatus.map((status) => {
                  const date = new Date(status.date);
                  return (
                    <div key={status.id} className="flex justify-start p-2 rounded-lg border-b">
                      <div className="w-[15%] mr-3">
                        <p className="text-sm">
                          {isValid(date) ? format(date, 'd/M/yy') : 'วันที่ไม่ถูกต้อง'}
                        </p>
                      </div>
                      <div className="w-[65%]">
                        <p className="text-sm">{status.title}</p>
                      </div>
                      <div className="w-[20%] flex justify-end">
                        <p className={`text-sm font-medium ${
                          status.status === 'รอดำเนินการ' ? 'text-yellow-600' : 
                          status.status === 'เสร็จสิ้น' ? 'text-green-600' : 
                          'text-red-600'
                        }`}>
                          {status.status}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportStatus;
