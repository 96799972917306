import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { 
  FileText, Download, BookOpen, ChevronDown,
  Calculator, DollarSign, Warehouse, Users, Laptop, File
} from "lucide-react";

const MySwal = withReactContent(Swal);

const EmployeeHandbook = () => {
  const navigate = useNavigate();
  const [activeDepartment, setActiveDepartment] = useState(null);
  const [loadingHandbook, setLoadingHandbook] = useState(null);
  const [departmentHandbooks, setDepartmentHandbooks] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
      return;
    }

    // ดึงข้อมูลคู่มือจาก API
    const fetchHandbooks = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get("/user-handbooks", {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        // ข้อมูล icon สำหรับแต่ละแผนก
        const deptIcons = {
          'acc': <Calculator size={20} className="text-blue-500" />,
          'fin': <DollarSign size={20} className="text-green-500" />,
          'wh': <Warehouse size={20} className="text-amber-600" />,
          'hr': <Users size={20} className="text-purple-500" />,
          'it': <Laptop size={20} className="text-indigo-500" />
        };

        // จัดกลุ่มคู่มือตามแผนก
        const handbooks = {};
        
        // จัดเรียงข้อมูลก่อนตามลำดับ display_order (จากน้อยไปมาก)
        const sortedData = [...response.data].sort((a, b) => {
          if (a.department !== b.department) {
            return a.department.localeCompare(b.department);
          }
          
          // แปลง display_order เป็นตัวเลขเพื่อเรียงลำดับ
          const aOrderStr = a.display_order ? a.display_order.split('-')[1] : '0';
          const bOrderStr = b.display_order ? b.display_order.split('-')[1] : '0';
          const aOrder = parseInt(aOrderStr) || 0;
          const bOrder = parseInt(bOrderStr) || 0;
          
          return aOrder - bOrder;
        });
        
        // จัดกลุ่มข้อมูลตามแผนก
        sortedData.forEach(handbook => {
          if (!handbooks[handbook.department]) {
            handbooks[handbook.department] = {
              name: handbook.department_name,
              icon: deptIcons[handbook.department] || <BookOpen size={20} className="text-gray-500" />,
              items: []
            };
          }
          
          handbooks[handbook.department].items.push({
            id: handbook.id,
            name: handbook.name,
            file: handbook.file_url,
            description: handbook.description,
            order: handbook.display_order
          });
        });

        setDepartmentHandbooks(handbooks);
      } catch (error) {
        console.error("Error fetching handbooks:", error);
        // ใช้ข้อมูลจำลองเมื่อไม่สามารถเชื่อมต่อ API ได้
        const mockData = {
          'acc': {
            name: 'แผนกบัญชี',
            icon: <Calculator size={20} className="text-blue-500" />,
            items: [
              { 
                id: 1,
                name: "คู่มือการบันทึกบัญชี", 
                file: "/uploads/guide_book/accounting.pdf", 
                description: "คู่มือการปฏิบัติงานแผนกบัญชี ระเบียบการบันทึกบัญชี"
              },
              {
                id: 2,
                name: "คู่มือการจัดทำงบการเงิน",
                file: "/uploads/guide_book/financial_statements.pdf",
                description: "แนวทางการจัดทำงบการเงินประจำเดือนและประจำปี"
              }
            ]
          },
          'fin': {
            name: 'แผนกการเงิน',
            icon: <DollarSign size={20} className="text-green-500" />,
            items: [
              {
                id: 3,
                name: "คู่มือระบบควบคุมภายใน",
                file: "/guide_book/finance.pdf",
                description: "ข้อปฏิบัติและแนวทางการดำเนินงานด้านการเงิน"
              },
              {
                id: 4,
                name: "คู่มือการรับ-จ่ายเงิน",
                file: "/guide_book/payment.pdf",
                description: "ขั้นตอนการรับและจ่ายเงินตามระเบียบบริษัท"
              }
            ]
          },
          'wh': {
            name: 'แผนกคลังสินค้า',
            icon: <Warehouse size={20} className="text-amber-600" />,
            items: [
              {
                id: 5,
                name: "คู่มือการจัดการคลังสินค้า",
                file: "/guide_book/warehouse.pdf",
                description: "คู่มือการบริหารจัดการคลังสินค้า การจัดเก็บและเบิกจ่าย"
              }
            ]
          },
          'hr': {
            name: 'แผนกบุคคล-ธุรการ',
            icon: <Users size={20} className="text-purple-500" />,
            items: [
              {
                id: 6,
                name: "คู่มือระเบียบงานบุคคล",
                file: "/guide_book/hr-admin.pdf",
                description: "ระเบียบงานบุคคล สวัสดิการพนักงาน และระเบียบการลา"
              },
              {
                id: 7,
                name: "คู่มือการประเมินผลงาน",
                file: "/guide_book/performance.pdf",
                description: "แนวทางการประเมินผลการปฏิบัติงานประจำปี"
              }
            ]
          },
          'it': {
            name: 'แผนกไอที',
            icon: <Laptop size={20} className="text-indigo-500" />,
            items: [
              {
                id: 8,
                name: "คู่มือความปลอดภัยทางไซเบอร์",
                file: "/guide_book/it.pdf",
                description: "คู่มือนโยบายด้านเทคโนโลยีสารสนเทศ และแนวปฏิบัติด้านความปลอดภัย"
              },
              {
                id: 9,
                name: "คู่มือการใช้ระบบ ERP",
                file: "/guide_book/erp.pdf",
                description: "คู่มือการใช้งานระบบ ERP สำหรับพนักงาน"
              }
            ]
          }
        };
        
        setDepartmentHandbooks(mockData);
      } finally {
        setIsLoading(false);
      }
    };

    fetchHandbooks();
  }, [navigate]);

  // แก้ไขฟังก์ชัน handleDownload ให้ใช้ URL โดยตรง
  const handleDownload = (file, handbookId) => {
    setLoadingHandbook(handbookId);
    
    try {
      // ปรับ URL ให้ถูกต้องโดยไม่ใช้ fixFileUrl
      const downloadUrl = `${process.env.REACT_APP_API_URL.replace('/api', '')}${file}`;
      
      console.log(`Downloading handbook ${handbookId} with URL:`, downloadUrl);
      
      // เปิดไฟล์โดยตรงไม่ต้องตรวจสอบ
      window.open(downloadUrl, '_blank');
      
      // แสดง Toast สำเร็จ
      MySwal.fire({
        toast: true,
        position: 'top-end',
        icon: 'success',
        title: 'เริ่มดาวน์โหลดไฟล์แล้ว',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      });
      
      // หยุดการโหลด
      setTimeout(() => {
        setLoadingHandbook(null);
      }, 800);
    } catch (error) {
      console.error("Error in download process:", error);
      setLoadingHandbook(null);
      
      // แสดงข้อความ error
      MySwal.fire({
        title: 'เกิดข้อผิดพลาด',
        text: 'ไม่สามารถดาวน์โหลดไฟล์ได้ในขณะนี้ โปรดลองอีกครั้งในภายหลัง',
        icon: 'error',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#3085d6'
      });
    }
  };

  // ฟังก์ชันเพื่อดูไอคอนไฟล์ตามนามสกุล
  const getFileIcon = (fileUrl) => {
    if (!fileUrl) return <File className="h-5 w-5 text-gray-500" />;
    
    const ext = fileUrl.split('.').pop().toLowerCase();
    
    // เช็คประเภทไฟล์จากนามสกุล
    switch (ext) {
      case 'pdf':
        return <File className="h-5 w-5 text-red-500" />;
      case 'doc':
      case 'docx':
        return <FileText className="h-5 w-5 text-blue-600" />;
      default:
        return <File className="h-5 w-5 text-gray-500" />;
    }
  };

  return (
    <div className="flex flex-col items-center py-10 px-4 md:px-10 bg-gradient-to-b from-blue-50 to-gray-100 min-h-screen">
      {isLoading ? (
        // แสดงสถานะกำลังโหลด
        <div className="w-full max-w-4xl h-64 flex justify-center items-center">
          <div className="flex flex-col items-center">
            <div className="animate-spin rounded-full h-12 w-12 border-4 border-t-blue-500 border-blue-200"></div>
            <p className="text-gray-500 mt-4">กำลังโหลดข้อมูล...</p>
          </div>
        </div>
      ) : (
        // แสดงเนื้อหาเมื่อโหลดเสร็จแล้ว
        <div className="w-full max-w-4xl bg-white rounded-2xl shadow-xl overflow-hidden">
          {/* Header section */}
          <div className="bg-gradient-to-r from-gray-600 to-gray-800 text-white p-8 relative overflow-hidden">
            <div className="absolute right-0 top-0 w-64 h-64 opacity-10">
              <BookOpen className="w-full h-full" strokeWidth={1} />
            </div>
            <h2 className="text-3xl md:text-4xl font-bold mb-3 flex items-center">
              <BookOpen className="mr-3" size={32} strokeWidth={2} /> คู่มือพนักงาน
            </h2>
            <p className="text-blue-100 max-w-xl text-sm md:text-base">
              เอกสารสำคัญที่รวบรวมแนวทางปฏิบัติ ขั้นตอนการทำงาน และข้อมูลจำเป็นสำหรับพนักงานทุกแผนก 
              เพื่อให้การปฏิบัติงานเป็นไปอย่างมีประสิทธิภาพและเป็นไปตามมาตรฐานของบริษัท
            </p>
          </div>

          {/* Main content */}
          <div className="p-6 md:p-8">
            <div className="flex flex-col gap-4">
              {Object.keys(departmentHandbooks).map((deptKey) => {
                const dept = departmentHandbooks[deptKey];
                return (
                  <div 
                    key={deptKey}
                    className={`border rounded-xl overflow-hidden transition-all duration-300 shadow hover:shadow-md ${
                      activeDepartment === deptKey ? 'ring-2 ring-blue-500' : ''
                    }`}
                  >
                    <div 
                      className="px-5 py-4 cursor-pointer flex justify-between items-center bg-gray-50 hover:bg-gray-100 transition-colors"
                      onClick={() => setActiveDepartment(activeDepartment === deptKey ? null : deptKey)}
                    >
                      <div className="flex items-center gap-3">
                        <div className="p-2 rounded-full bg-white shadow-sm">
                          {dept.icon}
                        </div>
                        <div className="text-left">
                          <h3 className="font-medium text-gray-800">{dept.name}</h3>
                          <p className="text-xs text-gray-500">{dept.items.length} คู่มือ</p>
                        </div>
                      </div>
                      <ChevronDown 
                        size={18} 
                        className={`text-gray-400 transition-transform duration-300 ${
                          activeDepartment === deptKey ? 'transform rotate-180' : ''
                        }`} 
                      />
                    </div>
                    
                    {activeDepartment === deptKey && (
                      <div className="divide-y divide-gray-100">
                        {dept.items.map((handbook) => (
                          <div key={handbook.id} className="p-4 bg-white hover:bg-gray-50 transition-colors">
                            <div className="flex items-start mb-3">
                              {getFileIcon(handbook.file)}
                              <div className="ml-3 text-left">
                                <h4 className="font-medium text-gray-800">{handbook.name}</h4>
                                <p className="text-sm text-gray-600 mt-1">{handbook.description}</p>
                              </div>
                            </div>
                            <button 
                              onClick={() => handleDownload(handbook.file, handbook.id)}
                              disabled={loadingHandbook === handbook.id}
                              className={`
                                flex items-center justify-center gap-2 px-4 py-2 bg-blue-50 text-blue-700 rounded-lg
                                hover:bg-blue-100 transition-colors w-full font-medium text-sm
                                ${loadingHandbook === handbook.id ? 'opacity-70 cursor-not-allowed' : ''}
                              `}
                            >
                              {loadingHandbook === handbook.id ? (
                                <div className="animate-spin rounded-full h-4 w-4 border-2 border-blue-700 border-t-transparent"></div>
                              ) : (
                                <Download size={16} />
                              )}
                              <span>{loadingHandbook === handbook.id ? 'กำลังดาวน์โหลด...' : 'ดาวน์โหลด'}</span>
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          
          {/* Footer */}
          <div className="border-t p-5 text-center text-sm text-gray-500 bg-gray-50">
            <p>หากพบปัญหาในการดาวน์โหลดหรือคู่มือไม่เป็นปัจจุบัน กรุณาติดต่อฝ่ายไอที</p>
            <div className="flex justify-center items-center gap-1 mt-2">
              <FileText size={14} className="text-gray-400" />
              <span>อัปเดตล่าสุด: กุมภาพันธ์ 2025</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmployeeHandbook;
