import React, { useState, useEffect } from 'react';
import { Card } from "../ui/card";
import { Save, X, Eye, EyeOff } from 'lucide-react';

const UserModal = ({ isModalOpen, setIsModalOpen, formData, setFormData, handleSubmit, showPassword, setShowPassword, editingUser }) => {
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  // Reset errors and confirm password when modal opens/closes
  useEffect(() => {
    if (isModalOpen) {
      setPasswordError("");
      setConfirmPassword("");
      setConfirmPasswordError("");
    }
  }, [isModalOpen]);

  // ตรวจสอบความซับซ้อนของรหัสผ่าน
  const validatePassword = (password) => {
    if (!password) return true; // ถ้าไม่ได้กรอกรหัสผ่านและเป็นการแก้ไขข้อมูล ให้ผ่าน
    if (editingUser && !password) return true;
    
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).{10,}$/;
    
    if (!passwordRegex.test(password)) {
      setPasswordError("รหัสผ่านต้องประกอบด้วยตัวอักษรพิมพ์เล็ก พิมพ์ใหญ่ และอักขระพิเศษ รวมกันอย่างน้อย 10 ตัว");
      return false;
    }
    
    setPasswordError("");
    return true;
  };

  // ตรวจสอบการยืนยันรหัสผ่าน
  const validateConfirmPassword = () => {
    if (!formData.password && editingUser) return true;
    
    if (formData.password !== confirmPassword) {
      setConfirmPasswordError("รหัสผ่านและรหัสผ่านยืนยันไม่ตรงกัน");
      return false;
    }
    
    setConfirmPasswordError("");
    return true;
  };

  // ตรวจสอบก่อนส่งฟอร์ม
  const onSubmit = (e) => {
    e.preventDefault();
    
    const isPasswordValid = validatePassword(formData.password);
    const isConfirmPasswordValid = validateConfirmPassword();
    
    if (!isPasswordValid || !isConfirmPasswordValid) {
      return;
    }
    
    handleSubmit(e);
  };

  return (
    isModalOpen && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
        <Card className="w-full max-w-md p-7">
          <div className='justify-end flex'>
            <button
              onClick={() => setIsModalOpen(false)}
              className="text-gray-500 hover:text-gray-700"
            >
              <X className="h-6 w-6" />
            </button>
          </div>
          <div className="flex justify-center items-center mb-6">
            <h2 className="text-2xl font-bold text-gray-800 mb-2">
              {editingUser ? 'แก้ไขผู้ใช้' : 'เพิ่มผู้ใช้'}
            </h2>
          </div>

          <form onSubmit={onSubmit} className="space-y-4">
            <div>
              <label className="block text-base font-medium text-gray-700">รหัสสมาชิก</label>
              <input
                type="text"
                value={formData.member_id}
                placeholder="รหัสสมาชิก"
                pattern="\d*"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    member_id: e.target.value.replace(/\D/g, '')
                  })
                }
                required
                disabled={editingUser}
                className="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            {editingUser && (
              <div>
                <label className="block text-base font-medium text-gray-700">รหัสสมาชิกใหม่</label>
                <input
                  type="text"
                  placeholder="รหัสสมาชิกใหม่ (ถ้าต้องการเปลี่ยน)"
                  pattern="\d*"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      new_member_id: e.target.value.replace(/\D/g, '')
                    })
                  }
                  className="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500"
                />
                <p className="mt-1 text-sm text-red-500">
                  คำเตือน: การเปลี่ยนรหัสสมาชิกอาจส่งผลต่อข้อมูลที่เชื่อมโยงกัน
                </p>
              </div>
            )}

            <div>
              <label className="block text-base font-medium text-gray-700">รหัสผ่าน</label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  required={!editingUser}
                  className={`mt-1 block w-full border ${passwordError ? "border-red-500" : "border-gray-300"} rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500`}
                  value={formData.password}
                  onChange={(e) => {
                    setFormData({ ...formData, password: e.target.value });
                    validatePassword(e.target.value);
                  }}
                  placeholder={editingUser ? "รหัสผ่านใหม่ (เว้นว่างถ้าไม่ต้องการเปลี่ยน)" : "รหัสผ่าน"}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <EyeOff className="h-5 w-5" /> : <Eye className="h-5 w-5" />}
                </button>
              </div>
              {passwordError && <p className="mt-1 text-sm text-red-500">{passwordError}</p>}
              <p className="mt-1 text-xs text-gray-500">
                รหัสผ่านต้องประกอบด้วยตัวอักษรพิมพ์เล็ก พิมพ์ใหญ่ และอักขระพิเศษ รวมกันอย่างน้อย 10 ตัว
              </p>
            </div>

            <div>
              <label className="block text-base font-medium text-gray-700">ยืนยันรหัสผ่าน</label>
              <input
                type={showPassword ? "text" : "password"}
                required={!editingUser && Boolean(formData.password)}
                className={`mt-1 block w-full border ${confirmPasswordError ? "border-red-500" : "border-gray-300"} rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500`}
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  if (formData.password === e.target.value) {
                    setConfirmPasswordError("");
                  }
                }}
                placeholder="ยืนยันรหัสผ่าน"
              />
              {confirmPasswordError && <p className="mt-1 text-sm text-red-500">{confirmPasswordError}</p>}
            </div>

            <div>
              <label className="block text-base font-medium text-gray-700">ชื่อ</label>
              <input
                type="text"
                required
                className="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                placeholder={editingUser ? (formData.name || "ชื่อผู้ใช้งาน") : "ชื่อผู้ใช้งาน"}
              />
            </div>

            <div>
              <label className="block text-base font-medium text-gray-700">ระดับสิทธิ์</label>
              <select
                className="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500"
                value={formData.access_level}
                onChange={(e) => setFormData({ ...formData, access_level: e.target.value })}
                required
              >
                <option value="">-- เลือกระดับสิทธิ์ ---</option>
                <option value="acc">Accounting</option>
                <option value="fin">Finance</option>
                <option value="wh">Warehouse</option>
                <option value="hr">Human Resources</option>
                <option value="it">Information Technology</option>
                <option value="admin">Admin</option>
              </select>
            </div>

            <div className="flex justify-end gap-3 mt-6">
              <button
                type="button"
                onClick={() => setIsModalOpen(false)}
                className="px-4 py-2 border rounded-md text-gray-700 hover:bg-gray-50"
              >
                ยกเลิก
              </button>
              <button
                type="submit"
                className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
              >
                <Save className="h-5 w-5" />
                บันทึก
              </button>
            </div>
          </form>
        </Card>
      </div>
    )
  );
};

export default UserModal;