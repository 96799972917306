import React from "react";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
    const navigate = useNavigate();

    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <div className="text-center">
                <h1 className="text-5xl font-bold text-gray-700">404 Not Found</h1>
                <p className="mt-2 text-gray-600">ไม่พบเนื้อหา</p>
                <button 
                    onClick={() => navigate('/home')}
                    className="mt-6 px-4 py-2 bg-blue-500 text-white rounded"
                >
                    กลับสู่หน้าแรก
                </button>
            </div>
        </div>
    );
}
