import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Announcements from "./components/Announcements";
import ReportStatus from "./components/Report_status";
import CompanyForms from "./components/CompanyForms"; 
import Faq from "./components/FAQ"; 
import CustomCalendar from "./components/Calendar"; // นำเข้าคอมโพเนนต์ปฏิทิน FullCalendar
import Events from "./components/Events";

function Dashboard() {

  const navigate = useNavigate();

  // เช็คว่ามี token ใน localStorage หรือไม่
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate('/login');
    }
  }, [navigate]);

  return (
    <div className="flex min-h-screen">
      {/* Container หลัก */}
      <div className="flex-1 p-6 bg-gray-100 lg:p-20">

        {/* Layout กล่องข้อมูล */}
        <div className="flex-wrap flex-start grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 h-auto gap-4">

          {/* กล่องแต่ละอัน */}
          <div className="bg-white p-6 rounded-lg shadow-md ">
            <Announcements/>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md ">  
            <ReportStatus />
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md">
            <CompanyForms />
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md ">
            <Events /> 
          </div>

          {/* ส่วนของกล่องปฏิทิน */}
          <div className="bg-white p-3 rounded-lg shadow-md">
            <h3 className="text-lg text-stone-800 font-bold mb-1 mt-3">📅 ปฏิทิน</h3>
            <div className="w-full">
              <CustomCalendar />
            </div>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md ">
            <Faq />
          </div>

        </div>
      </div>
    </div>
  );
}

export default Dashboard;
