import React, { useEffect, useState } from "react";
import { Card } from "../ui/card";
import {
  Users,
  AlertCircle,
  Database,
  BarChart,
  Activity,
  LogOut
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import axios from "../../utils/axiosConfig"; 
import activityLogger from '../../utils/activityLogger'; // นำเข้า activityLogger
import { API_URL } from '../../utils/configBridge';

const Dashboard = () => {
  const navigate = useNavigate();
  const [userCount, setUserCount] = useState(0);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [pendingProblems, setPendingProblems] = useState(0); // เพิ่ม state สำหรับจำนวนปัญหาที่รอดำเนินการ

  // ฟังก์ชันจัดรูปแบบวันที่และเวลาในแบบไทย
  const formatDateTime = (date) => {
    const pad = (num) => num.toString().padStart(2, "0");
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
    const day = date.getDate();
    const monthsThai = [
      "มกราคม",
      "กุมภาพันธ์",
      "มีนาคม",
      "เมษายน",
      "พฤษภาคม",
      "มิถุนายน",
      "กรกฎาคม",
      "สิงหาคม",
      "กันยายน",
      "ตุลาคม",
      "พฤศจิกายน",
      "ธันวาคม"
    ];
    const month = monthsThai[date.getMonth()];
    const year = date.getFullYear() + 543; // เปลี่ยนเป็น พ.ศ.
    return `เวลา : ${hours}:${minutes}:${seconds} | วันที่ ${day} ${month} ${year}`;
  };

  // เพิ่ม state สำหรับเวลา
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  // สำหรับแสดงจำนวนผู้ใช้งานทั้งหมด
  useEffect(() => {
    const token = localStorage.getItem("admin_token");
    fetch(`${API_URL}/users/count`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then((res) => res.json())
      .then((data) => setUserCount(data.count))
      .catch((err) => console.error(err));
  }, []);

  // เพิ่ม effect สำหรับดึงจำนวนปัญหาที่ยังไม่เสร็จสิ้น
  useEffect(() => {
    const token = localStorage.getItem("admin_token");
    
    axios.get('/problem_status', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        // นับจำนวนปัญหาที่ไม่ใช่สถานะ "เสร็จสิ้น"
        const unfinishedProblems = response.data.filter(
          problem => problem.status !== "เสร็จสิ้น"
        ).length;
        
        setPendingProblems(unfinishedProblems);
      })
      .catch(err => {
        console.error("Error fetching problem status:", err);
        // กรณีมีข้อผิดพลาดให้แสดงค่า 0
        setPendingProblems(0);
      });
  }, []);

  // แก้ไขฟังก์ชัน handleLogout โดยลบตัวแปรที่ไม่ได้ใช้งาน
  const handleLogout = () => {
    try {
      // บันทึก log การออกจากระบบ - ใช้ logout() แทนการเรียก logActivity โดยตรง
      activityLogger.logout();
      
      // สำคัญมาก: ต้องทำ setTimeout ให้นานพอที่จะรอให้ request ส่งเสร็จ
      setTimeout(() => {
        // จึงลบข้อมูล token และนำทางไปยังหน้าล็อกอิน
        localStorage.removeItem("admin_token");
        localStorage.removeItem("admin_name");
        localStorage.removeItem("adminTokenExpiration");
        localStorage.removeItem("adminTokenExpirationDisplay"); 
        localStorage.removeItem("admin_access_level");
        navigate("/adminlogin");
      }, 500); // เพิ่มเป็น 500ms เพื่อให้มั่นใจว่า request มีเวลาเพียงพอ
    } catch (error) {
      console.error("Error during logout:", error);
      // ยังคงทำการล็อกเอาท์แม้จะมีข้อผิดพลาด
      localStorage.removeItem("admin_token");
      localStorage.removeItem("admin_name");
      localStorage.removeItem("adminTokenExpiration");
      localStorage.removeItem("adminTokenExpirationDisplay"); 
      localStorage.removeItem("admin_access_level");
      navigate("/adminlogin");
    }
  };

  const stats = [
    {
      title: "เวลาในขณะนี้",
      value: formatDateTime(currentTime),
    },
    {
      title: "ผู้ใช้งานทั้งหมด",
      value: userCount,
      icon: <Users className="h-8 w-8 text-blue-500" />,

    },
    {
      title: "รายงานปัญหา",
      value: pendingProblems, // เปลี่ยนจาก "0" เป็นค่าจำนวนปัญหาที่ยังไม่เสร็จ
      icon: <AlertCircle className="h-8 w-8 text-red-500" />,

    }
  ];

  const quickActions = [
    {
      title: "สถิติการใช้งานผู้ใช้",
      description: "ภาพรวมการล็อกอินของผู้ใช้",
      icon: <BarChart className="h-8 w-8 text-indigo-500  mb-4" />,
      onClick: () => navigate("/admin/dashboard/login-statistics")
    },
    {
      title: "บันทึกกิจกรรมผู้ใช้",
      description: "ดูและวิเคราะห์กิจกรรมของผู้ใช้ในระบบ",
      icon: <Activity className="h-8 w-8 text-blue-500 mb-4" />,
      onClick: () => navigate("/admin/dashboard/activity-logs")
    },
    {
      title: "จัดการผู้ใช้",
      description: "เพิ่ม/ลบ/แก้ไขข้อมูลผู้ใช้งาน",
      icon: <Users className="h-8 w-8 text-blue-500 mb-4" />,
      onClick: () => navigate("/admin/dashboard/users")
    },
    {
      title: "จัดการข้อมูล",
      description: "จัดการฐานข้อมูลในระบบต่างๆ",
      icon: <Database className="h-8 w-8 text-green-500 mb-4" />,
      onClick: () => navigate("/admin/dashboard/data_management")
    },
    {
      title: "ออกจากระบบ",
      description: "ออกจากระบบและกลับสู่หน้าล็อกอิน",
      icon: <LogOut className="h-8 w-8 text-red-500 mb-4" />,
      onClick: handleLogout
    }
  ];

  return (
    <div className="p-8 bg-gray-50 min-h-screen relative">
      {/* Header */}
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-800">Admin Dashboard</h1>
        <p className="text-gray-600 mt-2">Welcome Admin!</p>
      </div>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        {stats.map((stat, index) => (
          <Card key={index} className="p-6 hover:shadow-lg transition-shadow rounded-lg bg-white">
            <div className={`flex items-center ${stat.title === "เวลาในขณะนี้" ? "justify-center" : "justify-between"}`}>
              <div>
                <p className="text-sm font-medium text-gray-600">{stat.title}</p>
                <h3
                  className={`text-2xl ${
                    stat.title === "เวลาในขณะนี้" ? "font-normal text-xl mt-4" : "font-bold"
                  } text-gray-900 mt-2`}
                >
                  {stat.value}
                </h3>
                {stat.change && (
                  <span
                    className={`inline-flex items-center text-sm mt-2 ${
                      stat.changeType === "positive" ? "text-green-600" : "text-red-600"
                    }`}
                  >
                    {stat.change}
                  </span>
                )}
              </div>
              {stat.icon}
            </div>
          </Card>
        ))}
      </div>

      {/* Quick Actions */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {quickActions.map((action, index) => (
          <Card
            key={index}
            className="p-6 hover:bg-gray-50 transition-colors cursor-pointer rounded-lg bg-white"
            onClick={action.onClick}
          >
            <div className="flex flex-col items-center text-center">
              {action.icon}
              <h3 className="font-semibold text-gray-900">{action.title}</h3>
              <p className="text-sm text-gray-600 mt-2">{action.description}</p>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;