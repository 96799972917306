'use client'
import { useState, useEffect } from 'react';
import logo from '../img/New-Logo.png';

export default function LandingPage() {
  const [isLoaded, setIsLoaded] = useState(false);
  
  useEffect(() => {
    setIsLoaded(true);
  }, []);
  
  return (
    <div className="bg-cover bg-center min-h-screen w-full relative flex items-center justify-center" 
         style={{ backgroundImage: "url('landing.jpg')" }}>
      {/* โอเวอร์เลย์สีดำปรับความเข้มตามขนาดจอ */}
      <div className="absolute inset-0 bg-black bg-opacity-65 sm:bg-opacity-55"></div>

      {/* ส่วนเนื้อหาหลัก - จัดวางให้อยู่กึ่งกลางทั้งความกว้างและความสูง */}
      <div className="relative z-10 w-full max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col items-center justify-center">
        <div className={`w-full max-w-xl mx-auto transition-all duration-700 ${isLoaded ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'}`}>
          {/* แถบ For Employee Access */}
          <div className="mb-6 sm:mb-8 flex justify-center">
            <div className="relative rounded-full px-3 py-1 text-sm text-white ring-1 ring-white/50 hover:ring-white/80 transition-all">
               V.beta-24-3-68-1.2 
            </div>
          </div>
          
          <div className="text-center">
            {/* โลโก้ - ปรับขนาดให้เหมาะสม */}
            <div className="bg-center">
              <img
                alt="Company Logo"
                src={logo} 
                className="mx-auto h-16 sm:h-20 w-auto transition-transform duration-500 ease-out hover:scale-105"
                onLoad={() => setIsLoaded(true)}
              />
            </div>
            
            {/* ข้อความแนะนำ - ปรับขนาดตามจอและ line height */}
            <p className="mt-5 sm:mt-6 text-sm sm:text-base md:text-lg font-medium text-pretty text-white px-2 sm:px-0 leading-7">
              เป็นผู้จัดจำหน่ายวัสดุก่อสร้างครบวงจรที่ตอบโจทย์ทุกความต้องการของลูกค้า <br className="hidden sm:block" />
              ด้วยสินค้าคุณภาพ เช่น ปูนฉาบอิฐมวลเบา, แผ่นสมาร์ทบอร์ด
            </p>
            
            {/* ปุ่มเข้าสู่ระบบ - ปรับขนาดและ padding ให้เหมาะสม */}
            <div className="mt-6 sm:mt-8 flex items-center justify-center">
              <a
                href="/login"
                className="rounded-md bg-yellow-500 px-5 py-2 sm:px-6 sm:py-2.5 text-sm sm:text-base font-medium text-white shadow-md hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-offset-1 transition-all duration-300 active:scale-95"
              >
                เข้าสู่ระบบ
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* ส่วนลิขสิทธิ์ด้านล่าง - ปรับตำแหน่งให้ติดด้านล่างของหน้าจอเสมอ */}
      <div className="absolute bottom-4 left-0 right-0 z-10 text-center">
        <p className="text-xs text-white/70 mx-4 py-1">
          © {new Date().getFullYear()} โตเจริญพร. สงวนลิขสิทธิ์.
        </p>
      </div>
    </div>
  );
}
