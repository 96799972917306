// ไฟล์นี้เป็น UI สำหรับการจัดการไฟล์และโฟลเดอร์ในระบบ(ส่วนหลังบ้าน)

import React, { useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "../../ui/card";
import { 
  FolderOpen, ArrowLeft, FolderPlus, 
  Trash2, Download, RefreshCw, Search, 
  Grid, List, Upload, EllipsisVertical // เพิ่ม EllipsisVertical
} from "lucide-react";
import { useDropzone } from 'react-dropzone';
import { useFileData } from "./FileDataProvider";
import { getFileIcon, formatFileSize, formatDate, showDialog } from "./FileUtils";
import CreateFolderModal from "../../modals/CreateFolderModal_Backend"; // เพิ่มการ import
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// สร้าง MySwal ในระดับไฟล์
const MySwal = withReactContent(Swal);

// เพิ่มคอนสแตนท์ departments (แผนกต่างๆ)
const departments = [
  { id: "acc", label: "Accounting", color: "bg-blue-100 text-blue-800" },
  { id: "fin", label: "Finance", color: "bg-green-100 text-green-800" },
  { id: "wh", label: "Warehouse", color: "bg-yellow-100 text-yellow-800" },
  { id: "hr", label: "Human Resources", color: "bg-purple-100 text-purple-800" },
  { id: "it", label: "Information Technology", color: "bg-red-100 text-red-800" }
];

// ฟังก์ชันแสดง badges แสดงสิทธิ์การเข้าถึง
const renderAccessLevelBadges = (accessLevel) => {
  if (!accessLevel || accessLevel === 'all') {
    return (
      <span className="px-2 py-0.5 text-xs rounded bg-gray-100 text-gray-800 inline-flex items-center h-5">
        <span className="h-2 w-2 bg-gray-400 rounded-full mr-1"></span>
        ทุกแผนก
      </span>
    );
  }
  
  // แยกแผนกที่มีสิทธิ์
  const accessLevels = accessLevel.split(',');
  
  return (
    <div className="flex flex-wrap gap-1 justify-start w-full">
      {accessLevels.map(level => {
        const dept = departments.find(d => d.id === level);
        return dept ? (
          <span key={level} className={`px-2 py-0.5 text-xs rounded ${dept.color} inline-flex items-center h-5`}>
            <span className={`h-2 w-2 ${dept.color.includes('blue') ? 'bg-blue-500' : 
                           dept.color.includes('green') ? 'bg-green-500' : 
                           dept.color.includes('yellow') ? 'bg-yellow-500' : 
                           dept.color.includes('purple') ? 'bg-purple-500' : 
                           'bg-red-500'} rounded-full mr-1`}></span>
            {dept.label}
          </span>
        ) : null;
      })}
    </div>
  );
};

const FilesManagementUI = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  

  // เพิ่ม state สำหรับ modal
  const [isCreateFolderModalOpen, setIsCreateFolderModalOpen] = useState(false);
  
  // ใช้ hook สำหรับเข้าถึงข้อมูลและฟังก์ชันจาก FileDataProvider
  const {
    // State
    loading,
    currentFolderId,
    folderPath,
    searchTerm,
    showDeleted,
    viewMode,
    filteredFolders,
    filteredFiles,
    deletedFolders,
    deletedFiles,
    
    // State updaters
    setSearchTerm,
    setShowDeleted,
    setViewMode,
    setCurrentFolderId,
    setFolderPath,
    
    // Action handlers
    fetchFolders,
    fetchFiles,
    fetchDeletedFiles,
    fetchDeletedFolders,
    handleFolderClick,
    handleBack,
    handleCreateFolder: doHandleCreateFolder,
    handleDeleteFile,
    handleRestoreFile,
    handlePermanentDeleteFile,
    handleDeleteFolder,
    handleRestoreFolder,
    handlePermanentDeleteFolder,
    handleDownloadFile,
    handleFileUpload
  } = useFileData();

  // แทนที่ฟังก์ชันเดิมด้วยการเปิด Modal และเพิ่ม console.log
  const handleCreateFolder = () => {
    console.log('📁 เปิดหน้าต่างสร้างโฟลเดอร์ใหม่');
    setIsCreateFolderModalOpen(true);
  };
  
  // เพิ่มฟังก์ชันสำหรับจัดการการสร้างโฟลเดอร์จาก Modal พร้อม console.log
  const handleCreateFolderSubmit = (folderName, accessLevel) => {
    console.log(`📁 กำลังสร้างโฟลเดอร์: ${folderName} (สิทธิ์การเข้าถึง: ${accessLevel})`);
    doHandleCreateFolder(folderName, accessLevel);
  };

  // ค้นหาค่า access_level ของโฟลเดอร์ปัจจุบัน (หรือ 'all' ถ้าไม่มี)
  const currentFolderAccessLevel = folderPath.length > 0 
    ? folderPath[folderPath.length - 1].access_level 
    : 'all';

  // ฟังก์ชันเปิด file dialog เพื่ออัปโหลดไฟล์
  const handleUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // เพิ่มฟังก์ชันสำหรับจัดการการอัพโหลดไฟล์
  const enhancedHandleFileUpload = (files) => {
    const loading = showDialog({
      title: 'กำลังอัพโหลดไฟล์',
      text: 'โปรดรอสักครู่...',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        showDialog.showLoading();
      }
    });

    handleFileUpload(files, currentFolderId)
      .then(() => {
        loading.close();
        showDialog({
          icon: 'success',
          title: 'สำเร็จ!',
          text: 'อัพโหลดไฟล์เรียบร้อยแล้ว'
        });
        
        // รีเฟรชข้อมูลไฟล์ในโฟลเดอร์ปัจจุบัน
        if (currentFolderId) {
          fetchFiles(currentFolderId);
        }
      })
      .catch(error => {
        loading.close();
        showDialog({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด!',
          text: `ไม่สามารถอัพโหลดไฟล์ได้: ${error.message}`
        });
      });
  };

  // เพิ่มฟังก์ชันสำหรับลบไฟล์ถาวร
  const enhancedHandlePermanentDeleteFile = (fileId) => {
    MySwal.fire({
      title: 'ยืนยันการลบถาวร',
      text: 'คุณต้องการลบไฟล์นี้อย่างถาวรหรือไม่? การกระทำนี้ไม่สามารถย้อนกลับได้',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'ลบถาวร',
      cancelButtonText: 'ยกเลิก',
      confirmButtonColor: '#d33',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return handlePermanentDeleteFile(fileId)
          .then(() => {
            return { success: true };
          })
          .catch((error) => {
            // คืนค่าข้อผิดพลาดให้ SweetAlert จัดการ
            return { success: false, error: error.message || 'เกิดข้อผิดพลาดในการลบไฟล์' };
          });
      },
      allowOutsideClick: () => !MySwal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value.success) {
          MySwal.fire({
            icon: 'success',
            title: 'สำเร็จ!',
            text: 'ลบไฟล์ถาวรเรียบร้อยแล้ว',
            timer: 1500,
            showConfirmButton: false
          });
        } else {
          MySwal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด!',
            text: result.value.error
          });
        }
      }
    });
  };

  // แก้ไขฟังก์ชัน enhancedHandlePermanentDeleteFolder ให้ใช้ showDialog แทน
const enhancedHandlePermanentDeleteFolder = (folderId) => {
  MySwal.fire({
    title: 'ยืนยันการลบถาวร',
    text: 'คุณต้องการลบโฟลเดอร์นี้อย่างถาวรหรือไม่? การกระทำนี้ไม่สามารถย้อนกลับได้',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'ลบถาวร',
    cancelButtonText: 'ยกเลิก',
    confirmButtonColor: '#d33',
    showLoaderOnConfirm: true,
    preConfirm: () => {
      return handlePermanentDeleteFolder(folderId)
        .then(() => {
          return { success: true };
        })
        .catch((error) => {
          // คืนค่าข้อผิดพลาดให้ SweetAlert จัดการ
          return { success: false, error: error.message || 'เกิดข้อผิดพลาดในการลบโฟลเดอร์' };
        });
    },
    allowOutsideClick: () => !MySwal.isLoading()
  }).then((result) => {
    if (result.isConfirmed) {
      if (result.value.success) {
        MySwal.fire({
          icon: 'success',
          title: 'สำเร็จ!',
          text: 'ลบโฟลเดอร์ถาวรเรียบร้อยแล้ว',
          timer: 1500,
          showConfirmButton: false
        }).then(() => {
          // รีเฟรชข้อมูลโฟลเดอร์ในถังขยะหลังจากที่แสดง success dialog เสร็จ
          fetchDeletedFolders();
        });
      } else {
        MySwal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด!',
          text: result.value.error
        });
      }
    }
  });
};

  // Dropzone setup
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: true,
    maxSize: 20971520, // 20MB
    accept: {
      'image/*': [],
      'application/pdf': [],
      'application/msword': [],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
      'application/vnd.ms-excel': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
      'text/plain': [],
      'application/zip': [],
      'application/x-rar-compressed': []
    },
    onDrop: acceptedFiles => {
      if (acceptedFiles?.length) {
        enhancedHandleFileUpload(acceptedFiles);
      }
    },
    onDropRejected: fileRejections => {
      let errorMessage = '';
      fileRejections.forEach(({ file, errors }) => {
        errors.forEach(e => {
          if (e.code === 'file-too-large') {
            errorMessage += `${file.name} มีขนาดใหญ่เกินไป (สูงสุด 20MB)\n`;
          } else if (e.code === 'file-invalid-type') {
            errorMessage += `${file.name} ประเภทไฟล์ไม่รองรับ\n`;
          } else {
            errorMessage += `${file.name}: ${e.message}\n`;
          }
        });
      });
      
      showDialog({
        icon: 'error',
        title: 'ไม่สามารถอัพโหลดไฟล์ได้',
        text: errorMessage || 'เกิดข้อผิดพลาดในการอัพโหลดไฟล์',
        confirmButtonColor: "#3085d6"
      });
    }
  });

  // แก้ไขการใช้ Dropzone
  return (
    <div className="p-8 bg-gray-50 min-h-screen">
      {/* ซ่อน input และให้สามารถรับไฟล์ได้จากทั่วทั้งหน้า */}
      <div {...getRootProps({ className: 'fixed inset-0 pointer-events-none' })}>
        <input {...getInputProps()} ref={fileInputRef} />
      </div>

      {/* แสดง overlay เมื่อมีการลากไฟล์เข้ามา */}
      {isDragActive && (
        <div className="fixed inset-0 bg-blue-500 bg-opacity-20 z-50 flex flex-col items-center justify-center pointer-events-auto">
          <div className="bg-white rounded-lg shadow-xl p-8 text-center max-w-md animate-fadeIn">
            <Upload className="h-16 w-16 text-blue-500 mx-auto mb-4" />
            <h3 className="text-xl font-semibold text-gray-800 mb-2">วางไฟล์เพื่ออัพโหลด</h3>
            <p className="text-gray-600">ปล่อยไฟล์ที่นี่เพื่ออัพโหลดในโฟลเดอร์ปัจจุบัน</p>
          </div>
        </div>
      )}
      
      {/* Header with Back Button */}
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold text-gray-800">จัดการไฟล์และโฟลเดอร์</h1>
        <button
          onClick={() => navigate('/admin/dashboard/data_management')}
          className="flex gap-2 bg-gray-100 text-gray-600 px-4 py-2 rounded-md hover:bg-gray-200 transition-colors"
        >
          <ArrowLeft className="h-5 w-5" />
          ย้อนกลับไปหน้าหลัก
        </button>
      </div>

      {/* Breadcrumb Navigation */}
      <div className="flex items-center mb-6 text-base overflow-x-auto">
        <button 
          className="text-blue-600 hover:text-blue-800 whitespace-nowrap"
          onClick={() => {
            setCurrentFolderId(null);
            setFolderPath([]);
            fetchFolders();
          }}
        >
          หน้าหลัก
        </button>
        
        {folderPath.map((folder, index) => (
          <React.Fragment key={folder.id}>
            <span className="mx-2 text-gray-500">/</span>
            <button
              className="text-blue-600 hover:text-blue-800 whitespace-nowrap"
              onClick={() => {
                const newPath = folderPath.slice(0, index + 1);
                setFolderPath(newPath);
                setCurrentFolderId(folder.id);
                fetchFolders(folder.id);
                fetchFiles(folder.id);
              }}
            >
              {folder.name}
            </button>
          </React.Fragment>
        ))}
      </div>

      {/* Action Buttons */}
      <Card className="mb-6 p-4">
        <div className="flex flex-wrap gap-3">
          {currentFolderId && (
            <button
              onClick={handleBack}
              className="flex items-center px-4 py-2 bg-gray-100 rounded-md hover:bg-gray-200 transition-colors"
            >
              <ArrowLeft className="h-4 w-4 mr-2" />
              ย้อนกลับ
            </button>
          )}
          
          <button
            onClick={handleCreateFolder}
            className="flex items-center px-4 py-2 bg-blue-100 text-blue-700 rounded-md hover:bg-blue-200 transition-colors"
          >
            <FolderPlus className="h-4 w-4 mr-2" />
            สร้างโฟลเดอร์
          </button>
          
          {currentFolderId && (
            <button
              onClick={handleUpload}
              className="flex items-center gap-2 px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
              disabled={showDeleted}
            >
              <Upload className="h-4 w-4" />
              <span className="font-medium">อัพโหลดไฟล์</span>
            </button>
          )}
          
          {/* View Mode Switcher */}
          <div className="flex items-center gap-2 ml-auto">
            <button
              onClick={() => setViewMode("grid")}
              className={`p-2 rounded-md transition-colors ${
                viewMode === "grid" 
                  ? "bg-blue-100 text-blue-700" 
                  : "bg-gray-100 text-gray-600 hover:bg-gray-200"
              }`}
              title="แสดงแบบกริด"
            >
              <Grid className="h-4 w-4" />
            </button>
            <button
              onClick={() => setViewMode("list")}
              className={`p-2 rounded-md transition-colors ${
                viewMode === "list" 
                  ? "bg-blue-100 text-blue-700" 
                  : "bg-gray-100 text-gray-600 hover:bg-gray-200"
              }`}
              title="แสดงแบบรายการ"
            >
              <List className="h-4 w-4" />
            </button>
          </div>
          
          <button
            onClick={() => {
              fetchFolders(currentFolderId);
              if (currentFolderId) {
                fetchFiles(currentFolderId);
              }
              if (showDeleted) {
                fetchDeletedFolders();
                fetchDeletedFiles();
              }
            }}
            className="flex items-center px-4 py-2 bg-gray-100 rounded-md hover:bg-gray-200 transition-colors"
          >
            <RefreshCw className="h-4 w-4 mr-2" />
            รีเฟรช
          </button>

          <button
            onClick={() => {
              setShowDeleted(!showDeleted);
            }}
            className={`flex items-center px-4 py-2 rounded-md transition-colors ${
              showDeleted 
                ? "bg-red-500 text-white hover:bg-red-600" 
                : "bg-gray-100 text-gray-700 hover:bg-gray-200"
            }`}
          >
            <Trash2 className="h-4 w-4 mr-2" />
            {showDeleted ? "ออกจากถังขยะ" : "ถังขยะ"}
          </button>
        </div>
      </Card>

      {/* Search Bar */}
      <div className="relative mb-6">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
        <input
          type="text"
          placeholder="ค้นหาไฟล์หรือโฟลเดอร์..."
          className="pl-10 pr-4 py-2 w-full border border-gray-300 rounded-md"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Content Section */}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {/* Main Content - Normal Mode */}
          {!showDeleted && (
            <>
              {/* Folders Section */}
              {filteredFolders.length > 0 && (
                <div className="mb-8">
                  <h2 className="text-xl font-bold mb-4 text-gray-800">โฟลเดอร์</h2>
                  {viewMode === "grid" ? (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 text-left">
                      {filteredFolders.map(folder => (
                        <Card key={`folder-${folder.id}`} className="p-4 hover:shadow-md transition-shadow">
                          <div className="flex items-start justify-between">
                            <div 
                              className="flex items-center cursor-pointer flex-grow" 
                              onClick={() => handleFolderClick(folder)}
                            >
                              <FolderOpen className="h-10 w-10 text-yellow-500 mr-3" />
                              <div>
                                <h3 className="font-medium text-gray-800">{folder.name}</h3>
                                <p className="text-xs text-gray-500 mt-1">โฟลเดอร์</p>
                                <div className="mt-2">
                                  {renderAccessLevelBadges(folder.access_level)}
                                </div>
                              </div>
                            </div>
                            
                            {/* เปลี่ยนเป็น dropdown */}
                            <div className="dropdown dropdown-end">
                              <label tabIndex={0} className="btn btn-ghost btn-xs">
                                <EllipsisVertical className="h-5 w-5 text-gray-500 hover:text-gray-700" />
                              </label>
                              <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-white rounded-md w-40">
                                <li>
                                  <button onClick={() => handleDeleteFolder(folder.id)} className="flex items-center px-3 py-2 hover:bg-gray-100">
                                    <Trash2 className="h-4 w-4 mr-2 text-red-500" />
                                    <span>ลบโฟลเดอร์</span>
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </Card>
                      ))}
                    </div>
                  ) : (
                    <Card className="mb-4">
                      <div className="divide-y">
                        {filteredFolders.map(folder => (
                          <div key={folder.id} className="flex items-center justify-between p-3 hover:bg-gray-50">
                            <div 
                              className="flex items-center cursor-pointer flex-grow" 
                              onClick={() => handleFolderClick(folder)}
                            >
                              <FolderOpen className="h-6 w-6 text-yellow-500 mr-3" />
                              <div className="text-start">
                                <span className="font-medium text-gray-800">{folder.name}</span>
                                <div className="mt-1">
                                  {renderAccessLevelBadges(folder.access_level)}
                                </div>
                              </div>
                            </div>
                            
                            {/* เปลี่ยนเป็น dropdown */}
                            <div className="dropdown dropdown-end">
                              <label tabIndex={0} className="btn btn-ghost btn-xs">
                                <EllipsisVertical className="h-5 w-5 text-gray-500 hover:text-gray-700" />
                              </label>
                              <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-white rounded-md w-40">
                                <li>
                                  <button onClick={() => handleDeleteFolder(folder.id)} className="flex items-center px-3 py-2 hover:bg-gray-100">
                                    <Trash2 className="h-4 w-4 mr-2 text-red-500" />
                                    <span>ลบโฟลเดอร์</span>
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Card>
                  )}
                </div>
              )}

              {/* Files Section */}
              {currentFolderId && (
                <div>
                  <h2 className="text-xl font-bold mb-4 text-gray-800">ไฟล์</h2>
                  {filteredFiles.length > 0 ? (
                    viewMode === "grid" ? (
                      <FilesGridView 
                        files={filteredFiles}
                        onDownload={handleDownloadFile}
                        onDelete={handleDeleteFile}
                      />
                    ) : (
                      <FilesListView 
                        files={filteredFiles}
                        onDownload={handleDownloadFile}
                        onDelete={handleDeleteFile}
                      />
                    )
                  ) : (
                    <EmptyState message="ไม่มีไฟล์ในโฟลเดอร์นี้" />
                  )}
                </div>
              )}

              {/* Empty State */}
              {!currentFolderId && filteredFolders.length === 0 && (
                <EmptyState message="ยังไม่มีโฟลเดอร์ในระบบ กรุณาสร้างโฟลเดอร์ใหม่" />
              )}
            </>
          )}

          {/* Trash Content */}
          {showDeleted && (
            <TrashContent 
              loading={loading}
              deletedFolders={deletedFolders}
              deletedFiles={deletedFiles}
              viewMode={viewMode}
              onFolderClick={handleFolderClick}
              onRestoreFolder={handleRestoreFolder}
              onPermanentDeleteFolder={enhancedHandlePermanentDeleteFolder}
              onRestoreFile={handleRestoreFile}
              onPermanentDeleteFile={enhancedHandlePermanentDeleteFile}
            />
          )}
        </>
      )}
      
      {/* เพิ่ม Modal */}
      <CreateFolderModal
        isOpen={isCreateFolderModalOpen}
        onClose={() => setIsCreateFolderModalOpen(false)}
        onSubmit={handleCreateFolderSubmit}
        parentFolderAccessLevel={currentFolderAccessLevel}
      />
    </div>
  );
};

// Component แสดง Loading
const LoadingSpinner = () => (
  <div className="flex justify-center items-center h-64">
    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
  </div>
);

// Component แสดง Empty State
const EmptyState = ({ message }) => (
  <div className="text-center py-10 bg-gray-50 rounded-md">
    <p className="text-gray-500">{message}</p>
  </div>
);

// Component แสดงไฟล์แบบ Grid
const FilesGridView = ({ files, onDownload, onDelete }) => (
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
    {files.map(file => (
      <Card key={file.id} className="p-4 hover:shadow-md transition-shadow">
        <div className="flex flex-col h-full">
          <div className="flex items-start justify-between text-start mb-3">
            <div className="flex items-center">
              {getFileIcon(file.file_type)}
              <div className="ml-3">
                <h3 className="font-medium text-gray-800 break-all text-left truncate">{file.name}</h3>
                <p className="text-xs text-gray-500">{formatFileSize(file.file_size)}</p>
              </div>
            </div>
            
            {/* เปลี่ยนเป็น dropdown */}
            <div className="dropdown dropdown-end">
              <label tabIndex={0} className="btn btn-ghost btn-xs">
                <EllipsisVertical className="h-5 w-5 text-gray-500 hover:text-gray-700" />
              </label>
              <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-white rounded-md w-40">
                <li>
                  <button onClick={() => onDownload(file.id, file.name)} className="flex items-center px-3 py-2 hover:bg-gray-100">
                    <Download className="h-4 w-4 mr-2 text-blue-500" />
                    <span>ดาวน์โหลด</span>
                  </button>
                </li>
                <li>
                  <button onClick={() => onDelete(file.id)} className="flex items-center px-3 py-2 hover:bg-gray-100">
                    <Trash2 className="h-4 w-4 mr-2 text-red-500" />
                    <span>ลบไฟล์</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <p className="text-xs text-gray-500 mb-4">
            อัพโหลด: {formatDate(file.upload_date)}
          </p>
        </div>
      </Card>
    ))}
  </div>
);

// Component แสดงไฟล์แบบ List
const FilesListView = ({ files, onDownload, onDelete }) => (
  <Card className="mb-4">
    <div className="divide-y">
      {files.map(file => (
        <div key={file.id} className="flex items-center justify-between p-3 hover:bg-gray-50">
          <div className="flex items-center flex-grow">
            <div className="flex-shrink-0 mr-3">
              {getFileIcon(file.file_type)}
            </div>
            <div className="min-w-0 flex-grow mr-auto">
              <p className="font-medium text-gray-800 text-left truncate max-w-full">{file.name}</p>
              <div className="flex text-xs text-gray-500">
                <span className="mr-3">{formatFileSize(file.file_size)}</span>
                <span>อัพโหลด: {formatDate(file.upload_date)}</span>
              </div>
            </div>
          </div>
          
          {/* เปลี่ยนเป็น dropdown */}
          <div className="dropdown dropdown-end">
            <label tabIndex={0} className="btn btn-ghost btn-xs">
              <EllipsisVertical className="h-5 w-5 text-gray-500 hover:text-gray-700" />
            </label>
            <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-white rounded-md w-40">
              <li>
                <button onClick={() => onDownload(file.id, file.name)} className="flex items-center px-3 py-2 hover:bg-gray-100">
                  <Download className="h-4 w-4 mr-2 text-blue-500" />
                  <span>ดาวน์โหลด</span>
                </button>
              </li>
              <li>
                <button onClick={() => onDelete(file.id)} className="flex items-center px-3 py-2 hover:bg-gray-100">
                  <Trash2 className="h-4 w-4 mr-2 text-red-500" />
                  <span>ลบไฟล์</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      ))}
    </div>
  </Card>
);

// Component แสดงถังขยะ
const TrashContent = ({ 
  loading, 
  deletedFolders, 
  deletedFiles,
  viewMode,
  onFolderClick,
  onRestoreFolder,
  onPermanentDeleteFolder,
  onRestoreFile,
  onPermanentDeleteFile
}) => (
  <div className="mt-8">
    <div className="flex justify-between items-center mb-4">
      <h2 className="text-xl font-bold text-red-600">ถังขยะ</h2>
    </div>
    
    {loading ? (
      <LoadingSpinner />
    ) : (
      <>
        {/* Deleted Folders */}
        {deletedFolders.length > 0 && (
          <div className="mb-8 text-start">
            <h3 className="text-lg font-semibold mb-4">โฟลเดอร์ที่ถูกลบ</h3>
            <Card className="mb-4">
              <div className="divide-y">
                {deletedFolders.map(folder => (
                  <div 
                    key={`deleted-folder-${folder.id}`} 
                    className="flex items-center justify-between p-3 hover:bg-gray-50 border-l-4 border-red-500"
                  >
                    <div 
                      className="flex items-center cursor-pointer flex-grow" 
                      onClick={() => onFolderClick(folder)}
                    >
                      <FolderOpen className="h-6 w-6 text-yellow-500 mr-3" />
                      <div>
                        <span className="font-medium text-gray-800">{folder.name}</span>
                        <p className="text-xs text-gray-500">ลบเมื่อ: {formatDate(folder.deleted_at)}</p>
                      </div>
                    </div>
                    <div className="flex space-x-2">
                      <button
                        onClick={() => onRestoreFolder(folder.id)}
                        className="p-2 bg-green-100 text-green-700 rounded-md hover:bg-green-200 transition-colors"
                        title="กู้คืนโฟลเดอร์"
                      >
                        <RefreshCw className="h-4 w-4" />
                      </button>
                      <button
                        onClick={() => onPermanentDeleteFolder(folder.id)}
                        className="p-2 bg-red-100 text-red-700 rounded-md hover:bg-red-200 transition-colors"
                        title="ลบถาวร"
                      >
                        <Trash2 className="h-4 w-4" />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </Card>
          </div>
        )}

        {/* Deleted Files */}
        {deletedFiles.length > 0 && (
          <div>
            <h3 className="text-lg font-semibold mb-4">ไฟล์ที่ถูกลบ</h3>
            {viewMode === "grid" ? (
              <DeletedFilesGridView 
                files={deletedFiles}
                onRestore={onRestoreFile}
                onPermanentDelete={onPermanentDeleteFile}
              />
            ) : (
              <DeletedFilesListView 
                files={deletedFiles}
                onRestore={onRestoreFile}
                onPermanentDelete={onPermanentDeleteFile}
              />
            )}
          </div>
        )}

        {/* Empty State */}
        {deletedFolders.length === 0 && deletedFiles.length === 0 && (
          <EmptyState message="ไม่มีไฟล์หรือโฟลเดอร์ในถังขยะ" />
        )}
      </>
    )}
  </div>
);

// Component แสดงไฟล์ที่ถูกลบแบบ Grid
const DeletedFilesGridView = ({ files, onRestore, onPermanentDelete }) => (
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
    {files.map(file => (
      <Card key={file.id} className="p-4 hover:shadow-md transition-shadow border-l-4 border-red-500">
        <div className="flex flex-col h-full">
          <div className="flex items-start justify-between w-full mb-3">
            <div className="flex items-center">
              {getFileIcon(file.file_type)}
              <div className="ml-3 overflow-hidden">
                <h3 className="font-medium text-gray-800 text-left truncate">{file.name}</h3>
                <p className="text-xs text-gray-500">{formatFileSize(file.file_size)}</p>
              </div>
            </div>
            
            {/* เปลี่ยนเป็น dropdown */}
            <div className="dropdown dropdown-end">
              <label tabIndex={0} className="btn btn-ghost btn-xs">
                <EllipsisVertical className="h-5 w-5 text-gray-500 hover:text-gray-700" />
              </label>
              <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-white rounded-md w-40">
                <li>
                  <button onClick={() => onRestore(file.id)} className="flex items-center px-3 py-2 hover:bg-gray-100">
                    <RefreshCw className="h-4 w-4 mr-2 text-green-500" />
                    <span>กู้คืนไฟล์</span>
                  </button>
                </li>
                <li>
                  <button onClick={() => onPermanentDelete(file.id)} className="flex items-center px-3 py-2 hover:bg-gray-100">
                    <Trash2 className="h-4 w-4 mr-2 text-red-500" />
                    <span>ลบถาวร</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="text-xs text-gray-500 mb-2">
            <p>ลบเมื่อ: {formatDate(file.deleted_at)}</p>
            <p>อัพโหลด: {formatDate(file.upload_date)}</p>
          </div>
        </div>
      </Card>
    ))}
  </div>
);

// Component แสดงไฟล์ที่ถูกลบแบบ List
const DeletedFilesListView = ({ files, onRestore, onPermanentDelete }) => (
  <Card className="mb-4">
    <div className="divide-y">
      {files.map(file => (
        <div key={file.id} className="flex items-start justify-between p-3 hover:bg-gray-50 border-l-4 border-red-500">
          <div className="flex items-start flex-grow text-start">
            <div className="flex-shrink-0 mr-3">
              {getFileIcon(file.file_type)}
            </div>
            <div className="min-w-0 flex-grow">
              <p className="font-medium text-gray-800 truncate">{file.name}</p>
              <div className="flex flex-wrap text-xs text-gray-500">
                <span className="mr-3">{formatFileSize(file.file_size)}</span>
                <span className="mr-3">ลบเมื่อ: {formatDate(file.deleted_at)}</span>
                <span>อัพโหลด: {formatDate(file.upload_date)}</span>
              </div>
            </div>
          </div>
          
          {/* เปลี่ยนเป็น dropdown */}
          <div className="dropdown dropdown-end">
            <label tabIndex={0} className="btn btn-ghost btn-xs">
              <EllipsisVertical className="h-5 w-5 text-gray-500 hover:text-gray-700" />
            </label>
            <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-white rounded-md w-40">
              <li>
                <button onClick={() => onRestore(file.id)} className="flex items-center px-3 py-2 hover:bg-gray-100">
                  <RefreshCw className="h-4 w-4 mr-2 text-green-500" />
                  <span>กู้คืนไฟล์</span>
                </button>
              </li>
              <li>
                <button onClick={() => onPermanentDelete(file.id)} className="flex items-center px-3 py-2 hover:bg-gray-100">
                  <Trash2 className="h-4 w-4 mr-2 text-red-500" />
                  <span>ลบถาวร</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      ))}
    </div>
  </Card>
);

export default FilesManagementUI;