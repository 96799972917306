import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FileDataProvider } from './FileDataProvider';
import FilesManagementUI from './FilesManagementUI';

// นี่คือคอมโพเนนต์หลักที่จะใช้แทน FilesManagement เดิม
const FilesManager = () => {
  const navigate = useNavigate();
  
  // เพิ่ม useEffect เพื่อแสดงข้อความเมื่อคอมโพเนนต์ถูกโหลด
  useEffect(() => {
    console.log('📁 ระบบจัดการไฟล์และโฟลเดอร์กำลังเริ่มทำงาน...');
    
    // ใช้ setTimeout เพื่อให้ console.log แสดงผลหลังจากคอมโพเนนต์ถูก render เสร็จสมบูรณ์
    const timer = setTimeout(() => {
      console.log('📁 ระบบจัดการไฟล์และโฟลเดอร์พร้อมใช้งานแล้ว');
    }, 1000);
    
    // ทำความสะอาด timer เมื่อคอมโพเนนต์ถูก unmount
    return () => clearTimeout(timer);
  }, []);
  
  return (
    <FileDataProvider navigate={navigate}>
      <FilesManagementUI />
    </FileDataProvider>
  );
};

export default FilesManager;